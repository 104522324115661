import React, { DragEvent, FC } from "react";
import style from "../DoplexScreen.module.css";
import { v4 as uuidv4 } from "uuid";
import { IBlock } from "../../../types/common";

const getAllInputPoints = (type: string, obj: any) => {
  if (type === "custominput") {
    return "1";
  }

  if (type === "customoutput") {
    return "0";
  }

  return Object.keys(obj.properties.input.properties).length.toString();
};

const getAllOutputPoints = (type: string, obj: any) => {
  if (type === "custominput") {
    return "0";
  }

  if (type === "customoutput") {
    return "1";
  }

  if (obj.properties.output.properties) {
    return Object.keys(obj.properties.output.properties).length.toString();
  }

  if (obj.properties.output.type) {
    return "1";
  }

  return "0";
};

interface SidebarProps {
  blocks: IBlock[];
}

export const Sidebar: FC<SidebarProps> = ({ blocks }) => {
  const onDragStart = (
    event: DragEvent<HTMLDivElement>,
    nodeType: string,
    block?: any,
    title?: string
  ) => {
    const inputPoints = getAllInputPoints(nodeType, block);
    const outputPoints = getAllOutputPoints(nodeType, block);

    if (nodeType === "custominput") {
      event.dataTransfer.setData("inputValue", "");
    }

    event.dataTransfer.setData("blockID", block?.id);
    event.dataTransfer.setData("title", block ? block.title : title);
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("inputPoints", inputPoints);
    event.dataTransfer.setData("outputPoints", outputPoints);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <aside className={style.item}>
      <div
        className={style.block}
        onDragStart={(event) =>
          onDragStart(event, "custominput", null, "Input")
        }
        draggable
      >
        Input Node
      </div>
      <div
        className={style.block}
        onDragStart={(event) =>
          onDragStart(event, "customoutput", null, "Output")
        }
        draggable
      >
        Output Node
      </div>
      {blocks.map((block) => (
        <div
          className={style.block}
          key={uuidv4()}
          onDragStart={(event) => onDragStart(event, "customnode", block)}
          draggable
        >
          {block.title}
        </div>
      ))}
    </aside>
  );
};
