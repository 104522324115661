import classNames from 'classnames'
import React, { Fragment } from 'react'
import Select, {
  OptionProps,
  components,
  MenuProps,
  Props,
  StylesConfig,
} from 'react-select'
import style from './DbrainSelect.module.css'

const Option = (props: OptionProps) => {
  return (
    <Fragment>
      <components.Option {...props}>
        {props.isSelected && (
          <div className={style.checkmark}>
            <svg
              width="14"
              height="12"
              viewBox="0 0 14 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 5L5.5 10L13 1" stroke="#FAFAFA" strokeWidth="2" />
            </svg>
          </div>
        )}
        {props.children}
      </components.Option>
    </Fragment>
  )
}

const Menu = (props: MenuProps) => {
  return (
    <Fragment>
      <components.Menu {...props} className={style.menu}>
        {props.children}
      </components.Menu>
    </Fragment>
  )
}

const customStyles: StylesConfig = {
  option: (provided, state) => ({
    ...provided,
    // borderBottom: '1px dotted pink',
    backgroundColor: 'transparent',
    color: '#fafafa',
    padding: '8px 32px',
    display: 'flex',
    gap: '10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#139EEF',
    },
  }),
  group: (provided, state) => ({
    borderBottom: '1px solid #fafafa',
    padding: '20px 0',
    '&:last-child': {
      borderBottom: 'none',
    },
  }),
  valueContainer: (provided, state) => {
    return {
      ...provided,
      padding: '8px 16px',
    }
  },
  menu: (provided, state) => {
    return {
      ...provided,
      backgroundColor: '#111111',
    }
  },
}

export default function DbrainSelect(props: Props) {
  return (
    <Select
      components={{ Option, Menu }}
      styles={customStyles}
      {...props}
      theme={(theme) => ({
        ...theme,
        borderRadius: 4,
        padding: '12px 16px',
        colors: {
          ...theme.colors,
          primary: '#111',
        },
      })}
    />
  )
}
