import classnames from 'classnames'
import Card from 'components/Card/Card'
import React from 'react'
import { IProject } from 'types/common'
import style from './ProjectCard.module.css'
import { ThreeDots, Button, ButtonSize, ButtonTheme } from '@dbrainio/shared-ui'
import moment from 'moment'
import { ellipsize } from 'utils/utils'
import { useNavigate } from 'react-router-dom'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  project: IProject
  onEdit: (project: IProject) => void
  onDelete: (project: IProject) => void
}

export default function ProjectCard({
  project,
  onDelete,
  onEdit,
  ...props
}: Props) {
  const [menu, setMenu] = React.useState(false)

  const navigate = useNavigate()

  const onDotsClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setMenu(!menu)
  }

  const handleEdit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setMenu(false)
    navigate(`/projects/${project.id}`)
  }

  const handleDelete = (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setMenu(false)
    onDelete(project)
  }

  return (
    <Card {...props} className={classnames(style.root, props.className)}>
      <ThreeDots
        className={style.dots}
        onClick={onDotsClick}
        isRevealed={menu}
        // onMouseLeave={() => setMenu(false)}
        popupContent={
          <div className={style.menuPopup}>
            <Button
              theme={ButtonTheme.Blue}
              size={ButtonSize.XS}
              onClick={handleEdit}
            >
              Редактировать
            </Button>
            <Button
              theme={ButtonTheme.Red}
              size={ButtonSize.XS}
              onClick={handleDelete}
            >
              Удалить
            </Button>
          </div>
        }
      />
      <div className={style.date}>
        {moment.unix(project.created_at).format('DD MMMM')}
      </div>
      <div className={style.name}>{ellipsize(project.name, 40)}</div>
    </Card>
  )
}
