import { AxiosError } from 'axios'
import { AppError } from 'store/types'
import CyrillicToTranslit from 'cyrillic-to-translit-js'
import { IImage } from 'types/common'
import { LocalStorage } from './localStorage'
import moment from 'moment'

export function parseJwt(token: string) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

export const cyrillicToTranslit = new CyrillicToTranslit()

export const transformToSlug = (name: string) =>
  cyrillicToTranslit.transform(name, '_').toLowerCase()

export const generateImgUrl = (
  img: IImage,
  size?: 'thumbnail' | 'original',
) => {
  const loginRes = LocalStorage.getLoginRes()

  let url = `${window._env_?.API_URL}/image/thumbnail/${img.name}.${img.ext}`

  const params = new URLSearchParams()
  if (size === 'thumbnail') {
    params.append('size', '300')
  }

  if (loginRes) {
    params.append('access_token', loginRes.access)
  }

  if (Array.from(params.keys()).length > 0) {
    url += `?${params.toString()}`
  }

  return url
}

export const ColorPalette = [
  '#FF303F',
  '#139EEF',
  '#F99018',
  '#12EDBA',
  '#FF44C0',
  '#212FFF',
  '#FCCC20',
  '#BC19FC',
  '#14E814',
  '#E04A02',
  '#02C5E0',
  '#FBAB84',
  '#5A135B',
  '#B5000D',
  '#99C224',
]

export const transformHttpErrorToAppError = (e: AxiosError) => {
  const { response } = e
  if (response?.status === 403) {
    return AppError.Forbidden
  }

  if (response?.status === 404) return AppError.IncorrectToken

  if (response?.status === 500) {
    return AppError.InternalServerError
  }

  if (response?.status === 503) {
    return AppError.ServiceUnavailable
  }

  return AppError.Other
}

export const wait = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function getAppLang(): string {
  const langURLParam = getURLSearchParams().get('lang')

  if (langURLParam !== null) {
    return langURLParam.toUpperCase() === 'EN' ? 'en' : 'ru'
  }
  const lang = process.env.REACT_APP_LANG
  return lang === 'EN' ? 'en' : 'ru'
}

export interface DownloadCsvOptions {
  rows: string[][]
  filename: string
}

export const getURLSearchParams = (): URLSearchParams => {
  const queryString = window.location.search
  return new URLSearchParams(queryString)
}

export const downloadCsv = (options: DownloadCsvOptions) => {
  const universalBOM = '\uFEFF'

  function toCsv(arr: string[][]) {
    return arr.reduce(function (csvString, row) {
      csvString += row.join(',')
      csvString += '\r\n' //";";//"\n";
      return csvString
    }, '')
  }
  let csvContent =
    'data:text/csv;charset=utf-8,' + universalBOM + toCsv(options.rows)
  var encodedUri = encodeURI(csvContent)
  var link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', options.filename)
  document.body.appendChild(link) // Required for FF

  link.click() // This will download the data file named "my_data.csv".
  link.remove()
}

export const isMobile = () => {
  var check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
        a.substr(0, 4),
      )
    )
      check = true
  })(
    navigator.userAgent || navigator.vendor,
    // || window.opera
  )
  return check
}

export const cutFilenameWithEllipsis = (
  s: string,
  size: number,
  type: string,
) => {
  const typeShort = `${type.split('/').pop()}`
  return s.length >= size
    ? `${ellipsize(s, size)}...${typeShort}`
    : s + `.${typeShort}`
}

export const ellipsize = (s: string, size: number) => {
  const result = s.length > size ? `${s.slice(0, size)}...` : s
  return result
}

export const isiOS = () =>
  !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)

export function chunkArray<T>(myArray: T[], chunkSize: number) {
  var index = 0
  var arrayLength = myArray.length
  var tempArray = []

  for (index = 0; index < arrayLength; index += chunkSize) {
    const myChunk = myArray.slice(index, index + chunkSize)
    // Do something if you want with the group
    tempArray.push(myChunk)
  }

  return tempArray
}

export const fallbackCopyTextToClipboard = (text: string) => {
  var textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    var successful = document.execCommand('copy')
    var msg = successful ? 'successful' : 'unsuccessful'
    console.log('Fallback: Copying text command was ' + msg)
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}
export function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log('Async: Copying to clipboard was successful!')
    },
    function (err) {
      console.error('Async: Could not copy text: ', err)
    },
  )
}

export const formatUnixDate = (unix: number) =>
  moment.unix(unix).format('DD MMMM')
