import { GetDocumentsPayload } from 'api/documentApi'
import { ActionTypes } from 'store/types'
import { AnnotatePayload } from '../../api/annotationApi'
import { ID, IDocument } from '../../types/common'

export const START_LOGIN = 'APP/START_LOGIN'
export const SET_APP_ERROR = 'APP/SET_APP_ERROR'
export const CLEAR_APP_ERROR = 'APP/CLEAR_APP_ERROR'
export const GET_DOCUMENTS = 'APP/GET_DOCUMENTS'
export const SET_DOCUMENTS = 'APP/SET_DOCUMENTS'
export const START_SET_HEURISTICS = 'APP/START_SET_HEURISTICS'
export const COMPLETE_SET_HEURISTICS = 'APP/COMPLETE_SET_HEURISTICS'
export const START_ANNOTATE = 'APP/START_ANNOTATE'
export const START_DELETE_DOCUMENT = 'APP/START_DELETE_DOCUMENT'
export const COMPLETE_DELETE_DOCUMENT = 'APP/COMPLETE_DELETE_DOCUMENT'
export const COMPLETE_ANNOTATE = 'APP/COMPLETE_ANNOTATE'

export function setAppError(error: string): ActionTypes {
  return {
    type: SET_APP_ERROR,
    payload: error,
  }
}

export function getDocuments(payload: GetDocumentsPayload): ActionTypes {
  return {
    type: GET_DOCUMENTS,
    payload,
  }
}
export function setDocuments(payload: IDocument[]): ActionTypes {
  return {
    type: SET_DOCUMENTS,
    payload,
  }
}

export function deleteDocument(payload: ID): ActionTypes {
  return {
    type: START_DELETE_DOCUMENT,
    payload,
  }
}

export function annotate(payload: AnnotatePayload): ActionTypes {
  return {
    type: START_ANNOTATE,
    payload,
  }
}

export function clearAppError(): ActionTypes {
  return {
    type: CLEAR_APP_ERROR,
  }
}
