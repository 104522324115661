import { useEffect, useState } from "react";
import style from "./SingleDocumentScreen.module.css";
import Layout from "components/Layout/Layout";
import SingleDocument from "components/SingleDocument/SingleDocument";
import CloseButton from "components/CloseButton/CloseButton";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  ThreeDots,
} from "@dbrainio/shared-ui";
import { ID, IDocument, IImage } from "types/common";
import { DocumentApi } from "api/documentApi";
import { message } from "utils/toast";
import Confirm from "components/Confirm/Confirm";
import { MarkupApi } from "api/markupApi";
import { ImageApi } from "api/imageApi";
import { transformToSlug } from "utils/utils";

interface Props {}

export default function SingleDocumentScreen({}: Props) {
  const navigate = useNavigate();
  const loc = useLocation();

  let { id } = useParams<{ id?: ID }>();

  const [menu, setMenu] = useState(false);

  const [single, setSingle] = useState<IDocument | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [dataset, setDataset] = useState<IImage[] | null>(null);

  const [toDelete, setToDelete] = useState<ID | null>(null);
  const [isDeleteLoading, setDeleteLoading] = useState<boolean>(false);

  const [isUploading, setUploading] = useState(false);
  const [isRunMarkupLoading, setRunMarkupLoading] = useState(false);
  const [isRunTrainLoading, setRunTrainLoading] = useState(false);
  const [nameError, setNameError] = useState<boolean>(false);

  useEffect(() => {
    const getDocument = async () => {
      setLoading(true);
      if (!id) return;

      try {
        const response = await DocumentApi.get(id);
        if (response.status === 200) {
          setSingle(response.data.payload[0]);
        } else {
          message.error("Ошибка при запросе данных");
        }
      } catch (error) {
        message.error("Ошибка при запросе данных");
      }
      setLoading(false);
    };

    getDocument();
    getDataset();
    return () => {};
  }, [setLoading, id]);

  const runMarkup = async () => {
    setRunMarkupLoading(true);

    if (!single?.markups.length) {
      throw new Error("Markups arrays is empty");
    }

    try {
      const res = await MarkupApi.run(single?.markups[0].id as ID);

      if (res.status === 200) {
        message.success("Разметка успешно запущена");
        setSingle({
          ...single,
          stages: {
            ...single.stages,
            markup: {
              done: false,
              progress: 0.1,
            },
          },
        });
      } else {
        message.error(`Произошла ошибка. Код: ${res.status}`);
      }
    } catch (error) {
      message.error("Произошла ошибка во время запуска разметки");
    }
    setRunMarkupLoading(false);
  };

  const runTrain = async () => {
    setRunTrainLoading(true);

    if (!single?.markups.length) {
      throw new Error("Markups arrays is empty");
    }

    try {
      const res = await DocumentApi.saveMarkup(id as ID);
      message.success(`Разметка успешно сохранена`);
      setSingle({
        ...single,
        stages: {
          ...single.stages,
          training: {
            progress: 1.0,
            done: true,
          },
        },
      });

    } catch (error) {
      message.error("Неизвестная ошибка");
    }
    setRunTrainLoading(false);
  };

  const onUpload = async (
    acceptedFiles: File[],
    onUploadProgress: (count: number) => void
  ) => {
    if (!single) return;

    setUploading(true);
    try {
      const responses = await ImageApi.addBatch(
        acceptedFiles,
        { document_id: id as ID, tag: id as ID },
        onUploadProgress
      );

      const success = responses.filter(
        (r) => r.status === 200 || r.status === 201
      );
      if (success.length > 0) {
        const successImages = success
          .map((s) => s.data.payload)
          .reduce((accum, next) => [...accum, ...next], []);

        setSingle({
          ...single,
          images: (single.images || 0) + successImages.length,
          stages: {
            ...single.stages,
            upload: {
              done: true,
            },
          },
        });
        setDataset([...(dataset as IImage[]), ...successImages]);
        message.success("Успешно загружено");
      }

      const failed = responses.filter(
        (r) => r.status !== 200 && r.status !== 201
      );

      if (failed.length > 0) {
        message.error(`Произошла ошибка при загрузке некоторых изображений`);
      }
      // await getDataset()
    } catch (error) {
      message.error("Произошла ошибка во время загрузки датасета");
    }

    setUploading(false);
  };

  const getDataset = async () => {
    setLoading(true);
    if (!id) return;

    try {
      const res = await ImageApi.get({
        tag: id,
        pagination: {
          limit: 20,
          offset: 0,
        },
      });

      if (res.status === 200 || res.status === 201) {
        if (!res.data.payload) {
          throw new Error("Payload is empty");
        }

        setDataset(res.data.payload);
      } else {
        message.error(`Ошибка при загрузке изображений. Код: ${res.status}`);
      }
    } catch (error) {
      message.error(
        `Ошибка при загрузке изображений. Код: ${JSON.stringify(error)}`
      );
    }

    setLoading(false);
  };

  const onNameChange = async (newName: string) => {
    nameError && setNameError(false);

    const defaultSlug = transformToSlug(newName);

    try {
      const res = await DocumentApi.edit({
        id,
        name: newName,
        slug: defaultSlug,
      });

      if (res.status === 200) {
        if (res.data.success) {
          setSingle({
            ...(single as IDocument),
            name: newName,
            slug: defaultSlug,
          });
          // TODO: check it
          navigate(loc.pathname, {
            replace: true,
          });

          message.success("Документ сохранен");
        }
      } else {
        if (res.data.error?.title.includes("duplicate key")) {
          message.error(
            `Документ с таким названием уже существует. Код ${res.status}`
          );
          setNameError(true);
        } else {
          message.error(
            `Ошибка при изменении названия документа. Код ${res.status}`
          );
        }
      }
    } catch (error) {
      message.error("Ошибка при изменении названия документа");
    }
  };

  const onStartDelete = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setMenu(false);
    setToDelete(id as ID);
  };

  const onConfirmDelete = async () => {
    if (!toDelete) {
      throw new Error("ID to delete is not defined");
    }

    setDeleteLoading(true);

    try {
      const res = await DocumentApi.delete(toDelete);

      if (res.status === 200) {
        // dispatch()
        message.success("Документ успешно удалён");
        navigate(-1);
      }
    } catch (error) {
      message.error("При удалении что-то пошло не так");
    }
    setToDelete(null);
    setDeleteLoading(false);
  };

  const onCancelDelete = async () => setToDelete(null);

  if (id === undefined || id === null) {
    return <Navigate to="/" />;
  }

  return (
    <Layout className={style.root} loading={isLoading}>
      <Confirm
        title="Вы уверены, что хотите удалить документ?"
        description="Удаленный документ не получится восстановить"
        isLoading={isDeleteLoading}
        confirmText="Удалить"
        onCancel={onCancelDelete}
        onConfirm={onConfirmDelete}
        open={!!toDelete}
      />
      <div className={style.container}>
        <div className={style.controls}>
          <ThreeDots
            className={style.dots}
            onClick={() => setMenu(!menu)}
            isRevealed={menu}
            // onMouseLeave={() => setMenu(false)}
            popupContent={
              <div className={style.menuPopup}>
                <Button
                  theme={ButtonTheme.Red}
                  size={ButtonSize.XS}
                  onClick={onStartDelete}
                >
                  Удалить
                </Button>
              </div>
            }
          />
          <CloseButton onClick={() => navigate(-1)} className={style.close} />
        </div>
        {single && (
          <SingleDocument
            nameError={nameError}
            dataset={dataset}
            document={single}
            onNameChange={onNameChange}
            isUploading={isUploading}
            isRunMarkupLoading={isRunMarkupLoading}
            isRunTrainLoading={isRunTrainLoading}
            runMarkup={runMarkup}
            runTrain={runTrain}
            onUpload={onUpload}
          />
        )}
      </div>
    </Layout>
  );
}
