import React from 'react'
import { i18nInit } from './utils/i18n'
import { getAppLang } from './utils/utils'
import '@dbrainio/shared-ui/dist/index.css'
import 'react-toastify/dist/ReactToastify.min.css'

import { Routes, Route, Navigate } from 'react-router-dom'
import LoginScreen from './screens/LoginScreen/LoginScreen'
import HeuristicsScreen from 'screens/HeuristicsScreen/HeuristicsScreen'
import ProjectsScreen from 'screens/ProjectsScreen/ProjectsScreen'
import SingleDocumentScreen from 'screens/SingleDocumentScreen/SingleDocumentScreen'
import SingleProjectScreen from 'screens/SingleProjectScreen/SingleProjectScreen'
import AnnotateScreen from 'screens/AnnotateScreen/AnnotateScreen'
import { SegmentationProvider } from 'context/SegmentationContext'
import { ToastContainer } from 'react-toastify'
import { AuthProvider, useAuthState } from 'context/AuthContext'
import DoplexScreen from "./screens/DoplexScreen/DoplexScreen";

export enum AppStage {
  Upload = 'Upload',
  Uploading = 'Uploading',
}

i18nInit(getAppLang())

function RequireAuth({ children }: { children: JSX.Element }) {
  const state = useAuthState()
  const WITH_AUTH = process.env.REACT_APP_WITH_AUTH === 'true';
  const isLoggedIn = !!state.loginRes

  let redirectPath: string = ''

  if (!WITH_AUTH) {
    console.log('Auth disabled, skipping auth check')
    return children
  }

  if (!isLoggedIn) {
    console.log('Not logged in, redirecting to /login')
    redirectPath = '/login'
  }

  if (redirectPath) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={redirectPath} />
  }

  return children
}

console.log();


export default function App() {
  return (
    <AuthProvider>
      <SegmentationProvider>
        <ToastContainer />
        <Routes>
          {/* TODO: make route auth, lost when upgraded to react-router 6 */}
          <Route path="/login" element={<LoginScreen />}></Route>
          <Route
            path="/"
            element={
              <RequireAuth>
                <Navigate to="/projects" />
              </RequireAuth>
            }
          ></Route>
          <Route path="/annotate/:id" element={<AnnotateScreen />}></Route>
          <Route
            path="/documents/:id"
            element={<SingleDocumentScreen />}
          ></Route>
          <Route path="/projects" element={<ProjectsScreen />}></Route>
          <Route path="/projects/:id" element={<SingleProjectScreen />}></Route>
          <Route path="/heuristics/:id" element={<HeuristicsScreen />}></Route>
          <Route path="/doplex" element={<DoplexScreen />}></Route>
        </Routes>
      </SegmentationProvider>
    </AuthProvider>
  )
}
