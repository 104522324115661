import React from 'react'

interface Props {}
export default function MarkupLogo(props: Props) {
  return (
    <svg
      width="95"
      height="23"
      viewBox="0 0 95 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.5351 8.11091C74.0406 7.45794 72.9962 5.96669 72.9962 4.2315C72.9962 1.89451 74.8907 0 77.2277 0C78.9629 0 80.4541 1.04442 81.1071 2.5389H92.4611V13.8929C93.9556 14.5459 95 16.0371 95 17.7723C95 20.1093 93.1055 22.0038 90.7685 22.0038C89.0333 22.0038 87.5421 20.9594 86.8891 19.4649H75.5351V8.11091ZM89.0759 5.92411V13.8929C88.0997 14.3194 87.3156 15.1036 86.8891 16.0797H78.9203V8.11091C79.8964 7.68441 80.6806 6.90027 81.1071 5.92411H89.0759Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M6.54059 19.9448C2.1802 19.9448 0 17.2801 0 17.2801L2.42244 14.8576C2.42244 14.8576 3.8759 16.6745 6.41947 16.6745C8.35742 16.6745 9.20527 15.8266 9.20527 14.7365C9.20527 13.5397 7.76808 13.0615 6.07713 12.4988C3.66099 11.6948 0.726732 10.7184 0.726732 7.22694C0.726732 4.56226 3.02805 2.26094 6.90395 2.26094C10.6587 2.26094 12.5967 4.56226 12.5967 4.56226L10.1742 6.9847C10.1742 6.9847 8.96303 5.53124 7.02507 5.53124C5.20824 5.53124 4.48151 6.25797 4.48151 7.22694C4.48151 8.42377 5.9187 8.902 7.60965 9.46467C10.0258 10.2687 12.9601 11.245 12.9601 14.7365C12.9601 17.5223 10.5376 19.9448 6.54059 19.9448Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M22.1757 19.5814H18.5421V6.01572H13.6972V2.62431H27.0206V6.01572H22.1757V19.5814Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M36.9488 19.9448C32.5884 19.9448 29.5604 16.9167 29.5604 12.5563V2.62431H33.194V12.5563C33.194 14.9788 34.7686 16.5533 36.9488 16.5533C39.129 16.5533 40.7036 14.9788 40.7036 12.5563V2.62431H44.3373V12.5563C44.3373 16.9167 41.3092 19.9448 36.9488 19.9448Z"
        fill="url(#paint3_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.7604 19.5814H48.5832V2.62431H54.7604C59.9686 2.62431 63.7234 6.37909 63.7234 11.1028C63.7234 15.8266 59.9686 19.5814 54.7604 19.5814ZM52.2168 6.01572V16.19H54.7604C57.7884 16.19 59.9686 14.0098 59.9686 11.1028C59.9686 8.19592 57.7884 6.01572 54.7604 6.01572H52.2168Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M70.6217 19.5814H66.988V2.62431H70.6217V19.5814Z"
        fill="url(#paint5_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="95"
          y1="1.52795e-06"
          x2="-9.41382e-07"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1356A8" />
          <stop offset="1" stopColor="#002A5B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="95"
          y1="1.52795e-06"
          x2="-9.41382e-07"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1356A8" />
          <stop offset="1" stopColor="#002A5B" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="95"
          y1="1.52795e-06"
          x2="-9.41382e-07"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1356A8" />
          <stop offset="1" stopColor="#002A5B" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="95"
          y1="1.52795e-06"
          x2="-9.41382e-07"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1356A8" />
          <stop offset="1" stopColor="#002A5B" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="95"
          y1="1.52795e-06"
          x2="-9.41382e-07"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1356A8" />
          <stop offset="1" stopColor="#002A5B" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="95"
          y1="1.52795e-06"
          x2="-9.41382e-07"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1356A8" />
          <stop offset="1" stopColor="#002A5B" />
        </linearGradient>
      </defs>
    </svg>
  )
}
