import React from 'react'
import style from './LoadingScreen.module.css'
import { Loader } from '@dbrainio/shared-ui'
import classnames from 'classnames'

interface Props {
  color?: string
  transparent?: boolean
}
export default function LoadingScreen({
  color = '#1356A8',
  transparent,
}: Props) {
  return (
    <div
      className={classnames(style.root, { [style.transparent]: transparent })}
    >
      <Loader color={color} size={8} fullscreen />
    </div>
  )
}
