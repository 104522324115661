import React from 'react'
import { ID, IDataset } from 'types/common'
import style from './ImagesGrid.module.css'
import Heading from '../Heading/Heading'
import { IImage } from '../../types/common'
import { cutFilenameWithEllipsis, generateImgUrl } from 'utils/utils'
import { Pagination } from '@dbrainio/shared-ui'
import LoadingImage from 'components/LoadingImage/LoadingImage'

interface Props {
  images: IImage[]
  total: number | null
  onPageChange: (page: number) => void
  onClick: (image: IImage) => void
}

export default function ImagesGrid({
  images,
  total,
  onPageChange,
  onClick,
}: Props) {
  return (
    <div className={style.root}>
      <div className={style.grid}>
        {images.map((image, index) => (
          <div
            className={style.item}
            key={index}
            onClick={() => onClick(image)}
          >
            <div className={style.imgContainer}>
              <LoadingImage
                src={generateImgUrl(image, 'thumbnail')}
                alt="Ошибка при загрузке"
                className={style.img}
              />
            </div>
            {image.name && (
              <div className={style.name}>
                {cutFilenameWithEllipsis(image.name, 10, image.ext)}
              </div>
            )}
          </div>
        ))}
      </div>
      {total !== null && (
        <div className={style.pagination}>
          <Pagination onChange={onPageChange} total={total} />
        </div>
      )}
    </div>
  )
}
