import { axiosInstance } from "api";
import axios from "axios";
import {
  ID,
  IProject,
  IPagination,
  IImage,
  ResponseError,
} from "../types/common";

export interface AddResponse {
  payload: IImage[];
  success: boolean;
  error: string | null;
}

export interface GetImagesPayload {
  tag: string;
  pagination: IPagination;
}

export interface AddProjectPayload {
  name: string;
  slug: string;
}

export interface AddProjectResponse {
  payload?: IProject[];
  error: ResponseError | null;
  success: boolean;
}

export interface GetImagesResponse {
  payload?: IImage[];
  total: number | null;
  error: ResponseError | null;
  success: boolean;
}

export type EditProjectPayload = Partial<IProject>;
export interface EditProjectResponse {
  payload: null;
  error: ResponseError | null;
  success: boolean;
}

export interface DeleteProjectResponse {
  id: ID;
}

export class ImageApi {
  static async add(
    payload: File,
    params: { document_id: string; tag: string },
    onUploadProgress: (progressEvent: any) => void
  ) {
    const formData = new FormData();
    formData.append("file", payload);

    return axiosInstance.post<AddResponse>(`/image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params,
      onUploadProgress,
    });
  }

  static async addBatch(
    payload: File[],
    params: { document_id: string; tag: string },
    onUploadProgress: (count: number) => void
  ) {
    let count = 1;

    return axios.all(
      payload.map((f, i) => {
        const formData = new FormData();
        formData.append("file", f);
        return axiosInstance
          .post<AddResponse>(`/image`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            params,
          })
          .then((res) => {
            onUploadProgress((100 * count) / payload.length);
            count++;
            return res;
          });
      })
    );
    // return axios.post<AddResponse>(`/image`, formData, {
    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //   },
    //   params,
    //   onUploadProgress,
    // })
  }

  static async get({ tag, pagination }: GetImagesPayload) {
    return axiosInstance.get<GetImagesResponse>(`/image`, {
      params: {
        tag,
        ...pagination,
      },
    });
  }
  static async delete(id: ID) {
    return axiosInstance.delete<DeleteProjectResponse>(`/project/${id}`);
  }
  static async edit({ id, ...payload }: EditProjectPayload) {
    return axiosInstance.put<EditProjectResponse>(`/project/${id}`, payload);
  }
}
