import { axiosInstance } from 'api'
import {
  IField,
  ID,
  IHeuristic,
  HeuristicResponse,
  THeuristicCollections,
} from '../types/common'

export interface GetHeuristicsResponse {
  fields: IField[]
}

export interface AddHeuristicsResponse {
  success: boolean
  payload: HeuristicResponse | null
  error: Object | null
}

export interface AddHeuristicPayload {
  name: string
  document_id: ID
  collections: THeuristicCollections[]
}

export interface AddHeuristicsPayload {
  document_id: ID
  name: string
  basic: {
    name: string
    definitions: IHeuristic[] | null
  }[]
}

export class HeuristicApi {
  static async get(id: ID) {
    return axiosInstance.post<GetHeuristicsResponse>(`/annotate`, {
      document_id: id,
    })
  }
  static async add(h: AddHeuristicPayload) {
    return axiosInstance.post<AddHeuristicsResponse>(`/heuristic`, h)
  }
}
