import React, { FC, useState } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Button, ButtonTheme, TextInput } from "@dbrainio/shared-ui";
import * as Yup from "yup";
import style from "./LoginForm.module.css";
import { AuthApi } from "../../api/authApi";
import { useNavigate } from "react-router-dom";
import { LocalStorage } from "../../utils/localStorage";
import { useAuthDispatch } from "../../context/AuthContext";
import { DEFAULT_ROUTE } from "../../routes/routes";
import { message } from "../../utils/toast";
import { axiosInstance } from "../../api";
import { HTTPError } from "../../types/common";
import { setProjects } from "../../store/actions/projectActions";
import { useDispatch } from "react-redux";

type Props = {};

export interface IJWT {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  purpose: string;
  sub: {
    isDev: boolean;
    meta: any;
    permissions: ["gt"];
    role: "customer" | "admin" | "service";
    title: string;
    userName: string;
  };
}
interface FormValues {
  user: string;
  pass: string;
}

export const LoginForm: FC<Props> = ({}) => {
  const [initialValues] = useState<FormValues>({
    user: "",
    pass: "",
  });

  const dispatch = useAuthDispatch();
  const defaultDispatch = useDispatch();

  const navigate = useNavigate();

  const WITH_AUTH = process.env.REACT_APP_WITH_AUTH === "true";

  if (!WITH_AUTH) {
    return <Button onClick={() => navigate(DEFAULT_ROUTE)}
    type="submit"
    theme={ButtonTheme.Black}
    className={style.button}
    isLoading={false}
  >
    Войти
  </Button>


  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        user: Yup.string().required("Пустое имя пользователя"),
        pass: Yup.string().required("Пустое поле пароля"),
      })}
      onSubmit={async (
        { user, pass }: FormValues,
        { resetForm, setSubmitting }: FormikHelpers<FormValues>
      ) => {
        setSubmitting(true);
        try {
          const res = await AuthApi.login({
            username: user,
            password: pass,
          });
          const { data } = res;

          if ("access" in data) {
            dispatch({
              type: "login",
              payload: {
                res: data,
              },
            });
            LocalStorage.setLoginRes(data);
            defaultDispatch(setProjects([], true));
            navigate(DEFAULT_ROUTE);
            axiosInstance.defaults.headers.common = {
              Authorization: `${data.access}`,
            };
          } else {
            throw new Error("no access property in response");
          }
        } catch (e) {
          const { response: res } = e as HTTPError;
          if (res?.status === 401) {
            message.error("Неправильные логин/пароль");
          } else if (res?.status === 422) {
            message.error("Валидация не пройдена");
          } else {
            message.error(
              "Неизвестная ошибка. Статус ответа:" + JSON.stringify(e)
            );
          }
        }

        setSubmitting(false);
      }}
    >
      {({
        isSubmitting,
        values,
        errors,
        touched,
        submitCount,
        handleBlur,
        handleChange,
        handleSubmit,
      }: FormikProps<FormValues>) => {
        return (
          <form onSubmit={handleSubmit} className={style.root}>
            <TextInput
              placeholder="Логин"
              name="user"
              error={touched.user && !!errors.user && errors.user}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <TextInput
              placeholder="Пароль"
              name="pass"
              type="password"
              error={touched.pass && !!errors.pass && errors.pass}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Button
              type="submit"
              theme={ButtonTheme.Black}
              className={style.button}
              isLoading={isSubmitting}
            >
              Войти
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};
