import LoadingScreen from 'components/LoadingScreen/LoadingScreen'
import React, { useEffect, useState } from 'react'

interface Props
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {}

export default function LoadingImage(props: Props) {
  const [loading, setLoading] = useState(true)

  const handleLoad = () => setLoading(false)

  useEffect(() => {
    const { src } = props
    const img = new Image()

    img.addEventListener('load', handleLoad)
    if (!src) return
    img.src = src

    return () => {}
  }, [props.src])

  return loading ? <LoadingScreen color="#111111" /> : <img {...props} />
}
