import React from 'react'
import style from './MarkupSidebar.module.css'
import {
    Button,
    ButtonTheme,
    TextInput,
    TextInputTheme,
} from '@dbrainio/shared-ui'
import Sidebar from 'components/Sidebar/Sidebar'
import {IRects, IRectStyle} from 'components/Aabb/Aabb'
import guid from 'guid'
import {IRect} from 'types/common'
import classNames from 'classnames'

interface Props {
    fields: { [key: string]: string }
    rects: IRects
    currentActive: string | null
    newStyle: IRectStyle
    isCreating: boolean
    isFullscreen: boolean
    onDelete: (id: string) => void
    onFieldChange: (id: string, value: string) => void
    onSubmit: () => void
    onActivate: (id: string) => void
}

export default function MarkupSidebar({
                                          fields,
                                          currentActive,
                                          rects,
                                          newStyle,
                                          isCreating,
                                          isFullscreen,
                                          onDelete,
                                          onSubmit,
                                          onFieldChange,
                                          onActivate,
                                      }: Props) {
    const handleActivate = (id: string) => {
        onActivate(id)
    }

    const renderMarkupIcon = () => {
        return (
            <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="5"
                    y="5"
                    width="16"
                    height="16"
                    stroke="#FAFAFA"
                    strokeWidth="4"
                />
                <circle cx="5" cy="5" r="4.5" fill="#FAFAFA" stroke="#FAFAFA"/>
                <circle cx="21" cy="21" r="4.5" fill="#FAFAFA" stroke="#FAFAFA"/>
            </svg>
        )
    }

    const renderMarkupButton = (id: string | null, rect?: IRect) => {
        let transform = 'none'

        if (currentActive === rect?.fieldId) {
            transform = 'scale(1.2)'
        }

        return (
            <button
                tabIndex={-1}
                className={classNames(style.markup, {
                    [style.active]: id && currentActive === id,
                })}
                style={{
                    backgroundColor: rect?.style?.stroke || newStyle.stroke,
                    transform,
                }}
                onClick={() => handleActivate(rect ? rect.fieldId : guid.raw())}
            >
                {renderMarkupIcon()}
            </button>
        )
    }

    return (
        <Sidebar className={style.markup_sidebar}>
            <div
                className={classNames(style.rects, {
                    [style.fullscreen]: isFullscreen,
                })}
            >
                <div className={style.heading}>Поля для распознавания</div>
                <div className={style.container}>
                    {Object.keys(rects).map((k, index) => (
                        <div className={style.field} key={index}>
                            {renderMarkupButton(k, rects[k])}
                            <TextInput
                                placeholder="Название поля"
                                theme={TextInputTheme.White}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    onFieldChange(k, e.target.value)
                                }
                            />
                            <div className={style.delete} onClick={() => onDelete(k)}>
                                <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M1 13L13 1" stroke="#AAAAAA" strokeWidth="2"/>
                                    <path d="M13 13L0.999999 1" stroke="#AAAAAA" strokeWidth="2"/>
                                </svg>
                            </div>
                        </div>
                    ))}
                  {!isCreating && (
                      <div className={style.field}>{renderMarkupButton(currentActive)}</div>
                  )}
                </div>
            </div>
            <div className={style.submit}>
                <Button
                    theme={ButtonTheme.Blue}
                    onClick={() => onSubmit()}
                    disabled={Object.values(fields).every(
                        (f?: string) => f === undefined || f.length === 0,
                    )}
                >
                    Готово
                </Button>
            </div>
        </Sidebar>
    )
}
