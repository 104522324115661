import classnames from 'classnames'
import React from 'react'
import style from './BackButton.module.css'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {}

export default function BackButton(props: Props) {
  return (
    <div {...props} className={classnames(style.root, props.className)}>
      <svg
        width="32"
        height="16"
        viewBox="0 0 32 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 -2.18557e-06L6.99382e-07 8L7 16L7 10L32 10L32 6L7 6L7 -2.18557e-06Z"
          fill="#111111"
        />
      </svg>
    </div>
  )
}
