import React from "react";
import { HeuristicName } from "types/common";
import style from "./HeuristicSelector.module.css";

interface Props {
  onSelect: (name: HeuristicName) => void;
}

interface HeuristicsItemProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  name: HeuristicName;
  label: string;
  onAdd: (name: HeuristicName) => void;
}

const HeuristicsItem = ({
  name,
  label,
  onAdd,
  ...props
}: HeuristicsItemProps) => {
  return (
    <div className={style.item} onClick={() => onAdd(name)} {...props}>
      {label}
    </div>
  );
};

export default function HeuristicSelector({ onSelect }: Props) {
  return (
    <div className={style.root}>
      <div className={style.subheading}>Текстовые</div>
      <HeuristicsItem
        label="Рукописное поле"
        name="handwritten"
        onAdd={onSelect}
      />
      <HeuristicsItem
        label="Исправлять ошибки OCR"
        name="text_processing"
        onAdd={onSelect}
      />

      <HeuristicsItem label="Только цифры" name="to_digit" onAdd={onSelect} />

      <HeuristicsItem label="Только буквы" name="to_letters" onAdd={onSelect} />
      <HeuristicsItem
        label="Все большими буквами"
        name="to_upper"
        onAdd={onSelect}
      />

      <HeuristicsItem
        label="Все маленькими буквами"
        name="to_lower"
        onAdd={onSelect}
      />

      <HeuristicsItem
        label="Каждое слово с заглавной буквы"
        name="capitalize"
        onAdd={onSelect}
      />
      <div className={style.subheading}>Дата</div>
      <div className={style.panel}>
        <HeuristicsItem label="Дата" name="to_date" onAdd={onSelect} />
        <HeuristicsItem
          label="Дата и время"
          name="to_date_time"
          onAdd={onSelect}
        />

        <HeuristicsItem label="Время" name="hhmm" onAdd={onSelect} />

        <HeuristicsItem label="Время — ччммcc" name="hhmmss" onAdd={onSelect} />
      </div>
      <div className={style.subheading}>Общие</div>
      <HeuristicsItem label="Должно быть" name="to_discrete" onAdd={onSelect} />
      <HeuristicsItem
        label="Удалить символы"
        name="remove_residual_symbols"
        onAdd={onSelect}
      />
      <HeuristicsItem
        label="Оставить только"
        name="leave_residual_symbols"
        onAdd={onSelect}
      />
      <HeuristicsItem label="Заменять" name="replace" onAdd={onSelect} />

      <HeuristicsItem
        label="Ограничение длины"
        name="restrict_length"
        onAdd={onSelect}
      />
      <HeuristicsItem
        label="Удалять начало текста"
        name="remove_prefix"
        onAdd={onSelect}
      />

      <HeuristicsItem
        label="Удалять указанные строки"
        name="remove_strings"
        onAdd={onSelect}
      />
      <HeuristicsItem
        label="Регулярное выражение"
        name="pattern"
        onAdd={onSelect}
      />
      <div className={style.subheading}>Город России или СНГ</div>
      <div className={style.panel}>
        <HeuristicsItem
          label="Город россии"
          name="try_to_rus_city"
          onAdd={onSelect}
        />
        <HeuristicsItem
          label="Город России или СНГ"
          name="try_to_sng_or_rus_subject"
          onAdd={onSelect}
        />
        <HeuristicsItem
          label="Город СНГ"
          name="try_to_sng_country"
          onAdd={onSelect}
        />
      </div>
    </div>
  );
}
