import React, { useState } from "react";
import style from "./MultifileUpload.module.css";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { cutFilenameWithEllipsis } from "../../utils/utils";
import { useEffect } from "react";
import { Button, ButtonTheme } from "@dbrainio/shared-ui";
import useMediaQuery from "@mui/material/useMediaQuery";

export interface MultifileUploadProps {
  error?: string;
  isButtonLoading?: boolean;
  onSubmit: (acceptedFiles: File[]) => void;
  onEmptyFileList?: () => void;
  onDrop: (acceptedFiles: File[]) => void;
}

const MultifileUpload = (props: MultifileUploadProps) => {
  const matchesMobile = useMediaQuery("(max-width:600px)");

  const { t } = useTranslation();

  const [fileList, setFileList] = useState<File[]>([]);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.code === "Enter") {
        fileList.length && props.onSubmit(fileList);
      }
    };

    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  const onDrop = (acceptedFiles: File[]) => {
    props.onDrop(acceptedFiles);
    setFileList(acceptedFiles);
    props.onSubmit(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      // TODO: check
      accept: {
        "image/*": [".jpg", ".png", ".jpeg", ".pdf"],
        "application/*": [".zip"],
        "application/zip": [".zip"],
      },
      // maxSize: 10 * 1024 * 1024,
    });

  const dropzoneClasses = [style.dropzone];

  if (isDragActive) {
    dropzoneClasses.push(style.isDragActive);
  }

  const deleteFile = (name: string) => {
    const filtered = fileList.filter((f) => f.name !== name);
    setFileList(fileList.filter((f) => f.name !== name));
    if (filtered.length === 0 && props.onEmptyFileList) {
      props.onEmptyFileList();
    }
  };

  function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const renderDeleteIcon = () => (
    <div className={style.deleteIcon}>
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.000435925 6.66666L1.33377 7.99999L4.00016 5.3336L6.66666 8.00009L7.99999 6.66676L5.33349 4.00027L8.00043 1.33333L6.6671 0L4.00016 2.66693L1.33333 0.000101436L0 1.33343L2.66683 4.00026L0.000435925 6.66666Z"
          fill="#010101"
        />
      </svg>
    </div>
  );

  const renderFileList = () => {
    return (
      <div className={style.fileList}>
        <div className={style.fileListHeader}>
          <div className={style.fileListHeaderItem}>{t("File")}</div>
          <div className={style.fileListHeaderItem}>{t("Size")}</div>
        </div>
        {fileList.map(({ name, size, type }, index) => (
          <div className={style.fileListItem} key={index}>
            <div className={style.name}>
              {cutFilenameWithEllipsis(
                name.split(".").slice(0, -1).join("."),
                matchesMobile ? 10 : 30,
                type
              )}
            </div>
            <div>{formatBytes(size)}</div>
            <div className={style.deleteFile} onClick={() => deleteFile(name)}>
              {renderDeleteIcon()}
            </div>
          </div>
        ))}
        {fileRejections.map(({ file, errors }, index) => (
          <div
            className={[style.fileListItem, style.rejected].join(" ")}
            key={index}
          >
            <div>
              {cutFilenameWithEllipsis(
                file.name,
                matchesMobile ? 10 : 30,
                file.type
              )}
            </div>
            <div>{formatBytes(file.size)}</div>
            <div
              className={style.deleteFile}
              onClick={() => deleteFile(file.name)}
            ></div>
          </div>
        ))}
        <div className={style.classifyButton}>
          {props.error && (
            <div className={style.sizeExceededHint}>{t(props.error)}</div>
          )}
          {fileRejections.length === 1 && (
            <div className={style.sizeExceededHint}>
              {t(
                "There is file with size more than 10mb, that will not be processed"
              )}
            </div>
          )}
          {fileRejections.length > 1 && (
            <div className={style.sizeExceededHint}>
              {t(
                "There are files with size more than 10mb, that will not be processed"
              )}
            </div>
          )}
        </div>
      </div>
    );
  };
  const rootProps = getRootProps({ className: style.dropzone });

  return (
    <section className={style.root}>
      {fileList.length ? (
        renderFileList()
      ) : (
        <div>
          <div {...rootProps}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <div className={style.title}>ОК</div>
            ) : (
              <>
                <svg
                  width="120"
                  height="112"
                  viewBox="0 0 120 112"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M67 0H53V50H20L60 90L100 50L67 50V0Z"
                    fill="#AAAAAA"
                  />
                  <path d="M120 112V98H0V112H120Z" fill="#AAAAAA" />
                </svg>
              </>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default MultifileUpload;
