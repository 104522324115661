import axios from "axios";
import { doplexBaseURL } from "api";
import { LocalStorage } from "utils/localStorage";
import { IBlock } from "types/common";

export interface DoplexRes {
  task_id: string;
  task_tags: string[];
  result: IBlock[];
}

export interface DoplexErrorRes {
  message: string;
  error: string;
}

const token = LocalStorage.getToken() || "docr2021docr";
const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  common: {
    Authorization: token,
  },
};

export class DoplexApi {
  static getBlocks = async () => {
    const response = await axios.get<DoplexRes>(
      doplexBaseURL + "/pipelines/get_blocks",
      {
        headers,
      }
    );
    return response;
  };
}
