import axios from "axios";
import { LocalStorage } from "utils/localStorage";

const session = LocalStorage.getLoginRes();

const headers = {
  "Content-Type": "application/json",
  common: {
    Authorization: session?.access || "",
  },
};

if (!window._env_?.API_URL) {
  throw new Error("process.env.API_URL is not defined");
}

// export const baseURL = "https://api-dev.studio.kube.dbrain.io";
export const baseURL =
  window._env_.API_URL || "https://api-dev.studio.kube.dbrain.io";

export const doplexBaseURL = "https://doplex.dev.studio.dbrain.io";

// export const authBaseURL = 'https://auth-dev.studio.kube.dbrain.io'
export const authBaseURL =
  window._env_?.AUTH_API_URL || "https://auth-dev.studio.kube.dbrain.io";

export const axiosInstance = axios.create({
  baseURL,
  headers,
});

const WITH_AUTH = process.env.REACT_APP_WITH_AUTH === "true";

if (WITH_AUTH) {
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401 || error.response?.status === 403) {
        console.log("🚀 ~ file: api.ts ~ line 38 ~ error", error.response);
  
        if (
          ["Token empty", "Can't unpack token"].includes(
            error.response.data.error.title
          )
        ) {
          window.location.href = "/";
          LocalStorage.logout();
        }
      }
      return error.response;
    }
  );
}


// axios.interceptors.request.use(function (config) {
//   if (session?.access) {
//     config.headers.Authorization = `${session.access}`
//   }

//   return config
// })
