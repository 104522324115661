import React, { useRef } from 'react'
import style from './ThreeDots.module.css'
import classnames from 'classnames'
import Popover from '@mui/material/Popover'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  isRevealed?: boolean
  onClose?: () => void
  popupContent?: React.ReactNode
}

export function ThreeDots({
  popupContent,
  isRevealed,
  className,
  onClose,
  ...props
}: Props) {
  const divRef = useRef<HTMLDivElement | null>(null)

  return (
    <div {...props} className={classnames(style.root, className)} ref={divRef}>
      <Popover
        open={!!isRevealed}
        anchorEl={divRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={onClose}
        disableRestoreFocus
      >
        {popupContent}
      </Popover>
      <div className={style.dot}></div>
      <div className={style.dot}></div>
      <div className={style.dot}></div>
    </div>
  )
}
