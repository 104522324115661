import { AxiosError, AxiosResponse } from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import { GET_DOCUMENTS, setDocuments } from '../actions/appActions'
import { GetDocumentsAction } from '../types'
import { DocumentApi, GetDocumentsResponse } from '../../api/documentApi'

export enum AppError {
  IncorrectToken = 'IncorrectToken',
  Forbidden = 'Forbidden',
  InternalServerError = 'InternalServerError',
  ServiceUnavailable = 'ServiceUnavailable',
  Other = 'Other',
}

export const transformHttpErrorToAppError = (e: AxiosError) => {
  const { response } = e
  if (response?.status === 403) {
    return AppError.Forbidden
  }

  if (response?.status === 404) return AppError.IncorrectToken

  if (response?.status === 500) {
    return AppError.InternalServerError
  }

  if (response?.status === 503) {
    return AppError.ServiceUnavailable
  }

  return AppError.Other
}

function* getDocumentsWatcher(action: GetDocumentsAction) {
  try {
    const res: AxiosResponse<GetDocumentsResponse> = yield call(
      DocumentApi.getDocuments,
      action.payload,
    )

    yield put(setDocuments(res.data.documents))
  } catch (e) {
    console.log('function*getCounters -> e', e)
    // yield put(setAppError(transformHttpErrorToAppError(e)))
  }
}

function* DocumentsSaga() {
  yield takeLatest(GET_DOCUMENTS, getDocumentsWatcher)
}

export default DocumentsSaga
