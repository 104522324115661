import { axiosInstance } from 'api'
import axios from 'axios'
import { PartialDeep } from 'type-fest'
import { ID, IDocument, IPagination, ResponseError } from '../types/common'

export interface GetDocumentsResponse {
  documents: Array<IDocument>
}

export interface GetDocumentsResponse {
  payload: Array<IDocument>
  success: boolean
  error: string | null
}

export interface GetDocumentResponse {
  payload: Array<IDocument>
  success: boolean
  error: string | null
}

export interface SetHeuristicsResponse {}

export interface DeleteDocumentResponse {
  success: boolean
  error_description?: string
}

export interface AddDocumentPayload {
  name: string
  slug: string
  project_id: ID
}

export interface AddDocumentResponse {
  success: boolean
  payload?: IDocument[] // document id to track document unzipping and s3 uploading status
  error_description?: string | null
}

export interface GetDocumentsPayload extends IPagination {
  tag?: ID
}

export interface EditDocumentResponse {
  payload: null
  error: ResponseError | null
  success: boolean
}

export type EditDocumentPayload = PartialDeep<IDocument>

export interface TrainResponse {
  success: boolean
}

export class DocumentApi {
  static async train(id: ID) {
    return axiosInstance.post<TrainResponse>(`/document/train`, {
      id,
    })
  }
  static async saveMarkup(id: string) {
    const response = await axiosInstance.post(`/document/save_markup`, {
      id,
    }, {
      responseType: 'blob',
    });

    // Create a URL for the blob data
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/json' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'markup.json'); // Name the file that will be downloaded
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url); // Clean up the URL object
  }


  static async getDocuments(payload: GetDocumentsPayload) {
    return axiosInstance.get<GetDocumentsResponse | ResponseError>(
      `/document`,
      {
        params: payload,
      },
    )
  }

  static async status() {
    // return axiosInstance.get<DocumentStatusResponse>(`/document`)
  }

  static async delete(id: ID) {
    return axiosInstance.delete<DeleteDocumentResponse>(`/document/${id}`)
  }

  static async get(id: ID) {
    return axiosInstance.get<GetDocumentResponse>(`/document/${id}`)
  }

  static async add(payload: AddDocumentPayload) {
    return axiosInstance.post<AddDocumentResponse>(`/document`, payload)
  }

  static async edit({ id, ...payload }: EditDocumentPayload) {
    return axiosInstance.put<EditDocumentResponse>(`/document/${id}`, payload)
  }
}
