import { DragEvent, useEffect, useState } from "react";
import style from "./ProjectsScreen.module.css";
import { useDispatch, useSelector } from "react-redux";
import Card from "components/Card/Card";
import Heading from "components/Heading/Heading";
import Layout from "components/Layout/Layout";
import { AppStateType } from "store/reduxStore";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import Container from "components/Container/Container";
import { useNavigate } from "react-router-dom";
import {
  addProject,
  deleteProject,
  getProjects,
  setProjects,
} from "store/actions/projectActions";
import { ProjectApi } from "../../api/projectApi";
import { IProject } from "types/common";
import { Button, ButtonTheme } from "@dbrainio/shared-ui";
import { message } from "utils/toast";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import guid from "guid";
import Confirm from "components/Confirm/Confirm";
import { transformToSlug } from "utils/utils";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

interface Props {}

export default function ProjectsScreen({}: Props) {
  const orders = JSON.parse(localStorage.getItem("orders") || "null");
  const projects = useSelector(
    (state: AppStateType) => state.projects.items
  )?.map((p, i) => {
    return {
      ...p,
      order: orders && orders[p.id] ? orders[p.id] : i + 1,
    };
  });

  const isLoading = useSelector(
    (state: AppStateType) => state.projects.isLoading
  );
  const [currentProject, setCurrentProject] = useState<IProject | null>(null);
  const [toDelete, setToDelete] = useState<IProject | null>(null);
  const [isDeleteLoading, setDeleteLoading] = useState<boolean>(false);

  const [fetching, setFetching] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [requestAllowed, setRequestAllowed] = useState<boolean>(true);
  const [limit] = useState<number>(projects?.length ? 0 : 20);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (fetching && requestAllowed) {
      const isFirst = projects?.length ? false : offset === 0;
      const correctLimit = isFirst ? limit - 1 : limit; // -1 because of button plus
      dispatch(
        getProjects({
          limit: correctLimit,
          offset,
          simple: true,
          isFirst,
          setRequestAllowed,
        })
      );
      setOffset(offset + correctLimit);
      setFetching(false);
    }
    return () => {};
  }, [dispatch, fetching, offset, requestAllowed]);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);

    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = (event: any) => {
    if (
      event.target.documentElement.scrollHeight ===
      window.innerHeight + window.pageYOffset
    ) {
      setFetching(true);
    }
  };

  const sortProjects = (a: IProject, b: IProject) => a.order - b.order;

  const onAdd = async () => {
    const defaultName = "Новый проект";
    const defaultSlug = transformToSlug(defaultName) + "-" + guid.raw();

    try {
      const res = await ProjectApi.add({
        name: defaultName,
        slug: defaultSlug,
      });

      if (res.status === 201) {
        if (res.data.payload) {
          if (res.data.payload.length === 0) {
            throw new Error("Payload is empty array");
          }

          dispatch(addProject({ ...res.data.payload[0] }));
          navigate(`/projects/${res.data.payload[0].id}?new=true`);
        }
      }
    } catch (error) {
      message.error("Ошибка при создании проекта");
    }
  };

  const onStartDelete = async (p: IProject) => {
    setToDelete(p);
  };

  const onConfirmDelete = async () => {
    if (!toDelete) {
      throw new Error("ID to delete is not defined");
    }

    setDeleteLoading(true);

    try {
      const res = await ProjectApi.delete(toDelete.id);

      if (res.status === 200) {
        dispatch(deleteProject(toDelete.id));
        message.success("Проект успешно удалён");
      }
    } catch (error) {
      message.error("При удалении что-то пошло не так");
    }
    setToDelete(null);
    setDeleteLoading(false);
  };

  const onCancelDelete = async () => setToDelete(null);

  const onEdit = async (p: IProject) => {};

  const dragStartHandler = (
    e: DragEvent<HTMLDivElement>,
    project: IProject
  ) => {
    setCurrentProject(project);
  };

  const dragLeaveHandler = (e: DragEvent<HTMLDivElement>) => {
    const target = e.currentTarget as HTMLDivElement;
    target.style.backgroundColor = "white";
  };

  const dragEndHandler = (e: DragEvent<HTMLDivElement>) => {
    const target = e.currentTarget as HTMLDivElement;
    target.style.backgroundColor = "white";
  };

  const dragOverHandler = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const target = e.currentTarget as HTMLDivElement;
    target.style.backgroundColor = "lightgray";
  };

  const dropHandler = (e: DragEvent<HTMLDivElement>, project: IProject) => {
    e.preventDefault();

    const target = e.currentTarget as HTMLDivElement;
    target.style.backgroundColor = "white";

    const newProjects = projects?.map((p) => {
      if (p.id === project.id) {
        return { ...p, order: currentProject!.order };
      }

      if (p.id === currentProject?.id) {
        return { ...p, order: project.order };
      }

      return p;
    });

    if (newProjects) {
      const toLocalstorage = JSON.parse(localStorage.getItem("orders") || "{}");
      toLocalstorage[project.id] = currentProject!.order;
      toLocalstorage[currentProject!.id] = project.order;
      dispatch(setProjects(newProjects));
      localStorage.setItem("orders", JSON.stringify(toLocalstorage));
    }
  };

  return (
    <Layout className={style.root} light>
      <Dialog open={!!toDelete} onClose={onCancelDelete}>
        <DialogTitle>{"Вы уверены, что хотите удалить проект?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Удаленный проект не получится восстановить
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelDelete} theme={ButtonTheme.BlueBordered}>
            Отмена
          </Button>
          <Button
            onClick={onConfirmDelete}
            theme={ButtonTheme.Red}
            isLoading={isDeleteLoading}
          >
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
      <Confirm
        title="Вы уверены, что хотите удалить проект?"
        description="Удаленный проект не может быть восстановлен"
        isLoading={isDeleteLoading}
        confirmText="Удалить"
        onCancel={onCancelDelete}
        onConfirm={onConfirmDelete}
        open={!!toDelete}
      />
      <Container className={style.container}>
        <Heading className={style.heading}>Проекты</Heading>
        {isLoading ? (
          <LoadingScreen color="#fafafa" />
        ) : (
          <div className={style.grid}>
            <Card className={style.card} background="blue" onClick={onAdd}>
              <svg
                width="72"
                height="72"
                viewBox="0 0 72 72"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M40.0083 0H31.9917V31.9917H0V40.0083H31.9917V72H40.0083V40.0083H72V31.9917H40.0083V0Z"
                  fill="#FAFAFA"
                />
              </svg>
            </Card>
            {projects?.sort(sortProjects).map((p) => (
              <ProjectCard
                onDragStart={(e) => dragStartHandler(e, p)}
                onDragLeave={(e) => dragLeaveHandler(e)}
                onDragEnd={(e) => dragEndHandler(e)}
                onDragOver={(e) => dragOverHandler(e)}
                onDrop={(e) => dropHandler(e, p)}
                draggable={true}
                key={p.id}
                project={p}
                onDelete={onStartDelete}
                onEdit={onEdit}
                onClick={() => navigate(`/projects/${p.id}`)}
              />
            ))}
          </div>
        )}
      </Container>
    </Layout>
  );
}
