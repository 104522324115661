import { ActionTypes } from 'store/types'
import { START_ANNOTATE, COMPLETE_ANNOTATE } from '../actions/appActions'

export interface AnnotationState {
  isLoading: boolean
}
const initialState: AnnotationState = {
  isLoading: false,
}

export function annotationReducer(
  state = initialState,
  action: ActionTypes,
): AnnotationState {
  switch (action.type) {
    case START_ANNOTATE:
      return {
        ...state,
        isLoading: true,
      }
    case COMPLETE_ANNOTATE:
      return {
        ...state,
        isLoading: true,
      }

    default:
      return state
  }
}
