import { authBaseURL } from 'api'
import axios from 'axios'

export interface LoginRes {
  access: string
  refresh: string
}
export interface LoginErrorRes {
  message: string
  error: string
}

export class AuthApi {
  static login = async (payload: { username: string; password: string }) => {
    const params = new URLSearchParams()
    params.append('username', payload.username)
    params.append('password', payload.password)

    const response = await axios.post<LoginRes | LoginErrorRes>(
      authBaseURL + '/sessions/create',
      params,
      {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
    return response
  }
}
