import classNames from 'classnames'
import React, { Fragment } from 'react'
import { IDimensions } from 'types/common'
import { getNativeScrollbarSize } from 'utils/scroll'
import style from './Scroller.module.css'

export interface ScrollerProps {
  children: React.ReactNode
  outerSize: IDimensions
  innerSize: IDimensions
  className?: string
  scrollHeight: number
  disabled: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  fullscreenOffsetLeft: number
  fullscreenOffsetTop: number
}

export interface ScrollerState {
  isHScrollerMouseDown: boolean
  isVScrollerMouseDown: boolean
  scrollBarWidth: number
  vScrollRatio: number
  hScrollRatio: number
}

export default class Scroller extends React.Component<
  ScrollerProps,
  ScrollerState
> {
  lastPageX: number
  lastPageY: number
  hScroller: any
  vScroller: any
  middle: any
  outer: any
  inner: any

  constructor(props: ScrollerProps) {
    super(props)

    this.state = {
      scrollBarWidth: 0,
      hScrollRatio: 0,
      vScrollRatio: 0,
      isHScrollerMouseDown: false,
      isVScrollerMouseDown: false,
    }

    this.lastPageX = 0
    this.lastPageY = 0
  }

  componentWillMount() {
    this.prepareLayout()
    this.getScrollRatio()
  }

  componentDidMount() {
    const { outer, middle, hScroller, vScroller } = this
    const { disabled } = this.props

    outer.addEventListener('mouseenter', this.handleMouseEnter.bind(this))
    outer.addEventListener('mouseleave', this.handleMouseLeave.bind(this))
    middle.addEventListener('scroll', this.handleScroll.bind(this))

    if (hScroller)
      hScroller.addEventListener(
        'mousedown',
        this.handleHScrollerMouseDown.bind(this),
      )
    if (vScroller)
      vScroller.addEventListener(
        'mousedown',
        this.handleVScrollerMouseDown.bind(this),
      )
    if (!disabled) this.centerify()
  }

  componentWillUnmount() {
    const { outer, middle, hScroller, vScroller } = this

    outer.removeEventListener('mouseenter', this.handleMouseEnter)
    outer.removeEventListener('mouseleave', this.handleMouseLeave)
    middle.removeEventListener('scroll', this.handleScroll)

    if (hScroller)
      hScroller.removeEventListener('mousedown', this.handleHScrollerMouseDown)
    if (vScroller)
      vScroller.removeEventListener('mousedown', this.handleVScrollerMouseDown)
  }

  componentDidUpdate(prevProps: ScrollerProps) {
    const { hScroller, vScroller } = this

    if (prevProps.disabled && !this.props.disabled) {
      if (hScroller)
        hScroller.addEventListener(
          'mousedown',
          this.handleHScrollerMouseDown.bind(this),
        )
      if (vScroller)
        vScroller.addEventListener(
          'mousedown',
          this.handleVScrollerMouseDown.bind(this),
        )
    }

    if (
      prevProps.fullscreenOffsetLeft !== this.props.fullscreenOffsetLeft ||
      prevProps.fullscreenOffsetTop !== this.props.fullscreenOffsetTop
    ) {
      this.getScrollRatio()
      this.centerify()
    }
  }

  prepareLayout() {
    this.setState({
      scrollBarWidth: getNativeScrollbarSize(),
    })
  }

  getScrollRatio() {
    const { outerSize, innerSize } = this.props

    if (
      typeof outerSize.width === 'number' &&
      typeof outerSize.height === 'number' &&
      typeof innerSize.width === 'number'
    )
      this.setState({
        hScrollRatio: outerSize.width / innerSize.width,
        vScrollRatio: outerSize.height / innerSize.width,
      })
  }

  centerify() {
    const { fullscreenOffsetLeft, fullscreenOffsetTop } = this.props

    this.middle.scrollLeft = fullscreenOffsetLeft
    this.middle.scrollTop = fullscreenOffsetTop
  }

  handleHScrollerMouseDown(event: any) {
    this.setState({ isHScrollerMouseDown: true }, () => {
      this.lastPageX = event.pageX
      document.body.classList.add(style.scrollerGrabbed)

      document.addEventListener(
        'mouseup',
        this.handleDocumentMouseUp.bind(this),
      )
      document.addEventListener(
        'mousemove',
        this.handleHorizontalMouseMove.bind(this),
      )
    })
  }

  handleVScrollerMouseDown(event: any) {
    this.setState({ isVScrollerMouseDown: true }, () => {
      this.lastPageY = event.pageY
      document.body.classList.add(style.scrollerGrabbed)

      document.addEventListener(
        'mouseup',
        this.handleDocumentMouseUp.bind(this),
      )
      document.addEventListener(
        'mousemove',
        this.handleVerticalMouseMove.bind(this),
      )
    })
  }

  handleDocumentMouseUp() {
    this.setState(
      {
        isHScrollerMouseDown: false,
        isVScrollerMouseDown: false,
      },
      () => {
        document.body.classList.remove(style.scrollerGrabbed)
        document.removeEventListener('mouseup', this.handleDocumentMouseUp)
        document.removeEventListener('mousemove', this.handleVerticalMouseMove)
        document.removeEventListener(
          'mousemove',
          this.handleHorizontalMouseMove,
        )
      },
    )
  }

  handleVerticalMouseMove(event: any) {
    const { isVScrollerMouseDown, vScrollRatio } = this.state

    if (isVScrollerMouseDown) {
      const delta = event.pageY - this.lastPageY

      this.lastPageY = event.pageY
      requestAnimationFrame(() => {
        this.middle.scrollTop += delta / vScrollRatio
      })
    }
  }

  handleHorizontalMouseMove(event: any) {
    const { isHScrollerMouseDown, hScrollRatio } = this.state

    if (isHScrollerMouseDown) {
      const delta = event.pageX - this.lastPageX

      this.lastPageX = event.pageX
      requestAnimationFrame(() => {
        this.middle.scrollLeft += delta / hScrollRatio
      })
    }
  }

  handleMouseEnter() {
    const { onMouseEnter } = this.props

    this.outer.classList.add(style.scrollerHover)
    onMouseEnter && onMouseEnter()
  }

  handleMouseLeave() {
    const { onMouseLeave } = this.props

    this.outer.classList.remove(style.scrollerHover)
    onMouseLeave && onMouseLeave()
  }

  handleScroll() {
    const { innerSize, disabled } = this.props

    if (disabled) return

    if (
      typeof innerSize.width !== 'number' ||
      typeof innerSize.height !== 'number'
    )
      return

    const { scrollTop, scrollLeft } = this.middle
    const positionX = (scrollLeft / innerSize.width) * 100
    const positionY = (scrollTop / innerSize.height) * 100

    requestAnimationFrame(() => {
      if (this.hScroller) {
        this.hScroller.style.left = `${positionX}%`
      }

      if (this.vScroller) {
        this.vScroller.style.top = `${positionY}%`
      }
    })
  }

  renderScrollBars() {
    const { innerSize, outerSize, scrollHeight, disabled } = this.props
    const { scrollBarWidth } = this.state
    const isNeedHorizontalScroll = innerSize.width > outerSize.width
    const isNeedVerticalScroll = innerSize.height > outerSize.height

    if ((!isNeedHorizontalScroll && !isNeedVerticalScroll) || disabled) {
      return ''
    }
    if (
      typeof innerSize.width !== 'number' ||
      typeof innerSize.height !== 'number' ||
      typeof outerSize.width !== 'number' ||
      typeof outerSize.height !== 'number'
    )
      return

    const scrollSizeX =
      ((outerSize.width - scrollBarWidth) /
        (innerSize.width - scrollBarWidth)) *
      (outerSize.width - scrollBarWidth)
    const scrollSizeY =
      ((outerSize.height - scrollBarWidth) /
        (innerSize.height - scrollBarWidth)) *
      (outerSize.height - scrollBarWidth)

    return (
      <Fragment>
        {isNeedHorizontalScroll ? (
          <div
            className={classNames(
              style.scrollerScroll,
              style.scrollerScrollHorizontal,
            )}
            style={{ paddingRight: scrollHeight, height: scrollHeight }}
          >
            <span
              className={classNames(
                style.scrollerDrag,
                style.scrollerHorizontal,
              )}
              ref={(c) => {
                this.hScroller = c
              }}
              style={{ width: scrollSizeX }}
            />
          </div>
        ) : (
          ''
        )}
        {isNeedVerticalScroll ? (
          <div
            className={classNames(style.scrollerScroll, style.scrollerVertical)}
            style={{ paddingBottom: scrollHeight, width: scrollHeight }}
          >
            <span
              className={classNames(
                style.scrollerDrag,
                style.scrollerDragVertical,
              )}
              ref={(c) => {
                this.vScroller = c
              }}
              style={{ height: scrollSizeY }}
            />
          </div>
        ) : (
          ''
        )}
      </Fragment>
    )
  }

  render() {
    const { innerSize, outerSize, className, disabled } = this.props

    const { scrollBarWidth } = this.state

    return (
      <div
        className={classNames(className, {
          [style.scroller]: true,
          [style.scrollerDisabled]: disabled,
        })}
        ref={(c) => {
          this.outer = c
        }}
        style={{
          width: disabled ? 'auto' : outerSize.width,
          height: disabled ? 'auto' : outerSize.height,
        }}
      >
        <div
          className={classNames({
            [style.scrollerMiddle]: true,
            [style.scrollerDisabled]: disabled,
          })}
          ref={(c) => {
            this.middle = c
          }}
          style={{
            paddingRight: disabled ? 0 : scrollBarWidth,
            paddingBottom: disabled ? 0 : scrollBarWidth,
          }}
        >
          <div
            className={classNames({
              [style.scrollerInner]: true,
              [style.scrollerInnerDisabled]: disabled,
            })}
            ref={(c) => {
              this.inner = c
            }}
            style={{
              width: disabled ? 'auto' : innerSize.width,
              height: disabled ? 'auto' : innerSize.height,
              paddingRight: disabled ? 0 : scrollBarWidth,
            }}
          >
            {this.props.children}
          </div>
        </div>

        {this.renderScrollBars()}
      </div>
    )
  }
}
