import { axiosInstance } from "api";
import { ID, ResponseError, IBuild, TOCR, IProject } from "../types/common";

export interface AddBuild {
  id: ID;
  name: string;
  selected_documents_ids: string[];
  dbrain_docs: string[];
  ocr: TOCR;
}

export interface RemoveBuildPayload {
  projectId: ID;
  buildId: ID;
}

export interface EditBuildPayload {
  projectID: ID;
  buildId: ID;
  buildName: string;
}

export interface AddBuildResponse {
  payload: IBuild;
  error: ResponseError | null;
  success: boolean;
}

export interface EditBuildResponse {
  payload: IProject;
  error: ResponseError | null;
  success: boolean;
}

export class BuildApi {
  static async create({
    id,
    ocr,
    name,
    selected_documents_ids,
    dbrain_docs,
  }: AddBuild) {
    return axiosInstance.post<AddBuildResponse>(
      `/project/${id}/build`,
      {
        selected_documents_ids,
        dbrain_docs,
      },
      {
        params: {
          ocr_engine: ocr,
          name,
        },
      }
    );
  }
  static async remove({ buildId, projectId }: RemoveBuildPayload) {
    return axiosInstance.delete<{}>(`/project/${projectId}/build/${buildId}`);
  }
  static async edit({ projectID, buildId, buildName }: EditBuildPayload) {
    return axiosInstance.put<EditBuildResponse>(
      `/project/${projectID}/build/${buildId}?name=${buildName}`
    );
  }
  static async get(params: { build: IBuild; id: string }) {
    return axiosInstance({
      url: `/project/${params.id}/build/${params.build.id}`,
      responseType: "blob",
    });
  }
}
