import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC } from "react";

interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: React.ReactNode;
}

export const Button: FC<ButtonProps> = ({
  children,
  className,
  ...restProps
}) => {
  return (
    <button className={className || ""} {...restProps}>
      {children}
    </button>
  );
};
