import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

export const i18nInit = (lang: string) =>
  i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: {
          translation: {
            'Enter your token': 'Enter your token',
            Dbrain: 'Handl',
            Documentation: 'Documentation',
          },
        },
        ru: {
          translation: {
            'Enter your token': 'Введите токен',
            Dbrain: 'Dbrain',
            Documentation: 'Документация',
          },
        },
      },
      lng: lang,
      fallbackLng: 'ru',

      interpolation: {
        escapeValue: false,
      },
    })
