import { DocumentApi } from 'api/documentApi'
import { HeuristicApi } from 'api/heuristicApi'
import Heuristics from 'components/Heuristics/Heuristics'
import { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { ID, IDocument, THeuristicCollections } from 'types/common'
import { message } from 'utils/toast'
import { v4 } from 'uuid'

interface Props {}

export default function HeuristicsScreen(props: Props) {
  let { id } = useParams<{ id?: ID }>()

  const [isLoading, setLoading] = useState<boolean>(false)
  const [isAddLoading, setAddLoading] = useState<boolean>(false)
  const [document, setDocument] = useState<IDocument | null>(null)
  const [slideIdx, setSlideIdx] = useState<number>(0)

  const navigate = useNavigate()

  useEffect(() => {
    const getDocument = async () => {
      setLoading(true)
      if (!id) return

      try {
        const response = await DocumentApi.get(id)
        if (response.status === 200) {
          setDocument(response.data.payload[0])
        } else {
          message.error('Ошибка при запросе данных')
        }
      } catch (error) {
        message.error('Ошибка при запросе данных')
      }
      setLoading(false)
    }

    getDocument()
    return () => {}
  }, [setLoading, id])

  const onSubmit = async (collections: THeuristicCollections[]) => {
    if (!document) return

    setAddLoading(true)
    try {
      const res = await HeuristicApi.add({
        name: v4(),
        collections,
        document_id: document.id,
      })

      if (res.status === 200 || res.status === 201) {
        if (res.data.success && res.data.payload) {
          try {
            const res = await DocumentApi.edit({
              id: document.id,
              stages: {
                heuristics: {
                  done: true,
                },
              },
            })
            if (res.status === 200 || res.status === 201) {
              message.success('Эвристики сохранены')
              navigate(`/documents/${id}`, {
                replace: true,
              })
            }
          } catch (error) {
            message.error('Ошибка при изменении статуса этапа эвристик')
          }
        }
      } else {
        message.error('Ошибка при создании эвристик')
      }
    } catch (error) {
      message.error('Ошибка при создании эвристик')
    }
    setAddLoading(false)
  }

  if (!id) {
    return <Navigate to="/" />
  }

  return (
    document && (
      <Heuristics
        setSlideIdx={setSlideIdx}
        slideIdx={slideIdx}
        document={document}
        onSubmit={onSubmit}
        isAddLoading={isAddLoading}
      />
    )
  )
}
