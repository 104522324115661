import { axiosInstance } from 'api'
import {
  ID,
  IProject,
  IPagination,
  ResponseError,
  IBuild,
  TOCR,
} from '../types/common'

export interface GetProjectsResponse {
  payload: IProject[]
  success: boolean
  error: string | null
}

export type GetDocrDocumentsResponse = string[]

export interface GetProjectResponse {
  payload: IProject[]
  success: boolean
  error: string | null
}

export interface AddProjectPayload {
  name: string
  slug: string
}

export interface AddProjectResponse {
  payload?: IProject[]
  error: ResponseError | null
  success: boolean
}

export type EditProjectPayload = Partial<IProject>
export interface EditProjectResponse {
  payload: null
  error: ResponseError | null
  success: boolean
}

export interface DeleteProjectResponse {
  id: ID
}

export interface AddBuild {
  id: ID
  selected_documents_ids: string[]
  dbrain_docs: string[]
  ocr: TOCR
}

export interface AddBuildResponse {
  payload: IBuild
  error: ResponseError | null
  success: boolean
}

export class ProjectApi {
  static async getProjects(params: IPagination) {
    return axiosInstance.get<GetProjectsResponse>(`/project`, {
      params,
    })
  }
  static async getDocrDocuments() {
    return axiosInstance.get<GetDocrDocumentsResponse>(`/document/dbrain_docs`)
  }
  static async get(id: ID) {
    return axiosInstance.get<GetProjectResponse>(`/project/${id}`)
  }
  static async delete(id: ID) {
    return axiosInstance.delete<DeleteProjectResponse>(`/project/${id}`)
  }
  static async add(payload: AddProjectPayload) {
    return axiosInstance.post<AddProjectResponse>(`/project`, payload)
  }
  static async edit({ id, ...payload }: EditProjectPayload) {
    return axiosInstance.put<EditProjectResponse>(`/project/${id}`, payload)
  }
}
