import React, { useEffect, useRef, useState } from "react";
import style from "./DoplexScreen.module.css";
import Layout from "../../components/Layout/Layout";
import { v4 as uuidv4 } from "uuid";
import {
  Edge,
  Node,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from "react-flow-renderer";
import { DoplexApi } from "api/doplexApi";
import { AllObject, IBlock, IPipeline } from "types/common";
import { Sidebar } from "./Sidebar/Sidebar";
import { Button } from "./Button/Button";
import ReactFlowComponent from "./ReactFlowComponent/ReactFlowComponent";

import { RJSFSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/core";

const DoplexScreen = () => {
  const [blocks, setBlocks] = useState<null | IBlock[]>(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const container = useRef<HTMLDivElement | null>(null);

  const selectedNodes = nodes.filter((n) => n.selected);
  const selectedNode = selectedNodes.length === 1 ? selectedNodes[0] : null;

  const formRef = useRef<Form | null>(null);
  const [parametersSchema, setParametersSchema] = useState<null | RJSFSchema>(
    null
  );

  const onChangeFormHandler = (data: any) => {
    if (selectedNode) {
      selectedNode.data.parameters = data.formData;
    }
  };

  useEffect(() => {
    const getData = async () => {
      const res = await DoplexApi.getBlocks();

      if (res.status === 200) {
        if (res.data) {
          // удаление свойств args и kwargs из спеки блоков + очистка массива required
          const blocks = res.data.result.map((block: IBlock) => {
            delete block.properties?.parameters?.properties?.args;
            delete block.properties?.parameters?.properties?.kwargs;
            if (block.properties?.parameters?.required) {
              block.properties.parameters.required =
                block.properties.parameters.required.filter(
                  (item: string) => item !== "args" && item !== "kwargs"
                );
            }
            return block;
          });
          setBlocks(blocks.map((b) => ({ ...b, id: uuidv4() })));
        }
      }
    };
    getData();
  }, []);

  const downloadPipeline = (pipeline: IPipeline) => {
    const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify(pipeline)
    )}`;

    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "pipeline.json";

    link.click();
  };

  const formatString = (str: string[]) => {
    return str.map((s) => `$${s.replace(/-/gi, "")}`);
  };

  const findDirectionsIds = (
    node: Node,
    direction: "source" | "target",
    edges: Edge[]
  ): string[] => {
    const oppositeDirection = direction === "source" ? "target" : "source";

    const result: string[] = edges
      .filter((edge) => edge[direction] === node.id)
      .map((edge) => {
        const isParameters =
          edge.data?.selectValue && edge.data?.selectValue !== AllObject;
        const isCorrectNode =
          node.type !== "custominput" && direction === "target";

        if (isCorrectNode && isParameters) {
          return `${edge[oppositeDirection]}.${edge.data.selectValue}`;
        }

        return edge[oppositeDirection];
      });

    // node.type !== "customnode" - means that to, from fields should be unique
    return node.type !== "customnode" ? Array.from(new Set(result)) : result;
  };

  const createPipeline = () => {
    const pipeline: IPipeline = {};

    nodes.forEach((node) => {
      const isNameExists = !!pipeline[node.data.label];
      const block = blocks?.find((b) => b.id === node.data.blockID);
      const sourcesIds = findDirectionsIds(node, "target", edges);
      const nodeParameters = node.data?.parameters?.input;

      pipeline[isNameExists ? node.id : node.data.label] = {
        path: block?.properties.path || "",
        from: formatString(sourcesIds),
        id: formatString([node.id]).join(""),
        type: node.type || "",
        parameters: nodeParameters,
      };
    });

    downloadPipeline(pipeline);
  };

  useEffect(() => {
    const block = blocks?.find((b) => b.id === selectedNode?.data.blockID);

    if (block && Object.keys(block.properties.parameters.properties).length) {
      setParametersSchema({
        id: block.id,
        title: block.title,
        type: "object",
        properties: {
          input: block.properties.parameters,
        },
      });
    } else {
      setParametersSchema(null);
    }
  }, [blocks, nodes]);

  return (
    <Layout light>
      <ReactFlowProvider>
        <div className={style.container}>
          {blocks ? (
            <Sidebar blocks={blocks} />
          ) : (
            <div className={style.item}>
              <h1>Loading...</h1>
            </div>
          )}
          <div className={style.item} ref={container}>
            <Button
              className={style.button}
              onClick={() => {
                createPipeline();
              }}
            >
              Download pipeline (.json)
            </Button>
            <ReactFlowComponent
              blocks={blocks}
              nodes={nodes}
              setNodes={setNodes}
              onNodesChange={onNodesChange}
              edges={edges}
              setEdges={setEdges}
              onEdgesChange={onEdgesChange}
              container={container}
            />
          </div>
          <div className={style.item}>
            {parametersSchema && (
              /* @ts-ignore  */
              <Form
                className={style.form}
                formData={selectedNode?.data.parameters}
                ref={formRef}
                validator={validator}
                schema={parametersSchema}
                onChange={onChangeFormHandler}
              />
            )}
          </div>
        </div>
      </ReactFlowProvider>
    </Layout>
  );
};

export default DoplexScreen;
