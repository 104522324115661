import classnames from 'classnames'
import React from 'react'
import style from './Container.module.css'

export interface ContainerProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children: React.ReactNode
}

const Container = (props: ContainerProps) => {
  return (
    <div {...props} className={classnames(style.root, props.className)}>
      {props.children}
    </div>
  )
}

export default Container
