import classnames from 'classnames'
import React, { useState } from 'react'
import style from './Sidebar.module.css'
interface Props {
  children: React.ReactNode
  className?: string
}

export default function Sidebar({ children, className }: Props) {
  return <div className={classnames(style.root, className)}>{children}</div>
}
