import Aabb from 'components/Aabb/Aabb'
import CloseButton from 'components/CloseButton/CloseButton'
import { IImage, RectPayload } from 'types/common'
import { generateImgUrl } from 'utils/utils'
import style from './SegmentationScreen.module.css'

interface Props {
  image: IImage
  onSubmit: (rects: RectPayload[]) => void
  onClose: () => void
}

export default function SegmentationScreen({
  image,
  onSubmit,
  onClose,
}: Props) {
  const handleSubmit = (rects: RectPayload[]) => {
    onSubmit(rects)
  }

  return (
    <div className={style.root}>
      <CloseButton onClick={onClose} className={style.close} />
      <Aabb imageUrl={generateImgUrl(image)} onSubmit={handleSubmit} />
    </div>
  )
}
