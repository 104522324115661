import { axiosInstance } from "api";
import { ID } from "../types/common";

interface RunStopPayload {
  project_id: ID;
  build_id: ID;
}

interface RunStopUpdateResponse {
  success: boolean;
  payload: {
    created_at: number;
    tags: string[];
    uid: string;
    is_online: boolean;
    error: string;
    action: "idle" | "up" | "down" | "pending";
    build_id: string;
    port: number;
    id: string;
  };
  error: {
    code: string;
    title: string;
    description: string;
  };
}

export class StandApi {
  static async run({ project_id, build_id }: RunStopPayload) {
    return axiosInstance.post<RunStopUpdateResponse>(`/demo/start`, undefined, {
      params: {
        project_id,
        build_id,
      },
    });
  }
  static async update(id: ID) {
    return axiosInstance.put<RunStopUpdateResponse>(`/demo/${id}`);
  }
  static async stop({ build_id, project_id }: RunStopPayload) {
    return axiosInstance.post<RunStopUpdateResponse>(`/demo/stop`, undefined, {
      params: {
        project_id,
        build_id,
      },
    });
  }
}
