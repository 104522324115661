import { Checkbox, CheckboxTheme } from "@dbrainio/shared-ui";
import classNames from "classnames";
import React from "react";
import style from "./HeuristicsItem.module.css";

interface Props {
  children?: React.ReactNode;
  label?: string;
  row?: boolean;
  isChecked: boolean;
  block?: boolean;
  notRemovable?: boolean;
  onChange?: (value: boolean) => void;
  onRemove: () => void;
}

export default function HeuristicsItem({
  isChecked,
  children,
  label,
  block,
  notRemovable,
  row,
  onRemove,
  onChange = () => {},
}: Props) {
  return (
    <div
      className={classNames(style.root, {
        [style.row]: row,
        [style.block]: block,
        [style.checked]: isChecked,
        [style.empty]: !children,
      })}
    >
      <div className={style.checkbox}>
        <Checkbox
          onChange={onChange}
          isChecked={isChecked}
          theme={CheckboxTheme.Black}
          label={label}
        />
      </div>
      {!notRemovable && (
        <div className={style.delete} onClick={onRemove}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2 14L14 2" stroke="#AAAAAA" strokeWidth="2" />
            <path d="M14 14L2 2" stroke="#AAAAAA" strokeWidth="2" />
          </svg>
        </div>
      )}
      {isChecked && children}
    </div>
  );
}
