import React from 'react'
import DialogModal from 'components/DialogModal/DialogModal'

interface Props {
  open: boolean
  isLoading: boolean
  confirmText: string
  title: React.ReactNode
  description: React.ReactNode
  onCancel: () => void
  onConfirm: () => void
}
export default function Confirm({
  open,
  isLoading,
  description,
  confirmText,
  title,
  onCancel,
  onConfirm,
}: Props) {
  return (
    <DialogModal
      open={open}
      content={description}
      isConfirmLoading={isLoading}
      cancelText="Отмена"
      confirmText={confirmText}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={title}
      onClose={onCancel}
    />
  )
}
