import { IDataset, IImage } from '../../types/common'
import { ActionTypes } from 'store/types'

export interface DatasetState {
  items: IImage[] | null
  single: IDataset | null
  isLoading: boolean
  isDeleteLoading: boolean
}

const initialState: DatasetState = {
  single: null,
  isLoading: false,
  isDeleteLoading: false,
  items: [],
}

export function datasetReducer(
  state = initialState,
  action: ActionTypes,
): DatasetState {
  switch (action.type) {
    default:
      return state
  }
}
