import { createRoot } from "react-dom/client";

import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";
import store from "./store/reduxStore";
import { BrowserRouter } from "react-router-dom";
import "normalize.css";
import moment from "moment";
import "moment/locale/ru"; // without this line it didn't work

if (process.env.NODE_ENV === "production" && process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.npm_package_version,
    environment: process.env.SENTRY_ENVIRONMENT || "production",
  });
}

moment.locale("ru");

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
