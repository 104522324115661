import { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { setAppError, setDocuments } from "../actions/appActions";
import { GetDocumentsResponse } from "../../api/documentApi";
import {
  GET_PROJECTS,
  GetProjectsAction,
  setProjects,
} from "../actions/projectActions";
import { ProjectApi, GetProjectsResponse } from "../../api/projectApi";
import { transformHttpErrorToAppError } from "utils/utils";

function* getProjectsWatcher(action: GetProjectsAction) {
  try {
    const params = {
      offset: action.payload.offset,
      limit: action.payload.limit,
      simple: action.payload.simple,
    };
    const res: AxiosResponse<GetProjectsResponse> = yield call(
      ProjectApi.getProjects,
      params
    );
    if (
      (!res.data.payload.length || action.payload.limit === 0) &&
      action.payload.setRequestAllowed
    ) {
      action.payload.setRequestAllowed(false);
    }
    yield put(setProjects(res.data.payload));
  } catch (e) {
    yield put(setAppError(transformHttpErrorToAppError(e)));
  }
}

function* ProjectsSaga() {
  yield takeLatest(GET_PROJECTS, getProjectsWatcher);
}

export default ProjectsSaga;
