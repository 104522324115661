import classnames from 'classnames'
import LoadingScreen from 'components/LoadingScreen/LoadingScreen'
import React from 'react'
import Header from '../Header/Header'
import style from './Layout.module.css'

export interface LayoutProps {
  children: React.ReactNode
  light?: boolean
  loading?: boolean
  transparentLoading?: boolean
  isLogout?: boolean
  className?: string
}

export default function Layout({
  children,
  className,
  light,
  loading,
  transparentLoading,
  isLogout,
}: LayoutProps) {
  return (
    <div className={classnames(style.root, className)}>
      {transparentLoading && <LoadingScreen transparent={transparentLoading} />}
      <div className={style.header}>
        <Header light={light} isLogout={isLogout} />
      </div>
      {loading ? <LoadingScreen /> : children}
    </div>
  )
}
