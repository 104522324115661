import React, { ChangeEvent, FC, useEffect, useState } from "react";
import {
  Connection,
  getBezierPath,
  Handle,
  Position,
  ReactFlowInstance,
  useReactFlow,
} from "react-flow-renderer";
import styles from "./CustomComponents.module.css";
import { message } from "../../../utils/toast";
import { v4 as uuidv4 } from "uuid";
import { AllObject } from "../../../types/common";

const isValidConnection = (
  e: Connection,
  reactFlowInstance: ReactFlowInstance,
  key: string = ""
) => {
  if (e.target === e.source) return false;

  const nodes = reactFlowInstance.getNodes();
  const edges = reactFlowInstance.getEdges();

  const entryPoint = nodes.find((n) => n.id === e.source);
  const exitPoint = nodes.find((n) => n.id === e.target);

  if (entryPoint?.type === "custominput" && exitPoint?.type === "customoutput")
    return false;

  if (exitPoint?.type !== "customoutput") {
    const isConnected = edges.find(
      (edge) => edge.target === e.target && edge.targetHandle === e.targetHandle
    );
    return !isConnected;
  }

  const isConnected = edges.find(
    (edge) => edge.target === e.target && edge.sourceHandle === e.sourceHandle
  );

  return !isConnected;
};

interface TextNodeProps {
  obj: any;
}

const TextNode: FC<TextNodeProps> = ({ obj }) => {
  const [isRename, setIsRename] = useState<boolean>(false);
  const [value, setValue] = useState<string>(obj.data.label);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value !== value && setValue(e.target.value);
    obj.data.label = e.target.value;
  };

  return isRename ? (
    <input
      className={styles.input_text}
      type="text"
      value={value}
      onChange={onChange}
      autoFocus={true}
      onBlur={() => {
        setIsRename(false);
        if (value === "") {
          message.error("Имя не может быть пустым!");
          setValue(obj.data.defaultLabel);
        }
      }}
    />
  ) : (
    <div onDoubleClick={() => setIsRename(true)}>{value}</div>
  );
};

const getArrayOfPoints = (points: number) => {
  const coordinate = 100 / (points + 1);
  return new Array(points)
    .fill(coordinate)
    .map((el, index) => el * (index + 1) + "%");
};

export const CustomNode: FC<any> = (obj) => {
  const inputPoints = getArrayOfPoints(obj.data.inputPoints);
  const outputPoints = getArrayOfPoints(obj.data.outputPoints);
  const reactFlowInstance = useReactFlow();

  return (
    <>
      {inputPoints.length > 0 &&
        inputPoints.map((point, index) => (
          <Handle
            key={uuidv4()}
            id={`${index}`}
            type="target"
            position={Position.Top}
            style={{ left: point }}
            isValidConnection={(e) => isValidConnection(e, reactFlowInstance)}
          />
        ))}
      <TextNode obj={obj} />
      {outputPoints.length > 0 &&
        outputPoints.map((point, index) => (
          <Handle
            key={uuidv4()}
            id={`${index}`}
            type="source"
            position={Position.Bottom}
            style={{ left: point }}
            isValidConnection={(e) =>
              isValidConnection(e, reactFlowInstance, "output")
            }
          />
        ))}
    </>
  );
};

export const CustomInput: FC<any> = (obj) => {
  const reactFlowInstance = useReactFlow();

  return (
    <>
      <TextNode obj={obj} />
      <Handle
        type="source"
        position={Position.Bottom}
        isValidConnection={(e) => isValidConnection(e, reactFlowInstance)}
      />
    </>
  );
};

export const CustomOutput: FC<any> = (obj) => {
  const reactFlowInstance = useReactFlow();

  return (
    <>
      <TextNode obj={obj} />
      <Handle
        type="target"
        position={Position.Top}
        isValidConnection={(e) =>
          isValidConnection(e, reactFlowInstance, "output")
        }
      />
    </>
  );
};

export const CustomEdge: FC<any> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  markerEnd,
  selected,
}) => {
  const [selectValue, setSelectValue] = useState<string>(data?.selectValue);

  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path
        id={id}
        style={{
          strokeOpacity: 0,
          strokeWidth: 15,
          cursor: "pointer",
        }}
        className="react-flow__edge-path"
        d={edgePath}
      />
      <path
        id={id}
        style={{ ...style, pointerEvents: "none" }}
        className={`react-flow__edge-path`}
        d={edgePath}
        markerEnd={markerEnd}
      />

      {data?.selectItems?.length > 0 && selected && (
        <foreignObject
          width={150}
          height={150}
          x={(sourceX + targetX) / 2 - 25}
          y={(targetY + sourceY) / 2 - 17}
          style={{ pointerEvents: "none" }}
        >
          <select
            onChange={(e) => {
              data.selectValue = e.target.value;
              setSelectValue(e.target.value);
            }}
            value={selectValue}
            style={{
              outline: "none",
              border: "1px solid blue",
              borderRadius: "5px",
              fontSize: "10px",
              userSelect: "none",
              pointerEvents: "all",
            }}
          >
            <option value={AllObject}>{AllObject}</option>
            {data.selectItems.map((item: string) => (
              <option key={uuidv4()} value={item}>
                {item}
              </option>
            ))}
          </select>
        </foreignObject>
      )}
      {data?.text && selected && (
        <text
          style={{
            pointerEvents: "none",
            userSelect: "none",
            fontSize: "10px",
          }}
          x={(sourceX + targetX) / 2}
          y={(targetY + sourceY) / 2}
        >
          {data.text}
        </text>
      )}
    </>
  );
};
