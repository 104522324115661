import React, {
  Dispatch,
  DragEvent,
  FC,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import ReactFlow, {
  addEdge,
  Connection,
  Edge,
  MarkerType,
  Node,
  ReactFlowInstance,
} from "react-flow-renderer";
import { getCorrectNodes } from "../helpers/getCorrectNodes";
import { v4 as uuidv4 } from "uuid";
import {
  CustomEdge,
  CustomInput,
  CustomNode,
  CustomOutput,
} from "../CustomComponents/CustomComponents";
import { AllObject, IBlock } from "../../../types/common";

const deleteKeyCodes = ["Backspace", "Delete"];

const nodeTypes = {
  customnode: CustomNode,
  custominput: CustomInput,
  customoutput: CustomOutput,
};

const edgeTypes = {
  custom: CustomEdge,
};

interface ReactFlowComponentProps {
  container: MutableRefObject<HTMLDivElement | null>;
  nodes: Node<any>[];
  setNodes: Dispatch<SetStateAction<Node<any>[]>>;
  onNodesChange: any;
  edges: Edge<any>[];
  setEdges: Dispatch<SetStateAction<Edge<any>[]>>;
  onEdgesChange: any;
  blocks: null | IBlock[];
}

export const ReactFlowComponent: FC<ReactFlowComponentProps> = ({
  container,
  onNodesChange,
  setNodes,
  nodes,
  edges,
  setEdges,
  onEdgesChange,
  blocks,
}) => {
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance>();

  const onConnect = (params: Connection) => {
    let edgeParams: any = { ...params, type: "custom" };
    const sourceNode = nodes.find((n) => n.id === params.source);
    const targetNode = nodes.find((n) => n.id === params.target);

    if (sourceNode?.type === "customnode" && blocks) {
      const dataStructures: string[] = ["string", "number", "boolean", "array"];
      const output: any = blocks.find((b) => b.id === sourceNode.data.blockID)
        ?.properties.output;
      const type = output?.type;

      if (type && dataStructures.includes(type)) {
        const isArray = type === "array";
        const text = isArray ? "items" : "value";
        edgeParams = { ...edgeParams, data: { text: `${text}(${type})` } };
      } else if (type && type === "object") {
        const properties = output?.properties;

        if (properties) {
          const keys = Object.keys(properties);
          edgeParams = {
            ...edgeParams,
            data: {
              selectItems: keys,
              selectValue: AllObject,
            },
          };
        } else {
          edgeParams = { ...edgeParams, data: { text: "empty{}" } };
        }
      }
    } else if (sourceNode?.type === "custominput" && blocks) {
      const input: any = blocks.find((b) => b.id === targetNode?.data.blockID)
        ?.properties.input;
      const type = input?.type;

      if (type && type === "object") {
        const properties = input?.properties;

        if (properties) {
          const keys = Object.keys(properties);
          edgeParams = {
            ...edgeParams,
            data: {
              selectItems: keys,
              selectValue: AllObject,
            },
          };
        } else {
          edgeParams = { ...edgeParams, data: { text: "empty{}" } };
        }
      }
    }

    setEdges((eds) => addEdge({ ...edgeParams }, eds));
  };

  const onDragOver = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event: DragEvent) => {
      event.preventDefault();
      const reactFlowBounds = container.current!.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");
      const inputValue = event.dataTransfer.getData("inputValue");
      const title = event.dataTransfer.getData("title");
      const blockID = event.dataTransfer.getData("blockID");
      const inputPoints = Number(event.dataTransfer.getData("inputPoints"));
      const outputPoints = Number(event.dataTransfer.getData("outputPoints"));

      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = reactFlowInstance!.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNode = {
        id: uuidv4(),
        type,
        position,
        data: {
          label: title,
          defaultLabel: title,
          inputPoints,
          outputPoints,
          blockID,
          inputValue,
        },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [container, reactFlowInstance, setNodes]
  );

  return (
    <ReactFlow
      defaultEdgeOptions={{
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
      }}
      deleteKeyCode={deleteKeyCodes}
      nodes={nodes}
      edges={edges}
      // onEdgesDelete={onEdgesDeleteHandler}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      onInit={setReactFlowInstance}
      className="validationflow"
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      onDrop={onDrop}
      onDragOver={onDragOver}
    />
  );
};

export default ReactFlowComponent;
