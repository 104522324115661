import { applyMiddleware, combineReducers, compose, createStore } from 'redux'

import createSagaMiddleware from 'redux-saga'
import { appReducer } from './reducers/appReducer'
import rootSaga from './sagas'
import { documentReducer } from './reducers/documentReducer'
import { annotationReducer } from './reducers/annotationReducer'
import { projectReducer } from './reducers/projectReducer'
import { datasetReducer } from './reducers/datasetReducer'

let rootReducer = combineReducers({
  app: appReducer,
  documents: documentReducer,
  annotation: annotationReducer,
  projects: projectReducer,
  datasets: datasetReducer,
})

type RootReducerType = typeof rootReducer
export type AppStateType = ReturnType<RootReducerType>

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const sagaMiddleware = createSagaMiddleware()
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
)

sagaMiddleware.run(rootSaga)
export default store
