import React from 'react'
import { TextInput } from '@dbrainio/shared-ui'
import style from './MultiStringInput.module.css'
import classNames from 'classnames'
import PlusButton from 'components/Miniplus/Miniplus'

interface Props {
  className?: string
  strings: string[]
  onChange: (strings: string[]) => void
}

export default function MultiStringInput({
  strings,
  className,
  onChange,
}: Props) {
  return (
    <div className={classNames(style.root, className)}>
      {strings.map((s, i) => (
        <TextInput
          key={i}
          value={s}
          inputSize="small"
          className={style.input}
          onChange={(e) => {
            onChange(
              strings.map((str, idx) =>
                idx === i ? e.currentTarget.value : str,
              ),
            )
          }}
        />
      ))}
      <PlusButton onClick={() => onChange([...strings, ''])} />
    </div>
  )
}
