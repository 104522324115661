import { LoginRes } from 'api/authApi'
import * as React from 'react'
import { LocalStorage } from 'utils/localStorage'

const loginRes = LocalStorage.getLoginRes()

type Action = { type: 'login'; payload: { res: LoginRes } } | { type: 'logout' }

type Dispatch = (action: Action) => void
type State = { loginRes: LoginRes | null }
type CountProviderProps = { children: React.ReactNode }

const AuthStateContext = React.createContext<State | undefined>(undefined)
const AuthDispatchContext = React.createContext<Dispatch | undefined>(undefined)

function AuthReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'login': {
      return { ...state, loginRes: action.payload.res }
    }
    case 'logout': {
      return { ...state, loginRes: null }
    }
    default: {
      throw new Error(`Unhandled action type: ${(action as Action).type}`)
    }
  }
}

function AuthProvider({ children }: CountProviderProps) {
  const [state, dispatch] = React.useReducer(AuthReducer, {
    loginRes: loginRes ? loginRes : null,
  })

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

function useAuthState() {
  const context = React.useContext(AuthStateContext)
  if (context === undefined) {
    throw new Error('useCountState must be used within a CountProvider')
  }
  return context
}

function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext)
  if (context === undefined) {
    throw new Error('useCountDispatch must be used within a CountProvider')
  }
  return context
}

export { AuthProvider, useAuthState, useAuthDispatch }
