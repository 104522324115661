import { IProject } from "../../types/common";
import {
  ProjectActionTypes,
  SET_PROJECTS,
  ADD_PROJECT,
  DELETE_PROJECT,
} from "../actions/projectActions";
import { GET_PROJECTS } from "../actions/projectActions";

export interface ProjectsState {
  items: IProject[] | null;
  single: IProject | null;
  isLoading: boolean;
  isDeleteLoading: boolean;
}

const initialState: ProjectsState = {
  isLoading: false,
  isDeleteLoading: false,
  single: null,
  items: null,
};

export function projectReducer(
  state = initialState,
  action: ProjectActionTypes
): ProjectsState {
  switch (action.type) {
    case SET_PROJECTS:
      if (state.items) {
        state.items = state.items.map((item) => {
          const isProjectExist = action.payload.find(
            (project) => item.id === project.id
          );

          return isProjectExist || item;
        });
      }
      const uniqueProjects = action.payload.filter((project) => {
        const isProjectExist = state.items?.find(
          (item) => item.id === project.id
        );
        return !isProjectExist;
      });
      return {
        ...state,
        items: action.fromLogin
          ? [...action.payload] // ~ []
          : [...(state.items || []), ...uniqueProjects],
        isLoading: false,
      };
    case DELETE_PROJECT:
      return {
        ...state,
        items:
          (state.items && state.items.filter((p) => p.id !== action.payload)) ||
          null,
      };
    case GET_PROJECTS:
      return {
        ...state,
        isLoading: action.payload.limit === 0 || !!action.payload.isFirst,
      };
    case ADD_PROJECT:
      return {
        ...state,
        items: state.items
          ? [action.payload, ...state.items]
          : [action.payload],
        isLoading: false,
      };
    default:
      return state;
  }
}
