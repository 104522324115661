export const isBrowser = typeof document === 'object'

export const getNativeScrollbarSize = () => {
  const t = document.createElement('div')

  t.style.cssText =
    'width: 100px; height: 100px; overflow: scroll; position: absolute; top: -9999px;'

  document.body.appendChild(t)

  const width = t.offsetWidth - t.clientWidth

  document.body.removeChild(t)

  return width === 0 ? 30 : width + 15
}

export const requestAnimationFrame = (fn: FrameRequestCallback) => {
  if (isBrowser) {
    return (
      window.requestAnimationFrame(fn) ||
      window.setImmediate(fn) ||
      (function (c) {
        return setTimeout(c, 0)
      })(fn)
    )
  }
}
