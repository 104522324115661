import React from 'react'
import { TextInput } from '@dbrainio/shared-ui'
import style from './MultiSymbolInput.module.css'
import classNames from 'classnames'
import PlusButton from 'components/Miniplus/Miniplus'

interface Props {
  className?: string
  symbols: string[]
  onChange: (symbols: string[]) => void
}

export default function MultiSymbolInput({
  symbols,
  className,
  onChange,
}: Props) {
  return (
    <div className={classNames(style.root, className)}>
      {symbols.map((s, i) => (
        <TextInput
          key={i}
          oneSymboled
          value={s}
          onChange={(e) => {
            onChange(
              symbols.map((symbol, idx) =>
                idx === i ? e.currentTarget.value.slice(0, 1) : symbol,
              ),
            )
          }}
        />
      ))}
      <PlusButton onClick={() => onChange([...symbols, ''])} />
    </div>
  )
}
