import React from 'react'
import style from './Header.module.css'
import { Button, ButtonSize, ButtonTheme } from '@dbrainio/shared-ui'
import { useNavigate, Link, useMatch } from 'react-router-dom'
import DbrainStudioLogo from '../DbrainStudioLogo/DbrainStudioLogo'
import { DEFAULT_ROUTE } from 'routes/routes'
import { LocalStorage } from 'utils/localStorage'
import { useAuthDispatch } from 'context/AuthContext'
import classnames from 'classnames'
export interface HeaderProps {
  light?: boolean
  isLogout?: boolean
}

const Header = ({ light, isLogout = true }: HeaderProps) => {
  const dispatch = useAuthDispatch()

  const navigate = useNavigate()

  const handleLogout = () => {
    LocalStorage.logout()
    dispatch({ type: 'logout' })
    navigate('/login')
  }

  const handleHome = () => {
    navigate(DEFAULT_ROUTE)
  }

  let match = useMatch({
    path: '/projects',
    end: true,
    caseSensitive: true,
  })

  return (
    <div
      className={classnames(style.root, {
        [style.dark]: light,
      })}
    >
      <div className={style.content}>
        <span onClick={handleHome} className={style.logo}>
          <DbrainStudioLogo light={light} />
        </span>
        <div className={style.nav}>
          {/* // TODO: check */}
          {!match && (
            <Link to="/projects" className={style.link}>
              Проекты
            </Link>
          )}
        </div>
        {isLogout && (
          <Button
            theme={ButtonTheme.Gray}
            onClick={handleLogout}
            size={ButtonSize.XS}
            className={style.logout}
          >
            Выйти
          </Button>
        )}
      </div>
    </div>
  )
}

export default Header
