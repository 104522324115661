import React from 'react'
import style from './MagnifyButtons.module.css'

interface Props {
  onPlus: () => void
  onMinus: () => void
  percent: number
}

export default function MagnifyButtons({ percent, onPlus, onMinus }: Props) {
  return (
    <div className={style.root}>
      <div className={style.plus} onClick={onPlus}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 9V14L7 16H9V14V9H14H16V7L14 7H9V2V0H7L7 2V7H2L0 7V9H2H7Z"
            fill="#AAAAAA"
          />
        </svg>
      </div>
      <div className={style.percent}>{percent}%</div>
      <div className={style.minus} onClick={onMinus}>
        <svg
          width="16"
          height="2"
          viewBox="0 0 16 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="2"
            width="2"
            height="16"
            transform="rotate(-90 0 2)"
            fill="#AAAAAA"
          />
        </svg>
      </div>
    </div>
  )
}
