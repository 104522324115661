import React, { Component } from 'react'
import { v4 as uuidv4 } from 'uuid'
import debounce from 'lodash/debounce'
import { Button } from '@dbrainio/shared-ui'
import FullscreenCloseIcon from 'components/FullscreenCloseIcon/FullscreenCloseIcon'
import Scroller from 'components/Scroller/Scroller'
import FullscreenIcon from 'components/FullscreenIcon/FullscreenIcon'
import style from '../Aabb.module.css'
import classnames from 'classnames'
import MagnifyButtons from 'components/MagnifyButtons/MagnifyButtons'
import { IRects, IRectStyle } from '../Aabb'
import { IDimensions, IRect } from 'types/common'

const getX = (e: React.MouseEvent | MouseEvent) =>
  (e.clientX || 0) + window.scrollX

const getY = (e: React.MouseEvent | MouseEvent) =>
  (e.clientY || 0) + window.scrollY

const offset = (el: Element) => {
  const rect = el.getBoundingClientRect()
  const scrollLeft =
    window.pageXOffset || document.documentElement.scrollLeft || 0
  const scrollTop =
    window.pageYOffset || document.documentElement.scrollTop || 0
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export interface AabbCoreProps {
  coreref?: React.RefObject<HTMLDivElement>
  containerClass: string
  minBoxSide: number
  imageUrl?: string
  rects: IRects
  label: string | null
  rectStyle: IRectStyle
  onPathsUpdate: (rects: IRects) => void
  imageRef: React.RefObject<HTMLImageElement>
  coreMiddleRef: React.RefObject<HTMLDivElement>
  mouseDownHandler: (value: boolean) => void
  innerFullscreenSize: IDimensions
  handleButtonToggleFullscreen: (
    zoomRatioPercentage: number,
    fullscreenCallback: () => void,
  ) => void
  isFullscreen?: boolean
  imageFullscreenSize: IDimensions
  fullscreenOffsetLeft: number
  fullscreenOffsetTop: number
  zoomRatioPercentage: number
  changeZoomRatio: (zoomRatioPercentage: number, callback?: () => void) => void
  onStartNew: () => void
  onEndNew: () => void
}

export default interface AabbCoreState {
  currentRectId: string | null
  rects: IRects
}

export default class AabbCore extends Component<AabbCoreProps, AabbCoreState> {
  maxZoom: number
  minZoom: number
  zoomStep: number
  _onResize: (() => void) | null = null
  _mouseup: ((e: React.MouseEvent | MouseEvent) => void) | null = null
  _mousemove: ((e: React.MouseEvent | MouseEvent) => void) | null = null

  constructor(props: AabbCoreProps) {
    super(props)

    // @ts-ignore
    this.state = {
      rects: this.props.rects || {},
      currentRectId: null,
    }

    this.maxZoom = 2
    this.minZoom = 0.5
    this.zoomStep = 0.1

    this._onMouseMove = this._onMouseMove.bind(this)
    this._onMouseUp = this._onMouseUp.bind(this)
  }

  componentWillMount() {
    this._onResize = debounce(this.setResolution.bind(this), 250)
    window.addEventListener('resize', this._onResize)
    document.addEventListener('mousemove', this._onMouseMove)
    document.addEventListener('mouseup', this._onMouseUp)
  }

  componentDidMount() {
    this.setResolution()
  }

  componentWillReceiveProps(nextProps: AabbCoreProps) {
    const equal =
      JSON.stringify(Object.keys(nextProps.rects)) ===
      JSON.stringify(Object.keys(this.props.rects))
    if (!equal) {
      this._mouseup = null
      this.setState({ rects: nextProps.rects })
    }

    if (nextProps.zoomRatioPercentage !== this.props.zoomRatioPercentage) {
      this.setResolution()
    }
    if (nextProps.isFullscreen !== this.props.isFullscreen) {
      this.setResolution()
    }
  }

  componentWillUnmount() {
    this._onResize && window.removeEventListener('resize', this._onResize)
    document.removeEventListener('mousemove', this._onMouseMove)
    document.removeEventListener('mouseup', this._onMouseUp)
  }

  setResolution() {
    const el = this.props.imageRef?.current

    if (!el) {
      throw new Error('imageRef is null')
    }

    const width = el.offsetWidth
    const height = el.offsetHeight
    const { rects } = this.state

    Object.keys(rects).forEach((rectId) => {
      if (Object.prototype.hasOwnProperty.call(rects, rectId)) {
        const rect = rects[rectId]
        rect.x = rect.rx * width
        rect.y = rect.ry * height
        rect.width = rect.rwidth * width
        rect.height = rect.rheight * height
      }
    })

    this.setState({ rects })
  }

  _onMouseMove(e: React.MouseEvent | MouseEvent) {
    if (this._mousemove) {
      this._mousemove(e)
    }
  }

  _onMouseUp(e: React.MouseEvent | MouseEvent) {
    if (this._mouseup) {
      this.props.onEndNew()
      this._mouseup(e)
    }
  }

  el(): Element | null {
    return document.querySelector(`.${this.props.containerClass}`)
  }

  moveRect(rectId: string, e: React.MouseEvent<SVGGElement, MouseEvent>) {
    const { rects } = this.state
    const { label } = this.props

    const movingRect = rects[rectId]

    if (!label || movingRect.fieldId !== label) {
      return
    }

    e.stopPropagation()
    e.preventDefault()

    this.props.mouseDownHandler(true)

    if (!movingRect.showControls) {
      this.switchRectControls(rectId, true)
    }

    movingRect.showDelete = false
    const startX = getX(e)
    const startY = getY(e)
    const x0 = movingRect.x
    const y0 = movingRect.y

    this.setState({ currentRectId: rectId })

    this._mousemove = (moveEvent: React.MouseEvent | MouseEvent) => {
      moveEvent.preventDefault()
      moveEvent.stopPropagation()
      const x = getX(moveEvent)
      const y = getY(moveEvent)
      movingRect.x = x0 + x - startX
      movingRect.y = y0 + y - startY
      this._fixMovingRect(movingRect, false)
      rects[rectId] = movingRect
      this.setState({ rects })
      this.props.onPathsUpdate && this.props.onPathsUpdate(rects)
    }

    this._mouseup = () => {
      this.setState({ currentRectId: null })
      movingRect.showDelete = true
      // setNoScroll(false)
      this._mousemove = null
      this.props.mouseDownHandler(false)
    }
  }

  _fixRect(rect: IRect, fixSize: boolean) {
    const el = this.el()

    if (el) {
      rect.rx = rect.x / el.clientWidth
      rect.ry = rect.y / el.clientHeight
    }

    if (fixSize) {
      const { minBoxSide } = this.props
      const { isFullscreen } = this.props
      if (isFullscreen) {
        rect.width = Math.max(minBoxSide, rect.width)
        rect.height = Math.max(minBoxSide, rect.height)
      } else {
        rect.width = Math.max(4, rect.width)
        rect.height = Math.max(4, rect.height)
      }
    }
    if (el) {
      rect.rwidth = rect.width / el.clientWidth
      rect.rheight = rect.height / el.clientHeight
    }
  }

  _fixMovingRect(rect: IRect, fixSize: boolean) {
    const el = this.el()
    if (!!el) {
      rect.x = Math.max(rect.x, 1)
      rect.x = el && Math.min(rect.x, el.clientWidth - rect.width - 1)
      rect.y = Math.max(rect.y, 1)
      rect.y = Math.min(rect.y, el.clientHeight - rect.height - 1)
      this._fixRect(rect, fixSize)
    }
  }

  _fixResizingRect(rect: IRect, fixSize: boolean) {
    const el = this.el()
    if (!!el) {
      const right = Math.min(rect.x + rect.width, el.clientWidth - 1)
      const bottom = Math.min(rect.y + rect.height, el.clientHeight - 1)
      rect.x = Math.max(rect.x, 1)
      rect.width = right - rect.x
      rect.y = Math.max(rect.y, 1)
      rect.height = bottom - rect.y
      this._fixRect(rect, fixSize)
    }
  }

  // create rect also
  resizeRect(
    rectId: string | null,
    downEvent: React.MouseEvent<
      SVGRectElement | SVGCircleElement | SVGSVGElement,
      MouseEvent
    >,
    resize?: {
      left?: boolean
      right?: boolean
      top?: boolean
      bottom?: boolean
    },
  ) {
    const { minBoxSide, label, rectStyle } = this.props
    const { rects } = this.state

    downEvent && downEvent.stopPropagation()
    downEvent && downEvent.preventDefault()
    this.props.mouseDownHandler(true)

    const startX = getX(downEvent)
    const startY = getY(downEvent)

    let rect: IRect | null = rectId ? rects[rectId] : null

    if (!label) return

    if (!rectId) {
      this.props.onStartNew()

      const el = this.el()
      if (!el) return

      const { top, left } = offset(el)

      rect = {
        fieldId: label,
        style: rectStyle,
        showDelete: false,
        new: true,
        x: startX - left,
        y: startY - top,
        width: 0,
        height: 0,
        createdAt: +new Date(),
        idx: 0,
        rheight: 0,
        rwidth: 0,
        rx: 0,
        ry: 0,
        showControls: false,
      }

      rectId = uuidv4()

      this._fixResizingRect(rect, false)
      rects[rectId] = rect
      this.setState({ rects })
    }

    if (!label || (rect && rect.fieldId !== label)) {
      return
    }

    if (rect && !rect.showControls) {
      this.switchRectControls(rectId, true)
    }

    if (!rect) {
      throw new Error('Rect is null')
    }

    rect.showDelete = false
    const prevWidth = rect.width
    const prevHeight = rect.height
    const x0 = rect.x
    const y0 = rect.y
    const r = resize || {
      top: true,
      left: true,
      bottom: true,
      right: true,
    }

    this.setState({ currentRectId: rectId })

    this._mousemove = (moveEvent: React.MouseEvent | MouseEvent) => {
      if (!rect) {
        throw new Error('rect is null')
      }
      moveEvent.preventDefault()
      moveEvent.stopPropagation()

      const { currentRectId } = this.state

      if (!currentRectId) {
        this.props.mouseDownHandler(false)
        return
      }

      this.props.mouseDownHandler(true)

      if (rect && !rect.showControls && rectId) {
        this.switchRectControls(rectId, true)
      }

      const x = getX(moveEvent)
      const y = getY(moveEvent)
      const _minBoxSide = rect.new ? 0 : minBoxSide

      if (r.left && prevWidth + (startX - x) > _minBoxSide) {
        rect.x = x0 - (startX - x)
        rect.width = Math.max(prevWidth + (startX - x), 0)
      }
      if (r.top && prevHeight + (startY - y) > _minBoxSide) {
        rect.y = y0 - (startY - y)
        rect.height = Math.max(prevHeight + (startY - y), 0)
      }
      if (r.right && x - startX + prevWidth > _minBoxSide) {
        rect.width = Math.max(x - startX + prevWidth, 0)
      }
      if (r.bottom && y - startY + prevHeight > _minBoxSide) {
        rect.height = Math.max(y - startY + prevHeight, 0)
      }

      this._fixResizingRect(rect, !rect.new)
      //@ts-ignore
      rects[rectId] = rect
      this.setState({ rects })
    }

    this._mouseup = () => {
      if (!rect) {
        throw new Error('rect is null')
      }

      const currentRectId = null
      rect.showDelete = true
      rect.new = false
      rect.createdAt = +new Date()
      this._fixResizingRect(rect, true)
      this.setState({ rects, currentRectId })
      this._mousemove = null
      this.props.mouseDownHandler(false)
      this.props.onPathsUpdate(rects)
    }
  }

  deleteRect(
    rectId: string,
    e: React.MouseEvent<SVGCircleElement, MouseEvent>,
  ) {
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }
    const { rects } = this.state

    delete rects[rectId]
    this.setState({ rects })
    this.props.mouseDownHandler(false)
    this.props.onPathsUpdate(rects)
  }

  switchRectControls(rectId: string, isVisible: boolean) {
    const { rects } = this.state
    rects[rectId].showControls =
      typeof isVisible === 'undefined' ? !rects[rectId].showControls : isVisible
    if (rects[rectId].showControls) {
      Object.keys(rects).forEach((rId) => {
        if (
          Object.prototype.hasOwnProperty.call(rects, rId) &&
          rId !== rectId
        ) {
          rects[rId].showControls = false
        }
      })
    }
    this.setState({ rects })
  }

  handleSvgAreaMouseup() {
    this.props.mouseDownHandler(false)
  }

  handleZoomButtonClick(type: 'down' | 'up', callback: () => void) {
    const { zoomRatioPercentage, changeZoomRatio } = this.props

    const zoomRatio =
      type === 'up'
        ? zoomRatioPercentage + this.zoomStep
        : zoomRatioPercentage - this.zoomStep

    changeZoomRatio(parseFloat(zoomRatio.toFixed(1)), callback)
  }

  renderRectControls(rect: IRect, rectId: string) {
    const xResize = Math.min(10, rect.width / 10)
    const yResize = Math.min(10, rect.height / 10)
    const xyResize = Math.min(xResize, yResize)
    const delSize = Math.min(9, rect.width / 3, rect.height / 3)
    const crossRadius = delSize * (2 / 3)
    const crossLineSize = Math.sqrt((crossRadius * crossRadius) / 2)
    let del: React.ReactNode = null
    if (rect.showDelete) {
      del = (
        <g>
          <circle
            className={style.rectCircleDelete}
            cx={rect.x + rect.width / 2}
            cy={rect.y + rect.height / 2}
            r={delSize}
            style={{ fill: rect.style.stroke }}
          />
          <line
            x1={rect.x + rect.width / 2 - crossLineSize}
            y1={rect.y + rect.height / 2 - crossLineSize}
            x2={rect.x + rect.width / 2 + crossLineSize}
            y2={rect.y + rect.height / 2 + crossLineSize}
            style={{ stroke: 'white', strokeWidth: 2 }}
          />
          <line
            x1={rect.x + rect.width / 2 + crossLineSize}
            y1={rect.y + rect.height / 2 - crossLineSize}
            x2={rect.x + rect.width / 2 - crossLineSize}
            y2={rect.y + rect.height / 2 + crossLineSize}
            style={{ stroke: 'white', strokeWidth: 2 }}
          />
          <circle
            className={classnames(style.rectCircleDelete, style.transparent)}
            onMouseDown={this.deleteRect.bind(this, rectId)}
            cx={rect.x + rect.width / 2}
            cy={rect.y + rect.height / 2}
            r={delSize}
          />
        </g>
      )
    }

    if (rect.fieldId !== this.props.label) {
      return <g>{del}</g>
    }

    return (
      <g>
        <rect
          onMouseDown={(e: React.MouseEvent<SVGRectElement, MouseEvent>) =>
            this.resizeRect(rectId, e, { top: true })
          }
          x={rect.x}
          y={rect.y - yResize}
          width={rect.width}
          height={2 * yResize}
          className="n-resize transparent"
        />
        <rect
          onMouseDown={(e: React.MouseEvent<SVGRectElement, MouseEvent>) =>
            this.resizeRect(rectId, e, { right: true })
          }
          x={rect.x + rect.width - xResize}
          y={rect.y}
          width={2 * xResize}
          height={rect.height}
          className="e-resize transparent"
        />
        <rect
          onMouseDown={(e: React.MouseEvent<SVGRectElement, MouseEvent>) =>
            this.resizeRect(rectId, e, { bottom: true })
          }
          x={rect.x}
          y={rect.y + rect.height - yResize}
          width={rect.width}
          height={2 * yResize}
          className="s-resize transparent"
        />
        <rect
          onMouseDown={(e: React.MouseEvent<SVGRectElement, MouseEvent>) =>
            this.resizeRect(rectId, e, { left: true })
          }
          x={rect.x - xResize}
          y={rect.y}
          width={2 * xResize}
          height={rect.height}
          className="w-resize transparent"
        />

        <circle
          onMouseDown={(e) =>
            this.resizeRect(rectId, e, { top: true, left: true })
          }
          className="nw-resize transparent"
          cx={rect.x}
          cy={rect.y}
          r={xyResize}
        />
        <circle
          onMouseDown={(e) =>
            this.resizeRect(rectId, e, {
              bottom: true,
              right: true,
            })
          }
          className="se-resize transparent"
          cx={rect.x + rect.width}
          cy={rect.y + rect.height}
          r={xyResize}
        />
        <circle
          onMouseDown={(e) =>
            this.resizeRect(rectId, e, { bottom: true, left: true })
          }
          className="sw-resize transparent"
          cx={rect.x}
          cy={rect.y + rect.height}
          r={xyResize}
        />

        {del}
      </g>
    )
  }

  renderRect(rect: IRect, key: string, rectId: string) {
    if (rect) {
      const current = rect.fieldId === this.props.label
      const { strokeWidth, fontSize, stroke } = rect.style

      return (
        <g
          onMouseEnter={this.switchRectControls.bind(this, rectId, true)}
          onMouseLeave={this.switchRectControls.bind(this, rectId, false)}
          onMouseDown={this.moveRect.bind(this, rectId)}
          key={key}
          className={classnames({ [style.current]: !!current })}
        >
          <rect
            x={rect.x}
            y={rect.y}
            width={rect.width}
            height={rect.height}
            className={style.rect}
            style={rect.style}
          />

          <text
            x={rect.x + rect.width / 2}
            y={rect.y - strokeWidth + rect.height / 2 + fontSize / 2}
            fill={stroke}
            fontSize={fontSize}
            style={{ display: rect.showDelete ? 'block' : 'none' }}
          ></text>

          {rect.showControls ? this.renderRectControls(rect, rectId) : ''}
        </g>
      )
    }
    return ''
  }

  render() {
    const {
      imageUrl,
      label,
      containerClass,
      zoomRatioPercentage,
      isFullscreen,
    } = this.props

    const { rects } = this.state

    const rectsElements: React.ReactNode[] = []

    Object.keys(rects).forEach((id) => {
      if (rects[id].fieldId !== label) {
        rectsElements.push(this.renderRect(rects[id], id, id))
      }
    })
    Object.keys(rects).forEach((id) => {
      if (rects[id].fieldId === label && !rects[id].showControls) {
        rectsElements.push(this.renderRect(rects[id], id, id))
      }
    })
    Object.keys(rects).forEach((id) => {
      if (rects[id].fieldId === label && rects[id].showControls) {
        rectsElements.push(this.renderRect(rects[id], id, id))
      }
    })

    const outerSize = {
      width: document.body.offsetWidth,
      height: document.body.offsetHeight,
    }

    return (
      <div className={style.coreMiddle} ref={this.props.coreMiddleRef}>
        {isFullscreen && (
          <div className={style.closeFullscreen}>
            <Button
              form="circle"
              onClick={() => {
                this.props.handleButtonToggleFullscreen(
                  1,
                  this.setResolution.bind(this),
                )
              }}
            >
              <FullscreenCloseIcon />
            </Button>
          </div>
        )}

        {isFullscreen && (
          <div className={style.coreZoom}>
            <div className={style.magnifyButtons}>
              <MagnifyButtons
                percent={Math.trunc(zoomRatioPercentage * 100)}
                onMinus={() => {
                  this.handleZoomButtonClick(
                    'down',
                    this.setResolution.bind(this),
                  )
                }}
                onPlus={() =>
                  this.handleZoomButtonClick(
                    'up',
                    this.setResolution.bind(this),
                  )
                }
              />
            </div>
          </div>
        )}

        <div className={style.coreInner}>
          <Scroller
            disabled={!this.props.isFullscreen}
            outerSize={outerSize}
            innerSize={this.props.innerFullscreenSize}
            fullscreenOffsetLeft={this.props.fullscreenOffsetLeft}
            fullscreenOffsetTop={this.props.fullscreenOffsetTop}
            scrollHeight={8}
            className={style.coreScroller}
          >
            <div
              className={containerClass}
              ref={this.props.coreref}
              style={this.props.imageFullscreenSize}
            >
              <img
                src={imageUrl}
                alt=""
                ref={this.props.imageRef}
                className={style.imageValue}
              />
              <svg
                className={style.svgArea}
                onMouseDown={this.resizeRect.bind(this, null)}
                onMouseUp={this.handleSvgAreaMouseup.bind(this)}
              >
                {rectsElements}
              </svg>

              {!isFullscreen && (
                <div className={style.svgButtonFullscreen}>
                  <span
                    tabIndex={0}
                    role="button"
                    onClick={() => {
                      this.props.handleButtonToggleFullscreen(
                        1,
                        this.setResolution.bind(this),
                      )
                    }}
                  >
                    <FullscreenIcon />
                  </span>
                </div>
              )}
            </div>
          </Scroller>
        </div>
      </div>
    )
  }
}
