import { useState } from 'react'
import { IImage, ID, RectPayload } from 'types/common'
import style from './Annotate.module.css'
import Heading from '../Heading/Heading'
import ImagesGrid from 'components/ImagesGrid/ImagesGrid'
import Container from 'components/Container/Container'
import SegmentationScreen from 'screens/SegmentationScreen/SegmentationScreen'
import { ANNOTATE_IMAGES_PER_PAGE } from 'screens/AnnotateScreen/AnnotateScreen'
import Modal from '@mui/material/Modal'

interface Props {
  images: IImage[]
  total: number | null
  documentId: ID
  onPageChange: (page: number) => void
  onImage: (image: IImage) => void
  onSubmit: (rects: RectPayload[]) => void
}

export default function Annotate({
  images,
  documentId,
  total,
  onSubmit,
  onPageChange,
  onImage,
}: Props) {
  // const dispatch = useSegmentationDispatch()

  // const [isSegmentation, setSegmentation] = useState(false)
  const [annotationImage, setAnnotationImage] = useState<IImage | null>(null)

  const onImageClick = (image: IImage) => {
    // dispatch({ type: 'setSegmentation', payload: { image, documentId } })
    setAnnotationImage(image)
    onImage(image)
    // history.push('/markup')
  }

  const handleSubmit = (rects: RectPayload[]) => {
    setAnnotationImage(null)
    onSubmit(rects)
  }

  const totalPages = total ? Math.ceil(total / ANNOTATE_IMAGES_PER_PAGE) : null
  return (
    <div className={style.root}>
      <Container>
        <Heading className={style.heading} back>
          Выберите эталон
        </Heading>
        <ImagesGrid
          images={images}
          total={totalPages}
          onClick={onImageClick}
          onPageChange={onPageChange}
        />
        <Modal open={Boolean(annotationImage)} closeAfterTransition>
          <SegmentationScreen
            onClose={() => setAnnotationImage(null)}
            onSubmit={handleSubmit}
            image={annotationImage as IImage}
          />
        </Modal>
      </Container>
    </div>
  )
}
