import { useEffect, useState } from "react";
import style from "./SingleProjectScreen.module.css";
import Layout from "components/Layout/Layout";
import SingleProject from "../../components/SingleProject/SingleProject";
import { ProjectApi } from "api/projectApi";
import {
  useParams,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  IBuild,
  ID,
  IDemoStand,
  IntervalType,
  IProject,
  StandCheckType,
  TOCR,
} from "types/common";
import guid from "guid";
import { message } from "utils/toast";
import { DocumentApi } from "api/documentApi";
import { transformToSlug } from "utils/utils";
import fileDownload from "js-file-download";
import { BuildApi } from "api/buildApi";
import { StandApi } from "api/standApi";

interface Props {}

export interface ILoading {
  [key: string]: boolean;
}

export default function SingleProjectScreen({}: Props) {
  let { id } = useParams<{ id?: ID }>();

  const [project, setProject] = useState<IProject | null>(null);
  const [commonDocuments, setCommonDocuments] = useState<string[] | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isBuildLoading, setBuildLoading] = useState<boolean>(false);
  const [standsLoading, setStandsLoading] = useState<ILoading>({});
  const [isRemoveBuildLoading, setRemoveBuildLoading] =
    useState<boolean>(false);
  const [isRemoveDocumentLoading, setRemoveDocumentLoading] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const loc = useLocation();

  useEffect(() => {
    const getData = async () => {
      if (id === undefined || id === null) return;

      const res1 = await ProjectApi.get(id);

      if (res1.status === 200) {
        if (res1.data && res1.data.payload && res1.data.payload.length > 0) {
          setProject(res1.data.payload[0]);
        } else {
          throw new Error("Problem with project data");
        }
      } else {
        message.error(`Ошибка при получении проекта. Код: ${res1.status}`);
      }

      // if (res2.status === 200) {
      //   if (res2.data && res2.data.length > 0) {
      //     setCommonDocuments(res2.data);
      //   } else {
      //     throw new Error("Problem with common documents data");
      //   }
      // } else {
      //   message.error(
      //     `Ошибка при получении списка общих документов. Код: ${res2.status}`
      //   );
      // }
      setLoading(false);
    };
    getData();
  }, []);

  if (id === undefined || id === null) {
    return <Navigate to="/" />;
  }

  const onNameChange = async (newName: string) => {
    const defaultSlug = transformToSlug(newName);

    try {
      const res = await ProjectApi.edit({
        id,
        name: newName,
        slug: defaultSlug,
      });
      if (res.status === 200) {
        if (res.data.success) {
          setProject({
            ...(project as IProject),
            name: newName,
            slug: defaultSlug,
          });

          navigate(loc.pathname, {
            replace: true,
          });

          message.success("Проект сохранен");
        }
      } else {
        message.error(`Ошибка при сохранении проекта. Код: ${res.status}`);
      }
    } catch (error) {
      message.error("Ошибка при сохранении проекта");
    }
  };

  const onChangeBuildName = async (buildName: string, buildId: string) => {
    if (!id) return;
    if (!project) return;

    try {
      const res = await BuildApi.edit({
        projectID: id,
        buildId,
        buildName,
      });

      if (res.status === 200) {
        if (res.data.success) {
          const currentBuildIndex = project.builds.findIndex(
            (b) => b.id === buildId
          );
          project.builds[currentBuildIndex].name = buildName;

          setProject({ ...project });

          message.success("Имя сборки сохранено");
        }
      } else {
        message.error(`Ошибка при сохранении имени сборки. Код: ${res.status}`);
      }
    } catch (error) {
      message.error("Ошибка при сохранении имени сборки");
    }
  };

  const onBuild = async ({
    documents,
    ocr,
    name,
  }: {
    documents: { label: string; value: string }[];
    ocr: TOCR;
    name: string;
  }) => {
    if (!id) return;

    setBuildLoading(true);
    try {
      const res = await BuildApi.create({
        id,
        selected_documents_ids: documents
          .map((d) => d.value)
          .filter((d) => !commonDocuments?.includes(d)),
        dbrain_docs: documents
          .map((d) => d.value)
          .filter((d) => commonDocuments?.includes(d)),
        ocr,
        name,
      });
      if (res.status === 200) {
        if (res.data.success) {
          message.success("Сборка образа начата");
        }
        // TODO: when ready get new classifier from 200 response
        project &&
          setProject({
            ...project,
            builds: [res.data.payload, ...project.builds],
          });
      } else {
        message.error(`Ошибка при старте сборки. Код: ${res.status}`);
      }
    } catch (error) {
      message.error("Ошибка при старте сборки");
    }
    setBuildLoading(false);
  };

  const onGetBuild = async (build: IBuild) => {
    if (!id) return;

    try {
      const res = await BuildApi.get({
        id,
        build,
      });

      fileDownload(res.data, `${build.id}.zip`);
      if (res.status === 200) {
        if (res.data.success) {
          message.success("Сборка образа начата");
        }
      } else {
        message.error(`Ошибка при старте сборки. Код: ${res.status}`);
      }
    } catch (error) {
      message.error("Ошибка при старте сборки");
    }
  };

  const onRemoveBuild = async (buildId: string) => {
    if (!id) return;
    if (!project) return;
    setRemoveBuildLoading(true);
    try {
      const res = await BuildApi.remove({
        projectId: id,
        buildId,
      });

      if (res.status === 200) {
        message.success("Сборка успешно удалена");
        setProject({
          ...project,
          builds: project.builds.filter((b) => b.id !== buildId),
        });
      } else {
        message.error(`Ошибка при удалении сборки. Код: ${res.status}`);
      }
    } catch (error) {
      message.error("Ошибка при удалении сборки");
    }
    setRemoveBuildLoading(false);
  };

  const onRemoveDocument = async (documentId: string) => {
    if (!project) return;
    setRemoveDocumentLoading(true);
    try {
      const res = await DocumentApi.delete(documentId);

      if (res.status === 200) {
        message.success("Документ успешно удален");
        setProject({
          ...project,
          documents: project.documents.filter((d) => d.id !== documentId),
        });
      } else {
        message.error(`Ошибка при удалении документа. Код: ${res.status}`);
      }
    } catch (error) {
      message.error("Ошибка при удалении документа");
    }
    setRemoveDocumentLoading(false);
  };

  const onAddDocument = async () => {
    const defaultName = "Новый документ";
    const defaultSlug = transformToSlug(defaultName) + "-" + guid.raw();

    try {
      const res = await DocumentApi.add({
        name: defaultName,
        slug: defaultSlug,
        project_id: id as ID,
      });

      if (res.status === 201) {
        if (res.data.payload) {
          if (res.data.payload.length === 0) {
            throw new Error("Payload is empty array");
          }
          navigate(`/documents/${res.data.payload[0].id}?new=true`);
        }
      }
    } catch (error) {
      message.error("Ошибка при создании проекта");
    }
  };

  const standCheck = async (
    id: ID,
    buildId: ID,
    intervalID: IntervalType,
    type: StandCheckType
  ) => {
    if (!project) return;

    try {
      const res = await StandApi.update(id);

      if (res.status === 200) {
        if (res.data.payload) {
          const payload = res.data.payload;

          setProject((prevState) => {
            return prevState
              ? {
                  ...project,
                  demos: prevState.demos.map((d) => {
                    if (d.id === id) {
                      return {
                        ...payload,
                      };
                    }
                    return d;
                  }),
                }
              : { ...project };
          });

          if (payload.action === "idle") {
            setStandsLoading((prevState) => ({
              ...prevState,
              [buildId]: false,
            }));
            clearInterval(intervalID);
          }

          if (payload.error) {
            message.error("Ошибка при проверке статуса стенда");
            return;
          }

          if (
            type === "run" &&
            payload.action === "idle" &&
            payload.is_online
          ) {
            message.success("Стенд запущен!");
            return;
          }

          if (
            type === "stop" &&
            payload.action === "idle" &&
            !payload.is_online
          ) {
            message.success("Стенд остановлен!");
            return;
          }
        }
      }
    } catch (error) {
      message.error("Ошибка при проверке статуса стенда");
    }
  };

  const onRunStand = async (b: IBuild) => {
    if (!project) throw new Error("project is null");
    setStandsLoading((prevState) => ({ ...prevState, [b.id]: true }));

    try {
      const res = await StandApi.run({
        project_id: project.id,
        build_id: b.id,
      });

      if (res.status === 200) {
        if (res.data.payload) {
          setProject({
            ...project,
            demos: project.demos.map((d) => {
              if (d.build_id === b.id) {
                return {
                  ...d,
                  error: res.data.payload.error,
                  action: res.data.payload.action,
                };
              }
              return d;
            }),
          });

          if (res.data.payload.error) {
            setStandsLoading((prevState) => ({
              ...prevState,
              [b.id]: false,
            }));
            message.error("Ошибка при запуске стенда");
            return;
          }

          message.success(
            "Запуск стенда успешно инициирован. Время до поднятия ≈ 1 мин."
          );
        }
      } else {
        message.error(`Ошибка при запуске стенда: ${res.status}`);
      }
    } catch (error) {
      message.error("Ошибка при запуске стенда");
    }
  };

  const onStopStand = async (b: IBuild) => {
    if (!project) {
      throw new Error("project is null");
    }
    setStandsLoading((prevState) => ({ ...prevState, [b.id]: true }));

    try {
      const res = await StandApi.stop({
        project_id: project.id,
        build_id: b.id,
      });

      if (res.status === 200) {
        if (res.data.payload) {
          setProject({
            ...project,
            demos: project.demos.map((d) => {
              if (d.build_id === b.id) {
                return {
                  ...d,
                  error: res.data.payload.error,
                  action: res.data.payload.action,
                };
              }
              return d;
            }),
          });

          if (res.data.payload.error) {
            setStandsLoading((prevState) => ({
              ...prevState,
              [b.id]: false,
            }));
            message.error("Ошибка при остановки стенда");
            return;
          }

          message.success(
            "Остановка стенда успешно инициирована. Время до остановки ≈ 1 мин."
          );
        }
      } else {
        message.error(`Ошибка при остановке стенда: ${res.status}`);
      }
    } catch (error) {
      message.error("Ошибка при остановке стенда");
    }
  };

  return (
    <Layout className={style.root} loading={isLoading}>
      {project && (
        <SingleProject
          standsLoading={standsLoading}
          setStandsLoading={setStandsLoading}
          isRemoveBuildLoading={isRemoveBuildLoading}
          isRemoveDocumentLoading={isRemoveDocumentLoading}
          commonDocuments={commonDocuments}
          onGetBuild={onGetBuild}
          project={project}
          isBuildLoading={isBuildLoading}
          onBack={() => navigate(-1)}
          onNameChange={onNameChange}
          onChangeBuildName={onChangeBuildName}
          onBuild={onBuild}
          onRemoveBuild={onRemoveBuild}
          onRemoveDocument={onRemoveDocument}
          onAddDocument={onAddDocument}
          onRunStand={onRunStand}
          standCheck={standCheck}
          onStopStand={onStopStand}
        />
      )}
    </Layout>
  );
}
