import { Button, ButtonTheme } from '@dbrainio/shared-ui'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  styled,
} from '@mui/material'
import React from 'react'
import style from './DialogModal.module.css'

const DialogStyled = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 16,
    padding: '32px 40px',
    backgroundColor: '#fafafa',
    fontFamily: 'inherit',
  },
}))

interface Props {
  open: boolean
  isConfirmLoading?: boolean
  title: React.ReactNode
  content: React.ReactNode
  cancelText?: string
  confirmText?: string
  onCancel: () => void
  onConfirm: () => void
  onClose: () => void
}
export default function DialogModal({
  open,
  isConfirmLoading,
  confirmText,
  cancelText,
  title,
  content,
  onCancel,
  onConfirm,
  onClose,
}: Props) {
  return (
    <DialogStyled open={open} onClose={onClose}>
      <div className={style.dialog}>
        <DialogTitle className={style.title}>{title}</DialogTitle>
        <DialogContent className={style.content}>
          <DialogContentText className={style.text}>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={style.dialogActions}>
          <Button onClick={onCancel} theme={ButtonTheme.BlueBordered}>
            {cancelText || 'Отмена'}
          </Button>
          <Button
            onClick={onConfirm}
            theme={ButtonTheme.Blue}
            isLoading={isConfirmLoading}
          >
            {confirmText || 'Продолжить'}
          </Button>
        </DialogActions>
      </div>
    </DialogStyled>
  )
}
