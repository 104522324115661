import * as React from 'react'
import { IImage, ID } from '../types/common'

type Action =
  | { type: 'setSegmentation'; payload: { image: IImage; documentId: ID } }
  | { type: 'clearSegmentation' }

type Dispatch = (action: Action) => void
type State = { image: IImage | null; documentId: ID | null }
type CountProviderProps = { children: React.ReactNode }

const SegmentationStateContext = React.createContext<State | undefined>(
  undefined,
)
const SegmentationDispatchContext = React.createContext<Dispatch | undefined>(
  undefined,
)

function segmentationReducer(state: State, action: Action) {
  switch (action.type) {
    case 'setSegmentation': {
      return { ...action.payload }
    }
    case 'clearSegmentation': {
      return { image: null, documentId: null }
    }
    default: {
      throw new Error(`Unhandled action type: ${(action as Action).type}`)
    }
  }
}

function SegmentationProvider({ children }: CountProviderProps) {
  const [state, dispatch] = React.useReducer(segmentationReducer, {
    image: null,
    documentId: null,
  })

  return (
    <SegmentationStateContext.Provider value={state}>
      <SegmentationDispatchContext.Provider value={dispatch}>
        {children}
      </SegmentationDispatchContext.Provider>
    </SegmentationStateContext.Provider>
  )
}

function useSegmentationState() {
  const context = React.useContext(SegmentationStateContext)
  if (context === undefined) {
    throw new Error('useCountState must be used within a CountProvider')
  }
  return context
}

function useSegmentationDispatch() {
  const context = React.useContext(SegmentationDispatchContext)
  if (context === undefined) {
    throw new Error('useCountDispatch must be used within a CountProvider')
  }
  return context
}

export { SegmentationProvider, useSegmentationState, useSegmentationDispatch }
