import style from './Heading.module.css'
import React from 'react'
import classnames from 'classnames'
import BackButton from 'components/BackButton/BackButton'
import { useNavigate } from 'react-router-dom'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  mini?: boolean
  sub?: boolean
  back?: boolean
}

export default function Heading(props: Props) {
  const { mini, sub, back, ...divProps } = props
  const navigate = useNavigate()

  return (
    <div>
      {back && (
        <BackButton className={style.back} onClick={() => navigate(-1)} />
      )}
      <div
        {...divProps}
        className={classnames(style.root, props.className, {
          [style.mini]: mini,
          [style.sub]: sub,
        })}
      ></div>
    </div>
  )
}
