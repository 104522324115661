import classnames from 'classnames'
import React from 'react'
import style from './ProgressBar.module.css'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  progress: number
  label: string
  small?: boolean
}

export default function ProgressBar({
  progress,
  label,
  small,
  className,
}: Props) {
  return (
    <div
      className={classnames(style.root, className, { [style.small]: small })}
    >
      <div
        className={style.progress}
        style={{ width: `${Math.floor(progress * 100)}%` }}
      ></div>
      <div className={style.label}>{label}</div>
    </div>
  )
}
