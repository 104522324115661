import style from "./SingleProject.module.css";
import {
  Button,
  ButtonTheme,
  TextInput,
  TextInputTheme,
} from "@dbrainio/shared-ui";
import DocumentCard from "components/DocumentCard/DocumentCard";
import {
  IBuild,
  ID,
  IntervalType,
  IOption,
  IProject,
  StandCheckType,
  TOCR,
} from "types/common";
import Container from "components/Container/Container";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BackButton from "components/BackButton/BackButton";
import Select from "react-select";
import Card from "components/Card/Card";
import Grid from "@mui/material/Grid";
import DbrainSelect from "components/DbrainSelect/DbrainSelect";
import Confirm from "components/Confirm/Confirm";
import BuildCard from "components/BuildCard/BuildCard";
import { Mapper } from "utils/mapper";
import { ILoading } from "../../screens/SingleProjectScreen/SingleProjectScreen";

interface PlusMiniProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {}

const PlusMini = (props: PlusMiniProps) => (
  <div
    {...props}
    style={{
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    }}
  >
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 0H11V11H0V15H11V26H15V15H26V11H15V0Z" fill="#1356A8" />
    </svg>
  </div>
);

const OCROptions: IOption<TOCR>[] = [
  {
    value: "dbrain",
    label: "RU OCR",
  },
  {
    value: "google",
    label: "Global OCR #1",
  },
  {
    value: "yandex",
    label: "Global OCR #2",
  },
];

interface Props {
  project: IProject;
  commonDocuments: string[] | null;
  isBuildLoading: boolean;
  standsLoading: ILoading;
  setStandsLoading: Dispatch<SetStateAction<ILoading>>;
  isRemoveBuildLoading: boolean;
  isRemoveDocumentLoading: boolean;
  onNameChange: (newName: string) => void;
  onAddDocument: () => void;
  onChangeBuildName: (buildName: string, buildId: string) => void;
  onBuild: (payload: {
    documents: { label: string; value: string }[];
    ocr: TOCR;
    name: string;
  }) => void;
  onGetBuild: (documents: IBuild) => void;
  onRemoveBuild: (id: string) => void;
  onRemoveDocument: (id: string) => void;
  onBack: () => void;
  onRunStand: (b: IBuild) => void;
  onStopStand: (b: IBuild) => void;
  standCheck: (
    id: ID,
    buildId: ID,
    intervalID: IntervalType,
    type: StandCheckType
  ) => void;
}

const CustomSelect = ({
  defaultValue,
  options,
  onChange,
}: {
  defaultValue: { value: string; label: string };
  options: {
    label: string;
    options: {
      value: string;
      label: string;
    }[];
  }[];
  onChange: (newVal: { value: string; label: string }) => void;
}) => {
  const [value, setValue] = useState(defaultValue);
  return (
    <DbrainSelect
      defaultValue={defaultValue}
      onChange={(val: unknown) => {
        if (!val) return;

        setValue(val as { value: string; label: string });
        onChange(val as { value: string; label: string });
      }}
      options={options}
    />
  );
};

export interface ISelectedDocument {
  label: string;
  value: string;
}

export default function SingleProject({
  project,
  isBuildLoading,
  commonDocuments,
  isRemoveBuildLoading,
  isRemoveDocumentLoading,
  standsLoading,
  setStandsLoading,
  onRemoveDocument,
  onChangeBuildName,
  onNameChange,
  onBack,
  onAddDocument,
  onRemoveBuild,
  onGetBuild,
  onBuild,
  onRunStand,
  onStopStand,
  standCheck,
}: Props) {
  const onAdd = () => onAddDocument();
  const location = useLocation();
  const isNew = !!new URLSearchParams(location.search).get("new");

  const [name, setName] = useState(isNew ? "" : project.name);
  const [buildToDelete, setBuildToDelete] = useState<string | null>(null);
  const [documentToDelete, setDocumentToDelete] = useState<string | null>(null);
  const [buildsNames, setBuildsNames] = useState<{ [key: string]: string }>(
    project.builds.reduce((acc, build) => {
      acc[build.id] = build.name || build.id;
      return acc;
    }, {} as { [key: string]: string })
  );

  const textinputRef = useRef<HTMLInputElement | null>(null);

  const [selectedDocuments, setSelectedDocuments] = useState<
    ISelectedDocument[]
  >([]);

  const [ocr, setOcr] = useState<IOption<TOCR>>(OCROptions[0]);
  const [buildName, setBuildName] = useState<string>("");

  useEffect(() => {
    setSelectedDocuments([]);
    if (buildToDelete) setBuildToDelete(null);
    if (documentToDelete) setDocumentToDelete(null);
  }, [project]);

  const navigate = useNavigate();

  useEffect(() => {
    if (isNew) textinputRef?.current?.focus();
    return () => {};
  }, []);

  const onAddBuild = () => {
    selectedDocuments.length === 0 &&
      setSelectedDocuments([options[0], ...selectedDocuments]);
  };

  const handleRemoveBuild = () => {
    if (buildToDelete) {
      const { [buildToDelete]: _, ...rest } = buildsNames;
      setBuildsNames(rest);
      buildToDelete && onRemoveBuild(buildToDelete);
    }
  };

  const handleRemoveDocument = () => {
    documentToDelete && onRemoveDocument(documentToDelete);
  };

  const handleBuild = () => {
    const defaultName = selectedDocuments.map((d) => d.label).join(", ");
    onBuild({
      documents: selectedDocuments,
      ocr: ocr.value,
      name: buildName !== "" ? buildName : defaultName,
    });
  };

  const getBuild = (b: IBuild) => {
    onGetBuild(b);
  };

  const onBlur = () => {
    if (name.length === 0) {
      setName(project.name);
      return;
    }

    if (name !== project.name) {
      onNameChange(name);
    }

    removeNewFromQuery();
  };

  const removeNewFromQuery = () => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has("new")) {
      queryParams.delete("new");
      navigate(
        {
          search: queryParams.toString(),
        },
        {
          replace: true,
        }
      );
    }
  };

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    textinputRef?.current?.blur();
  };

  const options = project.documents
    .filter((d) => d.stages.training.done) // if final step is done
    .filter((d) => !d.fieldnets.some((f) => f.status === "failed")) // if there are no failed fieldnets
    .map((d, key) => ({
      value: d.id,
      label: d.name,
    }));

  const docrOptions = (commonDocuments || []).map((d, key) => ({
    value: d,
    label: Mapper.mapDocType(d),
  }));

  const groupedOptions = [
    {
      label: "",
      options: options,
    },
    {
      label: "",
      options: docrOptions,
    },
  ];

  const areAnyTrainedDocuments = project.documents.some(
    (d) => d.stages.training.done
  );

  const renderCreateBuild = () => {
    return (
      selectedDocuments.length > 0 && (
        <div className={style.createBuild}>
          <TextInput
            inputRef={textinputRef}
            placeholder="Название сборки"
            className={style.buildNameInput}
            theme={TextInputTheme.Transparent}
            inputSize="standard"
            autoFocus
            value={buildName}
            onChange={(e) => setBuildName(e.currentTarget.value.slice(0, 300))}
            onBlur={onBlur}
          />
          <div className={style.buildDocumentsList}>
            {selectedDocuments.map((d, idx) => (
              <div className={style.buildDocumentsListRow} key={idx}>
                <CustomSelect
                  defaultValue={options[0]}
                  onChange={(option: { value: string; label: string }) => {
                    setSelectedDocuments(
                      selectedDocuments.map((s, id) =>
                        id === idx ? option : s
                      )
                    );
                  }}
                  options={groupedOptions}
                />
                <div
                  className={style.deleteDocument}
                  onClick={() =>
                    setSelectedDocuments(
                      selectedDocuments.filter(
                        (document, index) => index !== idx
                      )
                    )
                  }
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2 14L14 2" stroke="#AAAAAA" strokeWidth="2" />
                    <path d="M14 14L2 2" stroke="#AAAAAA" strokeWidth="2" />
                  </svg>
                </div>
              </div>
            ))}
          </div>
          {options.length > 0 && (
            <div
              className={style.plusButton}
              onClick={() => {
                setSelectedDocuments([...selectedDocuments, options[0]]);
              }}
            >
              <svg
                width="26"
                height="27"
                viewBox="0 0 26 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 0H11V11.0803H0V15.1095H11V26.1898H15V15.1095H26V11.0803H15V0Z"
                  fill="#AAAAAA"
                />
              </svg>
            </div>
          )}
          <div className={style.ocr}>
            <span>OCR</span>
            <Select
              value={ocr}
              options={OCROptions}
              onChange={(val) => {
                if (!val) return;
                setOcr(val);
              }}
            />
          </div>
          <Button
            theme={ButtonTheme.Blue}
            onClick={handleBuild}
            isLoading={isBuildLoading}
            className={style.createBuildButton}
          >
            Собрать
          </Button>
        </div>
      )
    );
  };

  return (
    <Container className={style.root}>
      <Confirm
        title="Вы уверены, что хотите удалить сборку?"
        description="Удаленная сборка не может быть восстановлена"
        isLoading={isRemoveBuildLoading}
        confirmText="Удалить"
        onCancel={() => setBuildToDelete(null)}
        onConfirm={handleRemoveBuild}
        open={!!buildToDelete}
      />
      <Confirm
        title="Вы уверены, что хотите удалить документ?"
        description="Документ не может быть восстановлен"
        isLoading={isRemoveDocumentLoading}
        confirmText="Удалить"
        onCancel={() => setDocumentToDelete(null)}
        onConfirm={handleRemoveDocument}
        open={!!documentToDelete}
      />
      <Grid justifyContent="center" container>
        <Grid xs={8} item>
          <BackButton className={style.back} onClick={onBack} />
          <form onSubmit={onSubmit}>
            <TextInput
              inputRef={textinputRef}
              placeholder="Название проекта"
              className={style.input}
              theme={TextInputTheme.Transparent}
              inputSize="large"
              value={name}
              onChange={(e) => setName(e.currentTarget.value.slice(0, 300))}
              onBlur={onBlur}
            />
          </form>
          <div className={style.gridHeading}>
            <div className={style.cell}>
              <div>Документы</div>
              {project.documents.length > 0 && <PlusMini onClick={onAdd} />}
            </div>
            <div className={style.cell}>
              <div>Сборки</div>
              {areAnyTrainedDocuments && <PlusMini onClick={onAddBuild} />}
            </div>
          </div>
          <div className={style.grid}>
            <div className={style.documents}>
              {project.documents.length ? (
                project.documents.map((d, key) => (
                  <DocumentCard
                    document={d}
                    className={style.card}
                    key={key}
                    onDelete={() => setDocumentToDelete(d.id)}
                  />
                ))
              ) : (
                <Card
                  className={style.addDocument}
                  background="blue"
                  onClick={onAdd}
                >
                  <svg
                    width="72"
                    height="72"
                    viewBox="0 0 72 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M40.0083 0H31.9917V31.9917H0V40.0083H31.9917V72H40.0083V40.0083H72V31.9917H40.0083V0Z"
                      fill="#FAFAFA"
                    />
                  </svg>
                </Card>
              )}
            </div>
            <div className={style.builds}>
              {renderCreateBuild()}
              {!areAnyTrainedDocuments && (
                <Card
                  theme="disabled"
                  notClickable
                  className={style.addDocument}
                >
                  Для создания сборки нужен как минимум один обученный документ
                </Card>
              )}
              {project.builds
                ?.sort((a: IBuild, b: IBuild) =>
                  a.created_at < b.created_at ? 1 : -1
                )
                .map((b, i) => {
                  if (!(b.id in buildsNames)) {
                    setBuildsNames((prev) => ({
                      ...prev,
                      [b.id]: b.name || b.id,
                    }));
                  }
                  return (
                    <BuildCard
                      standsLoading={standsLoading}
                      setStandsLoading={setStandsLoading}
                      key={i}
                      demos={project.demos}
                      onChangeBuildName={onChangeBuildName}
                      onDelete={(id) => setBuildToDelete(id)}
                      documents={project.documents}
                      b={b}
                      onGetBuild={getBuild}
                      onRun={onRunStand}
                      onStop={onStopStand}
                      standCheck={standCheck}
                      selectedDocuments={selectedDocuments}
                      buildsNames={buildsNames}
                      setBuildsNames={setBuildsNames}
                    />
                  );
                })}
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
