import { axiosInstance } from 'api'
import { ID } from '../types/common'

export interface RunResponse {
  success: boolean
  error: any | null
}

export interface AddMarkupResponse {
  payload: any | null
  success: boolean
  error: any | null
}

export interface AddMarkupPayload {
  tag: string
  example_image_id: string
  document_id: string
  fields: {
    name: string
    slug: string
    bboxes: number[][][]
  }[]
}

export interface GetFieldPayload {
  markup_id: ID
  image_id: ID
  field_slug: string
}

export class MarkupApi {
  static async run(id: ID) {
    return axiosInstance.post<RunResponse>(`/markup/run`, {
      id,
    })
  }
  static async getGt(id: ID) {
    return axiosInstance.get<Blob>(`/markup/${id}/gt_results`, {
      responseType: 'blob',
    })
  }
  static async add(payload: AddMarkupPayload) {
    return axiosInstance.post<AddMarkupResponse>(`/markup`, {
      ...payload,
    })
  }
  static async getFieldCrop(payload: GetFieldPayload) {
    return axiosInstance.post<AddMarkupResponse>(`/markup`, {
      ...payload,
    })
  }
  // static async delete(id: ID) {
  //   return axios.delete<DeleteProjectResponse>(`/project/${id}`)
  // }
  // static async add(payload: AddProjectPayload) {
  //   return axios.post<AddProjectResponse>(`/project`, payload)
  // }
  // static async edit({ id, ...payload }: EditProjectPayload) {
  //   return axios.put<EditProjectResponse>(`/project/${id}`, payload)
  // }
}
