import classnames from 'classnames'
import React from 'react'
import style from './PlusButton.module.css'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {}

export default function PlusButton(props: Props) {
  return (
    <div {...props} className={classnames(style.root, props.className)}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M67 0H53V53H0V67H53V120H67V67H120V53H67V0Z" fill="#AAAAAA" />
      </svg>
    </div>
  )
}
