import React, { Component } from 'react'
import Core from './Core/AabbCore'
import style from './Aabb.module.css'
import classNames from 'classnames'
import { IRect, IDimensions, RectPayload } from 'types/common'
import MarkupSidebar from 'components/MarkupSidebar/MarkupSidebar'

import { transformToSlug } from 'utils/utils'

const getStyles = () => {
  const strokeOpacity = 1
  const fillOpacity = 0.25

  return [
    '255, 48, 63', //   #FF303F
    '19, 158, 239', //  #139EEF
    '249, 144, 24', //  #F99018
    '18, 237, 186', //  #12EDBA
    '255, 68, 192', //  #FF44C0
    '33, 47, 255', //   #212FFF
    '252, 204, 32', //  #FCCC20
    '188, 25, 252', //  #BC19FC
    '20, 232, 20', //   #14E814
    '224, 74, 2', //    #E04A02
    '2, 197, 224', //   #02C5E0
    '251, 171, 132', // #FBAB84
    '90, 19 ,91', //    #5A135B
    '182, 0 ,13', //    #B6000D
    '153, 194 ,36', //  #99C224
  ].map((color) => ({
    stroke: `rgba(${color}, ${strokeOpacity})`,
    fill: `rgba(${color}, ${fillOpacity})`,
    strokeWidth: 2,
    fontSize: 16,
  }))
}

const fix = (x: number) => Math.min(Math.max(0, x), 1)
const maxInnerFullscreenSize = 90000

export interface IRectStyle {
  stroke: string
  fill: string
  strokeWidth: number
  fontSize: number
}
export interface AabbProps {
  onSubmit: (rects: RectPayload[]) => void
  imageUrl: string
}

export interface IRects {
  [key: string]: IRect
}

export interface AabbField {
  id: string
}

export interface AabbState {
  currentFieldId: string | null
  fields: { [key: string]: string }
  fullscreenOffsetLeft: number
  fullscreenOffsetTop: number
  isFullscreen: boolean
  isMouseDown: boolean
  imageFullscreenSize: IDimensions
  innerFullscreenSize: IDimensions
  rects: IRects
  zoomRatioPercentage: number
  styles: IRectStyle[]
  isCreating: boolean
  currentStyle: IRectStyle
}

class Aabb extends Component<AabbProps, AabbState> {
  imageRef: React.RefObject<HTMLImageElement>
  coreMiddleRef: React.RefObject<HTMLDivElement>

  constructor(props: AabbProps) {
    super(props)
    const styles = getStyles()
    const currentStyle = styles[0]
    this.state = {
      rects: {},
      fields: {},
      styles,
      currentStyle,
      currentFieldId: null,
      isFullscreen: false,
      isCreating: false,
      isMouseDown: false,
      innerFullscreenSize: {
        width: maxInnerFullscreenSize,
        height: maxInnerFullscreenSize,
      },
      imageFullscreenSize: { width: 'auto', height: 'auto' },
      fullscreenOffsetLeft: 0,
      fullscreenOffsetTop: 0,
      zoomRatioPercentage: 1,
    }

    this.imageRef = React.createRef()
    this.coreMiddleRef = React.createRef()
  }

  setRects(rects: IRects) {
    this.setState({ rects: rects || {}, currentFieldId: null })
  }

  onSubmit = () => {
    const { rects, fields } = this.state

    // Object.keys(rects).forEach((key: string) => {
    //   const r = rects[key] as IRect
    //   console.log('🚀 ~ file: Aabb.tsx ~ line 115 ~ Aabb ~ Object.keys ~ r', r)
    //   const x0 = fix(r.rx)
    //   const y0 = fix(r.ry)
    //   const x1 = fix(r.rx + r.rwidth)
    //   const y1 = fix(r.ry + r.rheight)
    //   const coordinates = [
    //     [x0, y0],
    //     [x1, y0],
    //     [x1, y1],
    //     [x0, y1],
    //     [x0, y0],
    //   ]
    // })

    const payload: RectPayload[] = Object.keys(rects).map((k) => {
      const r = rects[k]
      const f = fields[k]
      const x0 = fix(r.rx)
      const y0 = fix(r.ry)
      const x1 = fix(r.rx + r.rwidth)
      const y1 = fix(r.ry + r.rheight)
      const bboxes = [
        [
          [x0, y0],
          [x1, y0],
          [x1, y1],
          [x0, y1],
          [x0, y0],
        ],
      ]

      return {
        name: f,
        slug: transformToSlug(f),
        bboxes,
      }
    })

    this.props.onSubmit(payload)
  }

  handlePathsUpdate(rects: IRects) {
    const currentStyle = this.state.styles
      .filter((s) => {
        return Object.values(rects).every((r) => r.style.fill !== s.fill)
      })
      .shift()

    if (!currentStyle) return

    this.setState({ currentStyle, rects })
  }

  mouseDownHandler(isMouseDown: boolean) {
    if (isMouseDown !== this.state.isMouseDown) {
      this.setState({ isMouseDown })
    }
  }

  handleZoomChange(ratio: number, callback?: () => void) {
    const innerFullscreenSize: IDimensions = {
      width: 'auto',
      height: 'auto',
    }
    const imageFullscreenSize: IDimensions = {
      width: 'auto',
      height: 'auto',
    }

    let fullscreenOffsetLeft = 0
    let fullscreenOffsetTop = 0

    if (this.state.isFullscreen) {
      const { offsetWidth, offsetHeight } = document.body
      const { current } = this.imageRef

      if (!current) {
        throw new Error('imageRef is null')
      }

      const currentWidth = (current.naturalWidth || current.offsetWidth) * ratio
      const currentHeight =
        (current.naturalHeight || current.offsetHeight) * ratio

      innerFullscreenSize.width = offsetWidth + currentWidth
      innerFullscreenSize.height = offsetHeight + currentHeight
      fullscreenOffsetLeft = currentWidth / 2
      fullscreenOffsetTop = currentHeight / 2
      imageFullscreenSize.width = currentWidth
      imageFullscreenSize.height = currentHeight
    } else {
      innerFullscreenSize.width = maxInnerFullscreenSize
      innerFullscreenSize.height = maxInnerFullscreenSize
    }

    this.setState(
      {
        innerFullscreenSize,
        imageFullscreenSize,
      },
      () => {
        if (callback) callback()

        this.setState({
          fullscreenOffsetLeft,
          fullscreenOffsetTop,
        })
      },
    )
  }

  handleButtonToggleFullscreen(
    zoomRatioPercentage: number,
    fullscreenCallback: () => void,
  ) {
    this.setState(
      (prev) => ({
        isFullscreen: !prev.isFullscreen,
      }),
      () => {
        this.handleZoomChange(zoomRatioPercentage, fullscreenCallback)
      },
    )
  }

  changeZoomRatio(zoomRatioPercentage: number, callback?: () => void) {
    this.setState({ zoomRatioPercentage }, () => {
      this.handleZoomChange(zoomRatioPercentage, callback)
    })
  }

  onDelete = (id: string) => {
    const rects = { ...this.state.rects }
    const fields = { ...this.state.fields }

    delete rects[id]
    delete fields[id]
    this.setState({ rects, fields })
  }

  render() {
    const {
      rects,
      currentFieldId,
      isCreating,
      isFullscreen,
      fields,
      currentStyle,
    } = this.state

    const core = (
      <div
        className={classNames(style.coreWrapper, {
          [style.coreWrapperFullscreen]: isFullscreen,
        })}
      >
        <Core
          onStartNew={() => this.setState({ isCreating: true })}
          onEndNew={() => this.setState({ isCreating: false })}
          containerClass={classNames({
            [style.svgContainer]: !isFullscreen,
            [style.svgContainerFullscreen]: isFullscreen,
          })}
          imageUrl={this.props.imageUrl}
          imageRef={this.imageRef}
          coreMiddleRef={this.coreMiddleRef}
          minBoxSide={16}
          onPathsUpdate={this.handlePathsUpdate.bind(this)}
          label={currentFieldId}
          rectStyle={currentStyle}
          rects={rects}
          isFullscreen={isFullscreen}
          innerFullscreenSize={this.state.innerFullscreenSize}
          imageFullscreenSize={this.state.imageFullscreenSize}
          mouseDownHandler={this.mouseDownHandler.bind(this)}
          handleButtonToggleFullscreen={this.handleButtonToggleFullscreen.bind(
            this,
          )}
          fullscreenOffsetLeft={this.state.fullscreenOffsetLeft}
          fullscreenOffsetTop={this.state.fullscreenOffsetTop}
          zoomRatioPercentage={this.state.zoomRatioPercentage}
          changeZoomRatio={this.changeZoomRatio.bind(this)}
        />
      </div>
    )

    return (
      <div className={classNames(style.root)}>
        <div className={style.imageWrapper}>
          <div className={style.image}>{core}</div>
        </div>
        <div
          className={classNames(style.sidebar, {
            [style.sidebarFullscreen]: isFullscreen,
          })}
        >
          <MarkupSidebar
            currentActive={currentFieldId}
            fields={fields}
            isCreating={isCreating}
            onDelete={this.onDelete}
            rects={rects}
            isFullscreen={this.state.isFullscreen}
            newStyle={currentStyle}
            onActivate={(id: string) => {
              this.setState({
                currentFieldId: id,
              })
            }}
            onFieldChange={(key: string, value: string) =>
              this.setState({
                fields: { ...this.state.fields, [key]: value },
              })
            }
            onSubmit={this.onSubmit}
          />
        </div>
      </div>
    )
  }
}

export default Aabb
