import style from './LoginScreen.module.css'
import { LoginForm } from 'components/LoginForm/LoginForm'
import DbrainStudioLogo from '../../components/DbrainStudioLogo/DbrainStudioLogo'

interface Props {}

export default function LoginScreen({}: Props) {
  return (
    <div className={style.root}>
      <div className={style.logo}>
        <DbrainStudioLogo light />
      </div>
      <LoginForm />
    </div>
  )
}
