import { AxiosError } from 'axios'
import { AppError } from 'store/types'

export const transformHttpErrorToAppError = (e: AxiosError) => {
  const { response } = e
  if (response?.status === 403) {
    return AppError.Forbidden
  }

  if (response?.status === 404) return AppError.IncorrectToken

  if (response?.status === 500) {
    return AppError.InternalServerError
  }

  if (response?.status === 503) {
    return AppError.ServiceUnavailable
  }

  return AppError.Other
}

function* AppSaga() {}

export default AppSaga
