import {
  SET_APP_ERROR,
  CLEAR_APP_ERROR,
  GET_DOCUMENTS,
  START_ANNOTATE,
  COMPLETE_ANNOTATE,
} from './actions/appActions'
import { ID, IDocument } from '../types/common'
import {
  START_SET_HEURISTICS,
  START_DELETE_DOCUMENT,
} from './actions/appActions'
import { AnnotatePayload } from '../api/annotationApi'
import { SET_DOCUMENTS } from './actions/appActions'
import { GetDocumentsPayload } from 'api/documentApi'
import { LoginRes } from 'api/authApi'

export enum AppError {
  IncorrectToken = 'IncorrectToken',
  Forbidden = 'Forbidden',
  InternalServerError = 'InternalServerError',
  ServiceUnavailable = 'ServiceUnavailable',
  Other = 'Other',
}

export enum RequestError {
  Forbidden = 'Forbidden',
  InternalServerError = 'InternalServerError',
  ServiceUnavailable = 'ServiceUnavailable',
  NullResponse = 'NullResponse',
  Other = 'Other',
}

export interface AppState {
  auth: LoginRes | null
  isAppLoading: boolean
  documents: IDocument[]
  appError: string | null
}

export interface SetAppErrorAction {
  type: typeof SET_APP_ERROR
  payload: string
}

export interface GetDocumentsAction {
  type: typeof GET_DOCUMENTS
  payload: GetDocumentsPayload
}

export interface SetDocumentsAction {
  type: typeof SET_DOCUMENTS
  payload: IDocument[]
}

export interface AnnotateAction {
  type: typeof START_ANNOTATE
  payload: AnnotatePayload
}

export interface CompleteAnnotateAction {
  type: typeof COMPLETE_ANNOTATE
}

export interface DeleteDocumentAction {
  type: typeof START_DELETE_DOCUMENT
  payload: ID
}

export interface ClearAppErrorAction {
  type: typeof CLEAR_APP_ERROR
}

export type ActionTypes =
  | SetAppErrorAction
  | ClearAppErrorAction
  | GetDocumentsAction
  | AnnotateAction
  | DeleteDocumentAction
  | SetDocumentsAction
  | CompleteAnnotateAction
  | SetDocumentsAction
