import HeuristicsItem from "components/HeuristicsItem/HeuristicsItem";
import Miniplus from "components/Miniplus/Miniplus";
import MultiStringInput from "components/MultiStringInput/MultiStringInput";
import MultiSymbolInput from "components/MultiSymbolInput/MultiSymbolInput";
import React, { Dispatch, SetStateAction, useState } from "react";
import { TextInput, TextInputTheme } from "@dbrainio/shared-ui";
import style from "./HeuristicsList.module.css";
import { THeuristic } from "types/common";
import { Mapper } from "utils/mapper";

interface Props {
  heuristics: THeuristic[];
  onRemove: (h: THeuristic) => void;
  setHeuristics: Dispatch<SetStateAction<{ [key: string]: THeuristic[] }>>;
  selected: string;
}

export default function HeuristicsList({
  heuristics,
  onRemove,
  setHeuristics,
  selected,
}: Props) {
  // text
  const [may_add_spaces, setMayAddSpaces] = useState(false);
  const [regex, setRegex] = useState("");
  // digits
  const [to_digit_delete, setToDigitsDelete] = useState(false);
  const [allow_separators, setAllowSeparators] = useState(false);
  const [a_as, setAAs] = useState(false);
  const [a_asValue, setAAsValue] = useState("");
  const [dateSeperator1, setDateSeparator1] = useState("/");
  // case
  const try_to_rus_city = useState(false);

  // date

  // common
  const is_replace = useState(false);
  const replaces = useState<
    {
      from: string;
      to: string;
    }[]
  >([
    {
      from: "",
      to: "",
    },
  ]);
  const remove_residual_symbols = useState([""]);
  const is_remove_residual_symbols = useState(false);
  const leave_residual_symbols = useState([""]);
  const is_restrict_length = useState(false);
  const restrict_length_min = useState("");
  const restrict_length_max = useState("");
  const is_regex = useState(false);
  const is_remove_strings = useState(false);
  const remove_strings = useState([""]);

  // cities
  const try_to_sng_or_rus_subject = useState(false);
  const try_to_sng_country = useState(false);
  const conservative_mode = useState(false);

  const renderHeuristicField = (heuristic: THeuristic) => {
    switch (heuristic.name) {
      case "handwritten": {
        return (
          <HeuristicsItem
            block
            isChecked
            onChange={() => {}}
            onRemove={() => onRemove(heuristic)}
            label={Mapper.mapHeuristicName(heuristic.name)}
          />
        );
      }
      case "text_processing": {
        return (
          <HeuristicsItem
            block
            isChecked
            onRemove={() => onRemove(heuristic)}
            label={Mapper.mapHeuristicName(heuristic.name)}
          >
            <HeuristicsItem
              notRemovable
              isChecked={may_add_spaces}
              onChange={setMayAddSpaces}
              onRemove={() => onRemove(heuristic)}
              label="Добавлять пробелы после точек"
            />
          </HeuristicsItem>
        );
      }
      case "to_digit": {
        return (
          <HeuristicsItem
            block
            isChecked
            onRemove={() => onRemove(heuristic)}
            label={Mapper.mapHeuristicName(heuristic.name)}
          >
            <HeuristicsItem
              notRemovable
              isChecked={to_digit_delete}
              onChange={setToDigitsDelete}
              onRemove={() => onRemove(heuristic)}
              label="Удалять всё символы, которые не удалось привести к цифрам"
            >
              <HeuristicsItem
                notRemovable
                isChecked={allow_separators}
                onChange={setAllowSeparators}
                onRemove={() => onRemove(heuristic)}
                label="Не удалять символы, которые могут разделять цифры"
              />
            </HeuristicsItem>
            <HeuristicsItem
              row
              notRemovable
              isChecked={a_as}
              onChange={setAAs}
              label="Заменять «А» на"
              onRemove={() => onRemove(heuristic)}
            >
              <span>
                <div className={style.replaceAInput}>
                  <TextInput
                    theme={TextInputTheme.White}
                    value={a_asValue}
                    oneSymboled
                    onChange={(event: React.FormEvent<HTMLInputElement>) =>
                      setAAsValue(event.currentTarget.value.slice(0, 1))
                    }
                  />
                </div>
              </span>
            </HeuristicsItem>
          </HeuristicsItem>
        );
      }
      case "to_letters": {
        return (
          <HeuristicsItem
            block
            isChecked
            onRemove={() => onRemove(heuristic)}
            label={Mapper.mapHeuristicName(heuristic.name)}
          />
        );
      }
      case "to_upper": {
        return (
          <HeuristicsItem
            block
            isChecked
            onRemove={() => onRemove(heuristic)}
            label={Mapper.mapHeuristicName(heuristic.name)}
          />
        );
      }
      case "to_lower": {
        return (
          <HeuristicsItem
            block
            isChecked
            onRemove={() => onRemove(heuristic)}
            label={Mapper.mapHeuristicName(heuristic.name)}
          />
        );
      }
      case "capitalize": {
        return (
          <HeuristicsItem
            block
            isChecked
            onRemove={() => onRemove(heuristic)}
            label={Mapper.mapHeuristicName(heuristic.name)}
          />
        );
      }
      case "to_date": {
        return (
          <HeuristicsItem
            block
            isChecked
            onRemove={() => onRemove(heuristic)}
            label={Mapper.mapHeuristicName(heuristic.name)}
          >
            <div className={style.date}>
              <span>дд</span>
              <span className={style.dateSep}>
                <TextInput
                  theme={TextInputTheme.White}
                  value={dateSeperator1}
                  oneSymboled
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    setDateSeparator1(event.currentTarget.value.slice(0, 1))
                  }
                />
              </span>
              <span>мм{dateSeperator1}гггг</span>
            </div>
          </HeuristicsItem>
        );
      }
      case "to_date_time": {
        return (
          <HeuristicsItem
            block
            isChecked
            onRemove={() => onRemove(heuristic)}
            label={Mapper.mapHeuristicName(heuristic.name)}
          >
            <div className={style.date}>
              <span>дд</span>
              <span className={style.dateSep}>
                <TextInput
                  theme={TextInputTheme.White}
                  value={dateSeperator1}
                  oneSymboled
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    setDateSeparator1(event.currentTarget.value.slice(0, 1))
                  }
                />
              </span>
              <span>мм{dateSeperator1}гггг</span>
            </div>
            <div className={style.date}>
              <span>чч</span>
              <span className={style.dateSep}>
                <TextInput
                  theme={TextInputTheme.White}
                  value={dateSeperator1}
                  oneSymboled
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    setDateSeparator1(event.currentTarget.value.slice(0, 1))
                  }
                />
              </span>
              <span>мм</span>
            </div>
          </HeuristicsItem>
        );
      }
      case "hhmm": {
        return (
          <HeuristicsItem
            block
            isChecked
            onRemove={() => onRemove(heuristic)}
            label={Mapper.mapHeuristicName(heuristic.name)}
          >
            <div className={style.date}>
              <span>чч</span>
              <span className={style.dateSep}>
                <TextInput
                  theme={TextInputTheme.White}
                  value={dateSeperator1}
                  oneSymboled
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    setDateSeparator1(event.currentTarget.value.slice(0, 1))
                  }
                />
              </span>
              <span>мм</span>
            </div>
          </HeuristicsItem>
        );
      }
      case "hhmmss": {
        return (
          <HeuristicsItem
            block
            isChecked
            onRemove={() => onRemove(heuristic)}
            label={Mapper.mapHeuristicName(heuristic.name)}
          >
            <div className={style.date}>
              <span>чч</span>
              <span className={style.dateSep}>
                <TextInput
                  theme={TextInputTheme.White}
                  value={dateSeperator1}
                  oneSymboled
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    setDateSeparator1(event.currentTarget.value.slice(0, 1))
                  }
                />
              </span>
              <span>мм - сс</span>
            </div>
          </HeuristicsItem>
        );
      }
      case "remove_residual_symbols": {
        return (
          <HeuristicsItem
            onRemove={() => onRemove(heuristic)}
            isChecked={is_remove_residual_symbols[0]}
            onChange={is_remove_residual_symbols[1]}
            label={Mapper.mapHeuristicName(heuristic.name)}
            block
          >
            <MultiSymbolInput
              symbols={remove_residual_symbols[0]}
              onChange={remove_residual_symbols[1]}
              className={style.multiSymbol}
            />
          </HeuristicsItem>
        );
      }
      case "to_discrete": {
        return (
          <HeuristicsItem
            onRemove={() => onRemove(heuristic)}
            isChecked={is_remove_residual_symbols[0]}
            onChange={is_remove_residual_symbols[1]}
            label={Mapper.mapHeuristicName(heuristic.name)}
            block
          >
            s
          </HeuristicsItem>
        );
      }
      case "leave_residual_symbols": {
        return (
          <HeuristicsItem
            onRemove={() => onRemove(heuristic)}
            isChecked={is_remove_residual_symbols[0]}
            onChange={is_remove_residual_symbols[1]}
            label={Mapper.mapHeuristicName(heuristic.name)}
            block
          >
            <MultiSymbolInput
              symbols={leave_residual_symbols[0]}
              onChange={leave_residual_symbols[1]}
              className={style.multiSymbol}
            />
          </HeuristicsItem>
        );
      }
      case "replace": {
        return (
          <HeuristicsItem
            block
            label="Заменять"
            onChange={is_replace[1]}
            isChecked={is_replace[0]}
            onRemove={() => onRemove(heuristic)}
          >
            <div className={style.replaces}>
              {replaces[0].map((r, idx) => (
                <div className={style.replace} key={idx}>
                  <TextInput
                    value={r.from}
                    inputSize="small"
                    onChange={(event: React.FormEvent<HTMLInputElement>) =>
                      replaces[1](
                        replaces[0].map((r, i) =>
                          i === idx
                            ? { ...r, from: event.currentTarget.value }
                            : r
                        )
                      )
                    }
                  />
                  <div>на</div>
                  <TextInput
                    type="number"
                    inputSize="small"
                    value={r.to}
                    onChange={(event: React.FormEvent<HTMLInputElement>) =>
                      replaces[1](
                        replaces[0].map((r, i) =>
                          i === idx
                            ? { ...r, to: event.currentTarget.value }
                            : r
                        )
                      )
                    }
                  />
                </div>
              ))}
              <Miniplus
                onClick={() =>
                  replaces[1]([
                    ...replaces[0],
                    {
                      from: "",
                      to: "",
                    },
                  ])
                }
              />
            </div>
          </HeuristicsItem>
        );
      }
      case "restrict_length": {
        return (
          <HeuristicsItem
            block
            label="Ограничение длины"
            onChange={is_restrict_length[1]}
            isChecked={is_restrict_length[0]}
            onRemove={() => onRemove(heuristic)}
          >
            <div className={style.minmax}>
              <div className={style.minmaxItem}>
                <div>Мин. </div>
                <TextInput
                  type="number"
                  inputSize="small"
                  value={restrict_length_min[0]}
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    restrict_length_min[1](
                      event.currentTarget.value.slice(0, 3)
                    )
                  }
                />
              </div>
              <div className={style.minmaxItem}>
                <div>Макс. </div>
                <TextInput
                  inputSize="small"
                  type="number"
                  value={restrict_length_max[0]}
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    restrict_length_max[1](
                      event.currentTarget.value.slice(0, 3)
                    )
                  }
                />
              </div>
            </div>
          </HeuristicsItem>
        );
      }
      case "remove_prefix": {
        return (
          <HeuristicsItem
            block
            label="Удалять начало текста"
            onChange={try_to_rus_city[1]}
            isChecked={try_to_rus_city[0]}
            onRemove={() => onRemove(heuristic)}
          >
            <TextInput
              inputSize="small"
              type="number"
              value={restrict_length_max[0]}
              onChange={(event: React.FormEvent<HTMLInputElement>) =>
                restrict_length_max[1](event.currentTarget.value.slice(0, 3))
              }
            />
          </HeuristicsItem>
        );
      }
      case "remove_strings": {
        return (
          <HeuristicsItem
            block
            label="Удалять указанные строки"
            onChange={is_remove_strings[1]}
            isChecked={is_remove_strings[0]}
            onRemove={() => onRemove(heuristic)}
          >
            <MultiStringInput
              strings={remove_strings[0]}
              onChange={remove_strings[1]}
            />
          </HeuristicsItem>
        );
      }
      case "pattern": {
        return (
          <HeuristicsItem
            block
            isChecked
            onChange={is_regex[1]}
            label="Регулярное выражение"
            onRemove={() => onRemove(heuristic)}
          >
            <TextInput
              inputSize="small"
              className={style.input}
              value={(regex || heuristic.params?.pattern) ?? ""}
              onChange={(e) => {
                const target = e.target as HTMLInputElement;
                setHeuristics((prevState) => {
                  return {
                    ...prevState,
                    [selected]: prevState[selected].map((h) =>
                      h.name === "pattern"
                        ? { ...h, params: { pattern: target.value } }
                        : h
                    ),
                  };
                });
                setRegex(target.value);
              }}
            />
          </HeuristicsItem>
        );
      }
      case "try_to_rus_city": {
        return (
          <HeuristicsItem
            block
            label="Город россии"
            onChange={try_to_rus_city[1]}
            isChecked={try_to_rus_city[0]}
            onRemove={() => onRemove(heuristic)}
          >
            {try_to_rus_city[0] && (
              <HeuristicsItem
                notRemovable
                label="Альтернативный режим"
                onChange={conservative_mode[1]}
                isChecked={conservative_mode[0]}
                onRemove={() => onRemove(heuristic)}
              />
            )}
          </HeuristicsItem>
        );
      }
      case "try_to_sng_or_rus_subject": {
        return (
          <HeuristicsItem
            block
            label="Город России или СНГ"
            onRemove={() => onRemove(heuristic)}
            onChange={try_to_sng_or_rus_subject[1]}
            isChecked={try_to_sng_or_rus_subject[0]}
          >
            {try_to_sng_or_rus_subject[0] && (
              <HeuristicsItem
                notRemovable
                label="Альтернативный режим"
                onChange={conservative_mode[1]}
                isChecked={conservative_mode[0]}
                onRemove={() => onRemove(heuristic)}
              />
            )}
          </HeuristicsItem>
        );
      }
      case "try_to_sng_country": {
        return (
          <HeuristicsItem
            block
            label="Город СНГ"
            onChange={try_to_sng_country[1]}
            isChecked={try_to_sng_country[0]}
            onRemove={() => onRemove(heuristic)}
          >
            {try_to_sng_country[0] && (
              <HeuristicsItem
                notRemovable
                label="Альтернативный режим"
                onChange={conservative_mode[1]}
                isChecked={conservative_mode[0]}
                onRemove={() => onRemove(heuristic)}
              />
            )}
          </HeuristicsItem>
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className={style.root}>
      {heuristics.map((heuristic, index) => {
        return <div key={index}>{renderHeuristicField(heuristic)}</div>;
      })}
    </div>
  );
}
