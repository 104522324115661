import classnames from "classnames";
import React from "react";
import { IDocument } from "types/common";
import style from "./DocumentCard.module.css";
import { Button, ButtonSize, ButtonTheme } from "@dbrainio/shared-ui";
import { useNavigate } from "react-router-dom";
import { ellipsize, formatUnixDate } from "utils/utils";
import Card from "components/Card/Card";
import ButtonBase from "@mui/material/ButtonBase";
import ProgressBar from "components/ProgressBar/ProgressBar";

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  document: IDocument;
  onDelete: () => void;
}

export default function DocumentCard({ document, onDelete, ...props }: Props) {
  const { stages, markups, fieldnets } = document;
  const n = useNavigate();

  const getControl = () => {
    if (fieldnets[fieldnets.length - 1]?.status === "failed")
      return <div className={style.buildError}>Ошибка обучения</div>;

    if (stages.training.done) {
      return (
        <div className={style.successButton}>
          <svg
            width="22"
            height="19"
            viewBox="0 0 22 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={style.successButtonIcon}
          >
            <path d="M2 7.5L8.75 15L20 1.5" stroke="#32BC42" strokeWidth="4" />
          </svg>
          <div>Обучен</div>
        </div>
      );
    }
    // if (stages.training.progress) {
    //   return (
    //     <ProgressBar label="Обучаем..." progress={stages.training.progress} />
    //   );
    // }

    if (stages.heuristics.done || stages.training.progress) {
      return (
        <Button
          theme={ButtonTheme.Blue}
          onClick={(e) => {
            e.stopPropagation();
            n(`/documents/${document.id}`);
          }}
        >
          К документу
        </Button>
      );
    }

    if (stages.markup.done) {
      return (
        <Button
          theme={ButtonTheme.Blue}
          onClick={(e) => {
            e.stopPropagation();
            n(`/heuristics/${document.id}`);
          }}
        >
          Указать эвристики
        </Button>
      );
    }

    if (document.stages.annotation.done) {
      if (markups.length > 0 && markups[0].status === "running") {
        return stages.markup.done ? null : (
          <ProgressBar
            label="Размечаем датасет..."
            small
            progress={stages.markup.progress || 0.1}
            className={style.progress}
          />
        );
      } else {
        return <Button theme={ButtonTheme.Blue}>Запустить разметку</Button>;
      }
    }

    if (document.stages.upload.done) {
      return (
        <Button
          theme={ButtonTheme.Blue}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            n(`/annotate/${document.id}`);
          }}
        >
          Выбрать и разметить эталон
        </Button>
      );
    }

    return <Button theme={ButtonTheme.Blue}>Залить датасет</Button>;
  };

  const handleDelete = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete();
  };

  return (
    <Card
      {...props}
      className={classnames(style.root, props.className)}
      theme={document.stages.training.done ? "green" : undefined}
      onClick={() => n(`/documents/${document.id}`)}
    >
      <div className={style.text}>
        <div className={style.date}>{formatUnixDate(document.created_at)}</div>
        <div className={style.name}>{ellipsize(document.name, 44)}</div>
      </div>
      <div className={style.stage}>{getControl()}</div>
      <div className={style.remove} onClick={handleDelete}>
        <ButtonBase className={style.removeBase}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2 14L14 2" stroke="#AAAAAA" strokeWidth="2" />
            <path d="M14 14L2 2" stroke="#AAAAAA" strokeWidth="2" />
          </svg>
        </ButtonBase>
      </div>
    </Card>
  );
}
