import style from "./SingleDocument.module.css";
import {
  TextInput,
  TextInputTheme,
  Button,
  ButtonTheme,
} from "@dbrainio/shared-ui";
import { IDocument, IImage } from "types/common";
import MultifileUpload from "components/MultifileUpload/MultifileUpload";
import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import ProgressBar from "components/ProgressBar/ProgressBar";
import Container from "components/Container/Container";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { generateImgUrl, parseJwt } from "utils/utils";
import classNames from "classnames";
import LoadingImage from "components/LoadingImage/LoadingImage";
import { useAuthState } from "context/AuthContext";
import { IJWT } from "components/LoginForm/LoginForm";
import { MarkupApi } from "api/markupApi";

interface Props {
  document: IDocument;
  isUploading: boolean;
  isRunMarkupLoading: boolean;
  isRunTrainLoading: boolean;
  nameError: boolean;
  dataset: IImage[] | null;
  runMarkup: () => void;
  runTrain: () => void;
  onUpload: (
    acceptedFiles: File[],
    onUploadProgress: (count: number) => void
  ) => void;
  onNameChange: (newName: string) => void;
}

const Circle = ({
  index,
  active,
  theme,
}: {
  theme: "success" | "warning" | "default";
  active: boolean;
  index: number;
}) => {
  return (
    <div
      className={classnames(style.circle, {
        [style.green]: theme === "success",
        [style.black]: active,
        [style.orange]: theme === "warning",
      })}
    >
      {theme === "success" ? (
        <svg
          width="22"
          height="19"
          viewBox="0 0 22 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M2 7.5L8.75 15L20 1.5" stroke="#FAFAFA" strokeWidth="4" />
        </svg>
      ) : theme === "warning" ? (
        <svg
          width="54"
          height="48"
          viewBox="0 0 54 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.2568 3.09894C26.0215 1.73964 27.9785 1.73963 28.7432 3.09894L52.3235 45.0195C53.0734 46.3527 52.11 48 50.5803 48H3.41969C1.89004 48 0.926614 46.3527 1.67654 45.0195L25.2568 3.09894Z"
            fill="#F99018"
          />
          <path
            d="M25.2355 32.12H28.7555L29.5555 16.6H24.4355L25.2355 32.12ZM24.4355 36.76C24.4355 38.2 25.5555 39.32 26.9955 39.32C28.4355 39.32 29.5555 38.2 29.5555 36.76C29.5555 35.32 28.4355 34.2 26.9955 34.2C25.5555 34.2 24.4355 35.32 24.4355 36.76Z"
            fill="#FAFAFA"
          />
        </svg>
      ) : (
        <span>{index}</span>
      )}
    </div>
  );
};

const Progress = ({ success }: { success: boolean }) => {
  return (
    <div
      className={classnames(style.progress, {
        [style.success]: success,
      })}
    ></div>
  );
};

export default function SingleDocument({
  document,
  dataset,
  nameError,
  onUpload,
  onNameChange,

  runMarkup,
  runTrain,
  isUploading,
  isRunMarkupLoading,
  isRunTrainLoading,
}: Props) {
  const { stages, markups } = document;
  const location = useLocation();
  const isNew = !!new URLSearchParams(location.search).get("new");
  const [name, setName] = useState(isNew ? "" : document.name);
  const { loginRes } = useAuthState();
  const parsedJwt = loginRes ? (parseJwt(loginRes.access) as IJWT) : null;

  const [dropzoneVisibility, setDropzoneVisibility] = useState(false);

  const navigate = useNavigate();

  const textinputRef = useRef<HTMLInputElement | null>(null);
  const progressRef = useRef<HTMLDivElement | null>(null);

  const onUploadProgress = (count: number) => {
    const current = progressRef.current as HTMLDivElement;
    if (current) {
      current.style.width = `${count}%`;
    }
  };

  const isFieldnetFailed =
    document.fieldnets[document.fieldnets.length - 1]?.status === "failed" &&
    stages.training.done;
  const onDrop = () => {};

  useEffect(() => {
    if (isNew) textinputRef?.current?.focus();
    return () => {};
  }, []);

  const downloadGt = async () => {
    const markupId = markups.length > 0 && markups[0].id;
    if (markupId) {
      const res = await MarkupApi.getGt(markupId);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = window.document.createElement("a");
      link.href = url;
      link.setAttribute("download", `gt-${document.id}.zip`); //or any other extension
      window.document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  const onBlur = () => {
    if (name.length === 0) {
      setName(document.name);
      return;
    }

    if (name !== document.name) {
      onNameChange(name);
    }

    removeNewFromQuery();
  };

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    textinputRef?.current?.blur();
  };

  const removeNewFromQuery = () => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has("new")) {
      queryParams.delete("new");
      navigate(
        {
          search: queryParams.toString(),
        },
        {
          replace: true,
        }
      );
    }
  };

  const renderDropzone = (props?: {
    onDrag?: React.MouseEventHandler<HTMLDivElement>;
    onEmptyFileList?: () => void;
  }) => (
    <div className={style.dropzone} onDrag={props?.onDrag}>
      {isUploading ? (
        <div className={style.uploading}>
          <div ref={progressRef} className={style.progress}></div>
          <div className={style.label}> Загружаем...</div>
        </div>
      ) : (
        <MultifileUpload
          onSubmit={(files) => {
            onUpload(files, onUploadProgress);
            setDropzoneVisibility(false);
          }}
          onDrop={onDrop}
          onEmptyFileList={props?.onEmptyFileList}
        />
      )}
    </div>
  );

  const uploadStage = (
    <div className={style.row}>
      <div className={style.header}>
        <Progress success={stages.upload.done} />
        <div className={style.title}>Датасет</div>
        <Circle
          theme={stages.upload.done ? "success" : "default"}
          active={!stages.upload.done}
          index={1}
        />
      </div>
      {stages.upload.done ? (
        dataset && dataset.length > 0 ? (
          <div className={style.datasets}>
            {dropzoneVisibility || isUploading ? (
              renderDropzone({
                onDrag: () => setDropzoneVisibility(false),
                onEmptyFileList: () => setDropzoneVisibility(false),
              })
            ) : (
              <div
                className={style.dropzone}
                onDragEnter={() => setDropzoneVisibility(true)}
              >
                <div className={style.datasetPreview}>
                  <LoadingImage
                    src={generateImgUrl(dataset[0], "thumbnail")}
                    alt="Изображение не загрузилось"
                  />
                  <div className={style.datasetCount}>
                    {document.images || dataset.length}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>ОШИБКА: датасет отсутствует</div>
        )
      ) : (
        <div className={style.dropzoneContainer}>{renderDropzone()}</div>
      )}
    </div>
  );

  const annotationStage = (
    <div className={style.row}>
      <div className={style.header}>
        <Progress success={stages.markup.done} />

        <div className={style.title}>Разметка</div>
        <Circle
          theme={stages.markup.done ? "success" : "default"}
          active={stages.upload.done && !stages.markup.done}
          index={2}
        />
      </div>
      {stages.annotation.done ? (
        stages.markup.progress ||
        (markups.length > 0 && markups[0].status === "running") ? (
          stages.markup.done ? (
            <Button
              theme={ButtonTheme.BlueBordered}
              disabled={!stages.upload.done}
              onClick={() => navigate(`/annotate/${document.id}?type=rewrite`)}
            >
              Переразметить
            </Button>
          ) : (
            <ProgressBar
              label="Размечаем датасет..."
              progress={stages.markup.progress || 0.1}
            />
          )
        ) : (
          <div className={style.annotationButtons}>
            <Button
              theme={ButtonTheme.BlueBordered}
              disabled={!stages.upload.done}
              onClick={() => navigate(`/annotate/${document.id}`)}
            >
              Переразметить
            </Button>
            <Button
              theme={ButtonTheme.Blue}
              disabled={!stages.annotation.done}
              onClick={runMarkup}
              isLoading={isRunMarkupLoading}
            >
              Запустить разметку
            </Button>
          </div>
        )
      ) : (
        <Button
          theme={ButtonTheme.Blue}
          disabled={!stages.upload.done}
          onClick={() => navigate(`/annotate/${document.id}`)}
        >
          Выбрать и разметить
        </Button>
      )}
    </div>
  );

  const heuristicsStage = (
    <div className={style.row}>
      <div className={style.header}>
        <Progress success={stages.heuristics.done} />
        <div className={style.title}>Эвристики</div>
        <Circle
          theme={stages.heuristics.done ? "success" : "default"}
          active={stages.markup.done && !stages.heuristics.done}
          index={3}
        />
      </div>
      {stages.heuristics.done ? null : (
        <Button
          theme={ButtonTheme.Blue}
          onClick={() => navigate(`/heuristics/${document.id}`)}
          disabled={!stages.markup.done}
        >
          Указать эвристики
        </Button>
      )}
    </div>
  );
  const gtStage = (
    <div className={style.row}>
      <div className={style.header}>
        <div className={style.title}>GT</div>
        <Circle
          theme={stages.heuristics.done ? "success" : "default"}
          active={stages.markup.done && !stages.heuristics.done}
          index={3}
        />
      </div>
      {parsedJwt &&
      {
        ...parsedJwt,
        // sub: { ...parsedJwt.sub, permissions: ['gt'] },
      }.sub.permissions.includes("gt") ? (
        <Button
          theme={ButtonTheme.Blue}
          onClick={downloadGt}
          disabled={!stages.markup.done}
        >
          Скачать GT
        </Button>
      ) : null}
    </div>
  );

  const traniningStage = (
    <div className={classnames(style.row, style.lastRow)}>
      <div className={style.header}>
        <div className={style.title}>Обучение</div>
        <Circle
          theme={
            isFieldnetFailed
              ? "warning"
              : stages.training.done
              ? "success"
              : "default"
          }
          active={stages.heuristics.done && !stages.training.done}
          index={4}
        />
      </div>
      {/* {stages.training.done ? null : stages.training.progress ? (
        <ProgressBar label="Обучаем..." progress={stages.training.progress} />
      ) : ( */}
        <Button
          theme={ButtonTheme.Blue}
          disabled={!stages.heuristics.done}
          isLoading={isRunTrainLoading}
          onClick={runTrain}
        >
          Сохранить файлы разметки
        </Button>
      {/* )} */}
      {/* {isFieldnetFailed && (
        <div className={style.fieldnetError}>
          <div className={style.buildError}>Ошибка обучения филднета</div>
          <Button
            theme={ButtonTheme.Blue}
            disabled={!stages.heuristics.done}
            isLoading={isRunTrainLoading}
            onClick={runTrain}
          >
            Перезапустить обучение
          </Button>
        </div>
      )} */}
    </div>
  );

  return (
    <Container>
      <form onSubmit={onSubmit}>
        <TextInput
          placeholder="Название документа"
          className={classNames(style.input, {
            [style.error]: nameError,
          })}
          theme={TextInputTheme.Transparent}
          inputSize="large"
          value={name}
          inputRef={textinputRef}
          onChange={(e) => setName(e.currentTarget.value)}
          onBlur={onBlur}
        />
      </form>
      <section className={style.pipeline}>
        {uploadStage}
        {annotationStage}
        {parsedJwt &&
        {
          ...parsedJwt,
          // sub: { ...parsedJwt.sub, permissions: ['gt'] },
        }.sub.permissions.includes("gt") ? (
          gtStage
        ) : (
          <>
            {heuristicsStage}
            {traniningStage}
          </>
        )}
      </section>
    </Container>
  );
}
