import { useEffect, useState } from 'react'
import style from './AnnotateScreen.module.css'
import Layout from 'components/Layout/Layout'
import { useParams } from 'react-router'
import Annotate from 'components/Annotate/Annotate'
import { ID, IImage, RectPayload } from 'types/common'
import { ImageApi } from 'api/imageApi'
import { message } from 'utils/toast'
import { MarkupApi } from 'api/markupApi'
import { DocumentApi } from 'api/documentApi'
import { Navigate, useNavigate } from 'react-router-dom'

interface Props {}

export const ANNOTATE_IMAGES_PER_PAGE = 20

export default function SingleDocumentScreen({}: Props) {
  let { id } = useParams<{ id?: ID }>()

  const navigate = useNavigate()

  const [images, setImages] = useState<IImage[] | null>(null)
  const [total, setTotal] = useState<number | null>(null)

  const [isLoading, setLoading] = useState(false)
  const [isSubmitLoading, setSubmitLoading] = useState(false)
  const [image, setImage] = useState<IImage | null>(null)

  const onSubmit = async (rects: RectPayload[]) => {
    if (!id) return
    if (!image) return

    setSubmitLoading(true)
    try {
      const res = await MarkupApi.add({
        tag: id,
        example_image_id: image.id,
        document_id: id,
        fields: rects,
      })

      const documentRes = await DocumentApi.edit({
        id: id,
        stages: {
          markup: {
            done: false,
          },
        },
      })
      console.log(
        '🚀 ~ file: AnnotateScreen.tsx ~ line 52 ~ onSubmit ~ documentRes',
        documentRes,
      )

      if (res.status === 200 || res.status === 201) {
        message.success(`Параметры документа сохранены`)
        navigate(-1)
      } else {
        message.error(`Ошибка при обработке ответа. Код: ${res.status}`)
      }
    } catch (error) {
      message.error(
        `Ошибка при обработке ответа. Код: ${JSON.stringify(error)}`,
      )
    }
    setSubmitLoading(false)
  }

  const onPageChange = (page: number) => {}

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      if (!id) return

      try {
        const res = await ImageApi.get({
          tag: id,
          pagination: {
            limit: 240,
            offset: 0,
          },
        })

        if (res.status === 200 || res.status === 201) {
          if (!res.data.payload) {
            throw new Error('Payload is empty')
          }

          if (res.data.payload.length === 0) {
            throw new Error('Images array is empty')
          }

          setImages(res.data.payload)
          setTotal(res.data.total)
        } else {
          message.error(`Ошибка при загрузке изображений. Код: ${res.status}`)
        }
      } catch (error) {
        message.error(
          `Ошибка при загрузке изображений. Код: ${JSON.stringify(error)}`,
        )
      }

      setLoading(false)
    }

    getData()
    return () => {}
  }, [])

  if (!id) {
    return <Navigate to="/" />
  }

  return (
    <Layout
      className={style.root}
      loading={isLoading}
      transparentLoading={isSubmitLoading}
    >
      <div className={style.container}>
        {images && (
          <Annotate
            total={total}
            onPageChange={onPageChange}
            images={images}
            documentId={id}
            onSubmit={onSubmit}
            onImage={setImage}
          />
        )}
      </div>
    </Layout>
  )
}
