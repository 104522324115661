import { Button, ButtonTheme } from "@dbrainio/shared-ui";
import classnames from "classnames";
import DialogModal from "components/DialogModal/DialogModal";
import HeuristicSelector from "components/HeuristicSelector/HeuristicSelector";
import LoadingImage from "components/LoadingImage/LoadingImage";
import MarkupLogo from "components/MarkupLogo/MarkupLogo";
import PlusButton from "components/PlusButton/PlusButton";
import Sidebar from "components/Sidebar/Sidebar";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppStateType } from "store/reduxStore";
import {
  HeuristicName,
  IDocument,
  IField,
  IOption,
  THeuristic,
  THeuristicCollections,
} from "types/common";
import style from "./Heuristics.module.css";
import HeuristicsList from "./HeuristicsList";

interface Props {
  document: IDocument;
  isAddLoading: boolean;
  slideIdx: number;
  setSlideIdx: (idx: number) => void;
  onSubmit: (collections: THeuristicCollections[]) => void;
}

type SelectOption = IOption<IField>;

export default function Heuristics({
  document,
  isAddLoading,
  slideIdx,
  setSlideIdx,
  onSubmit,
}: Props) {
  const [isAdd, setIsAdd] = useState(true);
  const loginRes = useSelector((state: AppStateType) => state.app.auth);
  const [toSave, setToSave] = useState<boolean | null>(null);

  const [heuristics, setHeuristics] = useState<{
    [key: string]: THeuristic[];
  }>({});

  const navigate = useNavigate();

  const [selected, setSelected] = useState<string>(
    document.markups[0].fields[0].slug
  );

  const onBack = () => {
    navigate(`/documents/${document.id}`, {
      replace: true,
    });
  };

  const handleSubmit = () => {
    setToSave(true);
  };

  const onConfirmSubmit = () => {
    onSubmit(
      Object.keys(heuristics).map((k: string) => ({
        field: k,
        heuristics: heuristics[k],
        layer: "basic",
      }))
    );
  };

  const onCancelDelete = async () => setToSave(null);

  return (
    <div className={style.root}>
      <DialogModal
        title="Завершить проставление эвристик"
        content="Редактирование эвристик будет недоступно"
        cancelText="Отменить"
        confirmText="Сохранить и выйти"
        open={!!toSave}
        onClose={onCancelDelete}
        onCancel={onCancelDelete}
        onConfirm={onConfirmSubmit}
        isConfirmLoading={isAddLoading}
      />
      <Sidebar className={style.leftSidebar}>
        <div className={style.sidebarBody}>
          <div className={style.logo}>
            <MarkupLogo />
          </div>
          <div className={style.heading}>Поля</div>
          <div className={style.fieldList}>
            {document.markups[0].fields.map((f) => (
              <div
                className={classnames(style.fieldButton, {
                  [style.fieldButtonActive]: selected === f.slug,
                })}
                onClick={() => setSelected(f.slug)}
              >
                <span>{f.name}</span>
                {heuristics[f.slug] && heuristics[f.slug].length > 0 && (
                  <svg
                    width="14"
                    height="12"
                    viewBox="0 0 14 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 5L5.5 10L13 1"
                      stroke="#111111"
                      strokeWidth="2"
                    />
                  </svg>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className={style.controls}>
          <Button
            theme={ButtonTheme.BlueBordered}
            isLoading={isAddLoading}
            className={style.submit}
            onClick={onBack}
          >
            Назад
          </Button>
          <Button
            theme={ButtonTheme.Blue}
            onClick={handleSubmit}
            className={style.submit}
            isLoading={isAddLoading}
          >
            Сохранить
          </Button>
        </div>
      </Sidebar>
      <div className={style.slider}>
        <div className={style.slide}>
          {document.markups.length > 0 ? (
            <>
              <LoadingImage
                src={`${window._env_?.API_URL}/markup/${document.markups[0].id}/${selected}?access_token=${loginRes?.access}`}
                alt=""
              />
              {/* <LoadingImage
                src={`${window._env_?.API_URL}/markup/${document.markups[0].id}/${document.markups[0].example_image_id}/${selected}?access_token=${loginRes?.access}`}
                alt=""
              /> */}
            </>
          ) : null}
        </div>
      </div>
      <Sidebar className={style.rightSidebar}>
        <div className={style.heading}>Эвристики</div>
        <div className={isAdd ? style.not_hidden : style.hidden}>
          <HeuristicSelector
            onSelect={(name: HeuristicName) => {
              setIsAdd(false);
              setHeuristics({
                ...heuristics,
                [selected]: [
                  ...(heuristics[selected] || []),
                  {
                    name,
                  },
                ],
              });
            }}
          />
        </div>
        <div className={isAdd ? style.hidden : style.not_hidden}>
          <PlusButton onClick={() => setIsAdd(true)} />
          <HeuristicsList
            key={selected}
            selected={selected}
            setHeuristics={setHeuristics}
            heuristics={heuristics[selected] || []}
            onRemove={(h) => {
              setHeuristics({
                ...heuristics,
                [selected]: heuristics[selected].filter(
                  (heu) => heu.name !== h.name
                ),
              });
            }}
          />
        </div>
      </Sidebar>
    </div>
  );
}
