import { IProject } from "types/common";
import { IPagination, ID } from "../../types/common";

export const SET_PROJECTS = "PROJECT/SET_PROJECTS";
export const ADD_PROJECT = "PROJECT/ADD_PROJECT";
export const DELETE_PROJECT = "PROJECT/DELETE_PROJECT";
export const GET_PROJECTS = "PROJECT/GET_PROJECTS";

export interface SetProjectsAction {
  type: typeof SET_PROJECTS;
  payload: IProject[];
  fromLogin?: boolean;
}

export interface GetProjectsAction {
  type: typeof GET_PROJECTS;
  payload: IPagination;
}

export function getProjects(payload: IPagination): ProjectActionTypes {
  return {
    type: GET_PROJECTS,
    payload,
  };
}

export function setProjects(
  payload: IProject[],
  fromLogin?: boolean
): ProjectActionTypes {
  return {
    type: SET_PROJECTS,
    payload,
    fromLogin,
  };
}

export interface AddProjectsAction {
  type: typeof ADD_PROJECT;
  payload: IProject;
}

export function addProject(payload: IProject): ProjectActionTypes {
  return {
    type: ADD_PROJECT,
    payload,
  };
}

export interface DeleteProjectsAction {
  type: typeof DELETE_PROJECT;
  payload: ID;
}

export function deleteProject(payload: ID): ProjectActionTypes {
  return {
    type: DELETE_PROJECT,
    payload,
  };
}

export type ProjectActionTypes =
  | SetProjectsAction
  | GetProjectsAction
  | AddProjectsAction
  | DeleteProjectsAction;
