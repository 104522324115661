import React from 'react'
import { HTMLDivProps } from 'types/common'
import style from './Miniplus.module.css'

interface Props extends HTMLDivProps {}

const PlusButton = (props: Props) => (
  <div className={style.root} {...props}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="7" width="16" height="2" />
      <rect x="9" width="16" height="2" transform="rotate(90 9 0)" />
    </svg>
  </div>
)

export default PlusButton
