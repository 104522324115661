import { SET_APP_ERROR } from 'store/actions/appActions'
import { ActionTypes, AppState } from 'store/types'
import { LocalStorage } from 'utils/localStorage'

const initialState: AppState = {
  auth: LocalStorage.getLoginRes(),
  isAppLoading: false,
  documents: [],
  appError: null,
}

export function appReducer(
  state = initialState,
  action: ActionTypes,
): AppState {
  switch (action.type) {
    case SET_APP_ERROR:
      return {
        ...state,
        isAppLoading: false,
      }

    default:
      return state
  }
}
