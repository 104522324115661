import { IDocument } from '../../types/common'
import { ActionTypes } from 'store/types'
import { SET_DOCUMENTS, GET_DOCUMENTS } from '../actions/appActions'

export interface DocumentsState {
  items: IDocument[] | null
  single: IDocument | null
  isLoading: boolean
  isDeleteLoading: boolean
}

const initialState: DocumentsState = {
  single: null,
  isLoading: false,
  isDeleteLoading: false,
  items: [],
}

export function documentReducer(
  state = initialState,
  action: ActionTypes,
): DocumentsState {
  switch (action.type) {
    case SET_DOCUMENTS:
      return {
        ...state,
        items: [],
        isLoading: false,
      }
    case GET_DOCUMENTS:
      return {
        ...state,
        isLoading: true,
      }

    default:
      return state
  }
}
