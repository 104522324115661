import Card from "components/Card/Card";
import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  IBuild,
  ID,
  IDemoStand,
  IDocument,
  IntervalType,
  StandCheckType,
} from "types/common";
import style from "./BuildCard.module.css";
import {
  Button,
  ButtonTheme,
  TextInput,
  TextInputTheme,
} from "@dbrainio/shared-ui";
import { formatUnixDate } from "utils/utils";
import ProgressBar from "components/ProgressBar/ProgressBar";
import { Mapper } from "utils/mapper";
import { ILoading } from "../../screens/SingleProjectScreen/SingleProjectScreen";
import { ISelectedDocument } from "../SingleProject/SingleProject";

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  b: IBuild;
  documents: IDocument[];
  demos: IDemoStand[];
  standsLoading: ILoading;
  setStandsLoading: Dispatch<SetStateAction<ILoading>>;
  onDelete: (id: ID) => void;
  onGetBuild: (b: IBuild) => void;
  onChangeBuildName: (buildName: string, buildId: string) => void;
  onRun: (b: IBuild) => void;
  onStop: (b: IBuild) => void;
  selectedDocuments: ISelectedDocument[];
  buildsNames: { [key: string]: string };
  setBuildsNames: Dispatch<SetStateAction<{ [key: string]: string }>>;
  standCheck: (
    id: ID,
    buildId: ID,
    intervalID: IntervalType,
    type: StandCheckType
  ) => void;
}

export default function BuildCard({
  onDelete,
  onGetBuild,
  onChangeBuildName,
  onRun,
  onStop,
  documents,
  standsLoading,
  setStandsLoading,
  demos,
  standCheck,
  buildsNames,
  setBuildsNames,
  selectedDocuments,
  b,
  ...props
}: Props) {
  const stand = demos.find((d) => d.build_id === b.id);
  const prevName = useRef(buildsNames[b.id]);

  const [isInput, setIsInput] = useState<boolean>(false);
  const textinputRef = useRef<HTMLInputElement | null>(null);

  const handleDelete = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete(b.id);
  };

  const handleConvert = (e: MouseEvent<HTMLDivElement>) => {
    setIsInput(true);
  };

  const onBlur = () => {
    setIsInput(false);

    if (prevName.current !== buildsNames[b.id]) {
      onChangeBuildName(buildsNames[b.id], b.id);
      prevName.current = buildsNames[b.id];
    }
  };

  const run = () => {
    onRun(b);

    const intervalID = setInterval(() => {
      check(intervalID, "run");
    }, 15000);
  };

  const stop = () => {
    onStop(b);

    const intervalID = setInterval(() => {
      check(intervalID, "stop");
    }, 15000);
  };

  const check = (intervalID: IntervalType, type: StandCheckType) => {
    if (stand) {
      standCheck(stand.id, b.id, intervalID, type);
    }
  };

  const goToStand = () => {
    // h.push(`https://${b.id}.dev.studio.dbrain.io`)
    const win = window.open(`https://${b.id}.dev.studio.dbrain.io`, "_blank");
    win?.focus();
  };

  const formatDate = (str: string): string => {
    const number = parseInt(str);
    return `${number} ${str.slice(3).slice(0, 3)}`;
  };

  useEffect(() => {
    if (stand?.action !== "idle") {
      setStandsLoading((prev) => ({ ...prev, [b.id]: true }));
      if (stand?.is_online) {
        const intervalID = setInterval(() => {
          check(intervalID, "stop");
        }, 15000);
      } else {
        const intervalID = setInterval(() => {
          check(intervalID, "run");
        }, 15000);
      }
    }
  }, []);

  const body =
    b.image_build === null ? (
      <>
        {b.classifier?.status === "failed" && (
          <div className={style.buildError}>Ошибка обучения классификатора</div>
        )}
        {b.classifier?.status === "running" && (
          <ProgressBar
            progress={0.6}
            label="Обучаем классификатор"
          ></ProgressBar>
        )}
      </>
    ) : (
      <>
        {b.image_build?.error ? (
          <div className={style.buildError}>Ошибка при сборке</div>
        ) : stand?.error ? (
          <>
            <div className={style.buildError}>Ошибка при запуске стенда</div>
            <Button
              className={style.button}
              theme={ButtonTheme.Blue}
              isLoading={standsLoading[b.id]}
              onClick={run}
            >
              Перезапустить стенд
            </Button>
          </>
        ) : b.image_build?.done ? (
          stand && stand.is_online ? (
            <div className={style.buttons}>
              <Button
                theme={ButtonTheme.Blue}
                onClick={() => goToStand()}
                disabled={standsLoading[b.id] || stand?.action !== "idle"}
              >
                К стенду
              </Button>
              <Button
                theme={ButtonTheme.Blue}
                onClick={stop}
                isLoading={standsLoading[b.id] || stand?.action !== "idle"}
              >
                Остановить
              </Button>
            </div>
          ) : (
            <Button
              theme={ButtonTheme.Blue}
              isLoading={standsLoading[b.id] || stand?.action !== "idle"}
              onClick={run}
              className={style.buttonRun}
            >
              Запустить стенд
            </Button>
          )
        ) : null}
      </>
    );

  return (
    <Card
      theme={b.classifier?.status === "failed" ? "red" : "blue"}
      notClickable
      className={style.build}
    >
      <div className={style.buildHeader}>
        <div className={style.buildDate}>
          {b.classifier && formatDate(formatUnixDate(b.classifier?.created_at))}{" "}
          • <span className={style.buildId}>{b.id}</span> •{" "}
          <span className={style.buildId}>{Mapper.mapOCR(b.ocr_engine)}</span>
        </div>
        <div className={style.buildRemove} onClick={handleDelete}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2 14L14 2" stroke="#AAAAAA" strokeWidth="2" />
            <path d="M14 14L2 2" stroke="#AAAAAA" strokeWidth="2" />
          </svg>
        </div>
      </div>
      {isInput ? (
        <TextInput
          inputRef={textinputRef}
          placeholder="Название сборки"
          className={style.buildNameInput}
          theme={TextInputTheme.Transparent}
          inputSize="standard"
          autoFocus
          value={buildsNames[b.id]}
          onBlur={onBlur}
          onChange={(e) =>
            setBuildsNames({
              ...buildsNames,
              [b.id]: e.currentTarget.value.slice(0, 300),
            })
          }
        />
      ) : (
        <div className={style.buildName} onDoubleClick={handleConvert}>
          {buildsNames[b.id]}
        </div>
      )}
      {body}
    </Card>
  );
}
