import classnames from 'classnames'
import React from 'react'
import style from './Card.module.css'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  theme?: 'blue' | 'green' | 'red' | 'disabled'
  background?: 'default' | 'blue'
  notClickable?: boolean
}

export default function Card({
  theme,
  background,
  notClickable,
  ...props
}: Props) {
  return (
    <div
      {...props}
      className={classnames(style.root, props.className, {
        [style.blue]: theme === 'blue',
        [style.green]: theme === 'green',
        [style.red]: theme === 'red',
        [style.disabled]: theme === 'disabled',
        [style.notClickable]: notClickable,
        [style.simple]: !theme,
        [style.backgroundBlue]: background === 'blue',
      })}
    ></div>
  )
}
