import { LoginRes } from "api/authApi";

export enum LocalStorageItem {
  LoginRes = "LoginRes",
  token = "token",
}

export class LocalStorage {
  static setLoginRes(res: LoginRes): void {
    localStorage.setItem(LocalStorageItem.LoginRes, JSON.stringify(res));
  }
  static getLoginRes(): LoginRes | null {
    const res = localStorage.getItem(LocalStorageItem.LoginRes);
    return res ? JSON.parse(res) : null;
  }

  static getToken(): string | null {
    const res = localStorage.getItem(LocalStorageItem.token);
    return res ? res : null;
  }

  static logout(): void {
    return localStorage.removeItem(LocalStorageItem.LoginRes);
  }
}
